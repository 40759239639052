import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getTranslationHeadline} from "./helper";
import marked from "./markdown";

import '../assets/scss/imprint.scss';


class Imprint extends Component {
    render() {
        if (Object.keys(this.props.state.imprint).length) {
            return (
                <div id={'imprint'}>

                    <div className={'section theme_color theme_bordercolor'}>
                        {getTranslationHeadline(this.props.state.headlines, this.props.state.lang, 'Impressum')}
                    </div>

                    <div className={'imprint'}>
                        <section dangerouslySetInnerHTML={{__html: (!!this.props.state.imprint[this.props.state.lang]['Impressum'] ? marked(this.props.state.imprint[this.props.state.lang]['Impressum']) : '')}} />
                    </div>

                </div>
            );
        } else {
            return <></>;
        }
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        null
    )(
        Imprint
    )
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import queryString from "query-string";
import axios from "axios";
import {sha256} from "js-sha256";

import PasswordStrengthBar from 'react-password-strength-bar';

import '../assets/scss/admin/passwordForm.scss';
import loading from "../assets/img/loading.gif";
import {getTranslation} from "./helper";


class ActivateAccount extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            password: '',
            passwordRepeat: '',
            loading: false,
            done: false,
            score: 0
        }
    }

    setPassword(e) {
        e.preventDefault();

        let params = queryString.parse(this.props.location.search);

        if (!!params.token) {

            this.setState({
                loading: true
            });

            axios.post(
                process.env['REACT_APP_API'] + '/private/user/v1/activate',
                null,
                {
                    'headers': {
                        'Content-Type': 'application/json',
                        'authorization': params.token,
                        'newPassword': sha256(this.state.password)
                    }
                }
            ).then(result => {
                console.log(result);

                this.setState({
                    loading: false,
                    done: true
                });
            }).catch(error => {
                if (!!error.request.response) {
                    this.setState({
                        error: error.request.response.message,
                        loading: false,
                        done: false
                    });
                } else {
                    this.setState({
                        error: 'Network error',
                        loading: false,
                        done: false
                    });
                }
            });
        } else {
            this.setState({
                error: 'No Token is set'
            });
        }

    }

    chpwd(score) {
        this.setState({
            score: score
        })
    }

    togglePassword(e, id) {
        let input = document.getElementById(id);

        if (!!input) {
            if (input.getAttribute('type') === 'password') {
                input.setAttribute('type', 'text');
                e.target.innerHTML = 'visibility_off';
            } else {
                input.setAttribute('type', 'password');
                e.target.innerHTML = 'visibility';
            }
        }
    }

    render() {
        const {password, passwordRepeat, score, done} = this.state;

        return (
            <div className={'passwordForm'}>

                {done === false &&
                    <form method={'post'} onSubmit={(e) => this.setPassword(e)}>
                        <h3 className={'theme_color'}>{getTranslation(this, this.props.state.lang, 'NewPassword')}</h3>
                        <p className={'passwordText'}>{getTranslation(this, this.props.state.lang, 'PleaseEnterPassword')}</p>

                        <div className={'box'}>
                            <div className={'passwordInput'}>
                                <input id={'password'} type={'password'} name={'password'} value={password} onChange={e => this.setState({password: e.target.value})} placeholder={getTranslation(this, this.props.state.lang, 'Password')} />
                                <span className={'showPassword material-icons'} onClick={e => this.togglePassword(e, 'password')}>visibility</span>
                            </div>
                            <PasswordStrengthBar className={'bar'} scoreWordClassName={'knuff'} minLength={8} shortScoreWord={getTranslation(this, this.props.state.lang, 'PasswordToShort')} scoreWords={[getTranslation(this, this.props.state.lang, 'toWeak'), getTranslation(this, this.props.state.lang, 'weak'), getTranslation(this, this.props.state.lang, 'okay'), getTranslation(this, this.props.state.lang, 'well'), getTranslation(this, this.props.state.lang, 'veryStrong')]} password={password} onChangeScore={(data) => this.chpwd(data)} />
                        </div>

                        <div className={'box'}>
                            <div className={'passwordInput'}>
                                <input id={'passwordrepeat'} type={'password'} name={'passwordrepeat'} value={passwordRepeat} onChange={e => this.setState({passwordRepeat: e.target.value})} placeholder={getTranslation(this, this.props.state.lang, 'RepeatPassword')} />
                                <span className={'showPassword material-icons'} onClick={e => this.togglePassword(e, 'passwordrepeat')}>visibility</span>
                            </div>
                            <div className={'passwordRepeat' + ((password !== passwordRepeat && password !== '' && passwordRepeat !== '') ? ' notSame' : (password === '' || passwordRepeat === '' ? ' empty' : ' same'))}>
                                {(password !== passwordRepeat && password !=='' && passwordRepeat !== '') ? getTranslation(this, this.props.state.lang, 'RepeatPasswordIncorrect') : (password === '' || passwordRepeat === '' ? getTranslation(this, this.props.state.lang, 'PleaseRepeatPassword') : getTranslation(this, this.props.state.lang, 'RepeatPasswordCorrect'))}
                            </div>
                        </div>

                        {this.state.error &&
                            <div className={'error'}>
                                {this.state.error}
                            </div>
                        }

                        <button disabled={(!password || password !== passwordRepeat || score === 0)} className={'button ' + ((!!password && password === passwordRepeat && score > 0) ? 'theme_color theme_bordercolor theme_background_hover' : '')} type={'submit'}>
                            {this.state.loading ?
                                <img src={loading} alt={getTranslation(this, this.props.state.lang, 'loading')} />:
                                getTranslation(this, this.props.state.lang, 'SavePassword')
                            }
                        </button>
                    </form>
                }

                {done === true &&
                    <div className={'passwordSet'}>
                        <p>
                            {getTranslation(this, this.props.state.lang, 'SuccessfullySetPassword')}<br />
                            {getTranslation(this, this.props.state.lang, 'YouCanNowLogin')}
                        </p>
                        <Link className={'button theme_background theme_color_hover theme_bordercolorHover'} to={'/admin/login'}>{getTranslation(this, this.props.state.lang, 'ToLogin')}</Link>
                    </div>
                }
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
            }
        },
        null
    )(
        ActivateAccount
    )
);

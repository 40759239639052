import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getTranslation} from "../../../helper";

import '../../../../assets/scss/admin/add/vouchers.scss';
import {ReactComponent as Close} from "../../../../assets/img/close.svg";

class Vouchers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showme: 'links'
        };
    }

    clipBoardData(e, data, what) {
        e.preventDefault();
        let buttontext = e.target.innerText;

        let text = data.map(d => {
            return d[what];
        }).join("\n");

        navigator.clipboard.writeText(text);

        if (what === 'code') {
            e.target.innerText = "Codes kopiert...";
        } else {
            e.target.innerText = "Links kopiert...";
        }


        setTimeout(() => {
            e.target.innerText = buttontext;
        }, 4000);
    }

    render() {
        return (
            <div className={'addUser overlayBox'}>

                <Close className={'close'} onClick={() => this.props.methods.setThisState(false)} />

                <div className={'vouchers'}>

                    <h3>
                        {getTranslation(this, this.props.state.lang, 'Vouchers')}
                    </h3>

                    <div className={''}>

                        <ul className={'tabs'}>
                            <li className={'theme_bordercolor_before' + (this.state.showme === 'links' ? ' current theme_bordercolor' : '')} onClick={() => this.setState({showme: 'links'})}>Links</li>
                            <li className={'theme_bordercolor_before' + (this.state.showme === 'codes' ? ' current theme_bordercolor' : '')} onClick={() => this.setState({showme: 'codes'})}>Codes</li>
                        </ul>

                        {!!this.props.codes &&
                            <>
                                <ul className={'list'}>
                                    {
                                        this.props.codes.map((code, key) => {
                                            return (
                                                <li key={'key'+ this.state.showme + key}>
                                                    {
                                                        this.state.showme === 'codes'
                                                            ?
                                                                <input type={'text'} defaultValue={code.code} readOnly={true} />
                                                            :
                                                                <input type={'text'} defaultValue={code.link} readOnly={true} />
                                                    }
                                                </li>
                                            )
                                        })
                                    }
                                </ul>

                                <div className={'buttonBox'}>
                                    <button type={'button'} className={'button theme_color theme_bordercolor theme_background_hover'} onClick={e => this.props.setThisState(false)}>
                                        {getTranslation(this, this.props.state.lang, 'Cancel')}
                                    </button>

                                    {this.state.showme === 'codes' &&
                                        <button type={'button'} className={'button theme_color theme_bordercolor theme_background_hover'} onClick={e => this.clipBoardData(e, this.props.codes, 'code')}>
                                            {getTranslation(this, this.props.state.lang, 'CopyCodes')}
                                        </button>
                                    }

                                    {this.state.showme === 'links' &&
                                        <button type={'button'} className={'button theme_color theme_bordercolor theme_background_hover'} onClick={e => this.clipBoardData(e, this.props.codes, 'link')}>
                                            {getTranslation(this, this.props.state.lang, 'CopyLinks')}
                                        </button>
                                    }
                                </div>
                            </>
                        }

                    </div>

                </div>

            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
                codes: state.codes
            }
        },
        null
    )(
        Vouchers
    )
);

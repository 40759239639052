import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {
    getTranslation,
} from "../../../helper";
import {ReactComponent as Close} from "../../../../assets/img/close.svg";


class AddCompany extends Component {

    constructor(props) {
        super(props);

        this.state = this.props.data;
    }


    render() {
        return (
            <div className={'addUser overlayBox'}>

                <Close className={'close'} onClick={() => this.props.methods.setThisState(false)} />

                <form onSubmit={(e) => this.props.addUser(e)}>

                    <h3>{getTranslation(this, this.props.state.lang, 'NewCompany')}</h3>

                    <div className={'box'}>
                        <input type={'text'} placeholder={'Firmenname'} value={this.state.name} onChange={e => this.props.change({name: e.target.value})} />
                        <input type={'mail'} placeholder={'Firmen-E-Mail-Adresse'} value={this.state.mail} onChange={e => this.props.change({mail: e.target.value})} />
                    </div>

                    <h5>{getTranslation(this, this.props.state.lang, 'Licensemodel')}</h5>

                    <div className={'model'}>
                        <label htmlFor={'coach_card_10'}>
                            <input type={'radio'} id={'coach_card_10'} name={'restrictions'} value={'10'} onChange={e => this.props.change({restriction: Object.assign({}, this.state.restriction, {maxInvitationCount: 10})})} />
                            {getTranslation(this, this.props.state.lang, '10er')}
                        </label>

                        <label htmlFor={'coach_card_20'}>
                            <input type={'radio'} id={'coach_card_20'} name={'restrictions'} value={'20'} onChange={e => this.props.change({restriction: Object.assign({}, this.state.restriction, {maxInvitationCount: 20})})} />
                            {getTranslation(this, this.props.state.lang, '20er')}
                        </label>

                        <label htmlFor={'coach_card_25'}>
                            <input type={'radio'} id={'coach_card_25'} name={'restrictions'} value={'25'} onChange={e => this.props.change({restriction: Object.assign({}, this.state.restriction, {maxInvitationCount: 25})})} />
                            {getTranslation(this, this.props.state.lang, '25er')}
                        </label>

                        <label htmlFor={'coach_flat'}>
                            <input type={'radio'} id={'coach_flat'} name={'restrictions'} value={'flatrate'} onChange={e => this.props.change({restriction: Object.assign({}, this.state.restriction, {maxInvitationCount: false})})} />
                            {getTranslation(this, this.props.state.lang, 'Flatrate')}
                        </label>
                    </div>

                    <h5>{getTranslation(this, this.props.state.lang, 'Branding')}</h5>

                    <div className={'branding'}>
                        <label htmlFor={'coach_branding'}>
                            <input type={'checkbox'} id={'coach_branding'} value={this.state.restriction.brandingActivated} onChange={e => this.props.change({restriction: Object.assign({}, this.state.restriction, {brandingActivated: !this.state.restriction.brandingActivated})})} />
                            {getTranslation(this, this.props.state.lang, 'OwnBranding')}
                        </label>
                    </div>

                    <h5>{getTranslation(this, this.props.state.lang, 'AdminAccount')}</h5>

                    <div className={'selfCoach'}>
                        <input type={'checkbox'} id={'company_selfCoach'} value={this.state.roles.includes('Coach')} onChange={e => this.props.change({roles: ['Coach', 'BigCompany']})} />
                        <label htmlFor={'company_selfCoach'}>{getTranslation(this, this.props.state.lang, 'AdminIsCoach')}</label>
                    </div>

                    <div className={'buttonBox'}>
                        <button type={'button'} className={'button theme_color theme_bordercolor theme_background_hover'} onClick={() => this.props.methods.setThisState(false)}>
                            {getTranslation(this, this.props.state.lang, 'Cancel')}
                        </button>

                        <button className={'button theme_color theme_bordercolor theme_background_hover'}>
                            {getTranslation(this, this.props.state.lang, 'NewLicense')}
                        </button>
                    </div>

                </form>
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.setState(this.props.data);
        }
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
            }
        },
        null
    )(
        AddCompany
    )
);

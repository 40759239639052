import React, {Component} from 'react';
import {connect} from "react-redux";

import {ReactComponent as Info} from '../../assets/img/info.svg';
import {ReactComponent as Close} from '../../assets/img/close.svg';
import {copySessions} from '../helper';

import '../../assets/scss/balanceboard/infobubble.scss';

class InfoBubble extends Component {

    close() {
        let oldData = copySessions(this.props);

        oldData['infoBubble'] = {
            healthyworking: true
        };

        this.props.publish({sessions: {
            [this.props.userID]: {
                "@type":"Session",
                'sessionData': JSON.stringify(oldData)
            }
        }});
        this.props.toggleBlur(false);
    }

    render() {
        return (
            <div className={'infoBubbleBox'}>
                <div className={'infoBubble theme_background theme_borderEdgecolor_before'}>
                    <div className={'infoBubbleInner'}>
                        <Close className={'close'} onClick={() => this.close()} />

                        <h3>Hinweis</h3>
                        <div className={'infoText'}>
                            Im folgenden vergeben Sie bitte Ihre Handlungspunkte. Was läuft gut, was sollte verändert werden?
                            Klicken Sie dazu auf die mittleren Felder:<br /><br />

                            1x Klick = gefällt mir (positiv)<br />
                            2x Klick = gefällt mir nicht (Handlungsbedarf)<br />
                            3x Klick = Auswahl zurücknehmen (neutral)<br /><br />

                            Sie können max. 5 positive und 5 negative Punkte vergeben
                        </div>

                        <Info className={'infobubble'} />
                    </div>
                </div>
            </div>
        );
    }

}

export default connect(
    (state) => {
        return {
            sessions: state.sessions,
            questionData: state.questionData,
            invitationData: state.invitationData,
            userID: state.userID
        }
    },
    null
)(
    InfoBubble
);

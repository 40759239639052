import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import '../../../../assets/scss/admin/add/chooseSessionType.scss';
import {ReactComponent as Close} from "../../../../assets/img/close.svg";


class ChooseSessionType extends Component {

    render() {
        return (
            <div className={'addUser overlayBox'}>

                <Close className={'close'} onClick={() => this.props.methods.setThisState(false)} />

                <div className={'chooseSessionType'}>
                    <h3>
                        Sitzungstyp
                    </h3>

                    <ul className={'select'}>
                        <li className={'theme_bordercolor theme_color theme_background_hover'} onClick={() => this.props.methods.setSessions('session', 'addSession', {sessionData:JSON.stringify({withVideo: 'true'})})}>Videositzung</li>
                        <li className={'theme_bordercolor theme_color theme_background_hover'} onClick={() => this.props.methods.setSessions('session', 'addSession', {board:'balanceboard'})}>Balanceboard-Sitzung</li>
                        <li className={'theme_bordercolor theme_color theme_background_hover'} onClick={() => this.props.methods.setSessions('healthyWorking', 'addSession', null)}>BGM Sitzung</li>
                    </ul>

                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
            }
        },
        null
    )(
        ChooseSessionType
    )
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Route} from 'react-router-dom';
import {Client, StompConfig, StompHeaders} from "@stomp/stompjs";
import moment from 'moment';
import queryString from "query-string";
import axios from "axios";

import {setData} from "../../redux/actions";
import {changeColor, copySessions, getCode, getPermissions, getTranslation, logout, token} from "../helper";


import '../../assets/scss/lobby/lobby.scss';

import VideoLobby from './videoLobby.class';
import VideoLobbyConnected from './videoLobbyConnected.class'

import Balanceboard from '../balanceboard/routes.class';
import HealthyWorkingBoard from '../healthyworkingboard/routes.class';

import LobbyFooter from "./lobbyFooter.class";

class Lobby extends Component {

    constructor(props) {
        super(props);

        this.params = queryString.parse(props.location.search);

        this.state = {
            code: !!this.params.code ? this.params.code : (!!getCode() ? getCode() : null),

            video: true,
            audio: true,
            devices: 'audio,video',

            fullscreen: false,
            flying: false
        };

        this.startSession = this.startSession.bind(this);
        this.changeMedia = this.changeMedia.bind(this);
    }

    componentDidMount() {
        axios.get(process.env['REACT_APP_API'] + '/public/invitation/v1/' + this.state.code + "/" + (this.props.state.userID !== 'null' ? this.props.state.userID : 'SINGLE'), {
            'headers': {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': token()
            }
        }).then(result => {

            let invitationData = result.data;

            let sessions = Object.assign({}, this.props.state.defaultSessionsData);
            if (!!invitationData['sessions'] && !!invitationData['sessions'][this.props.state.userID] && !!invitationData['sessions'][this.props.state.userID]['sessionData']) {
                sessions = Object.assign({}, this.props.state.defaultSessionsData, JSON.parse(invitationData['sessions'][this.props.state.userID]['sessionData']));
                delete invitationData['sessions'];
            }

            this.props.setData({
                invitationData: invitationData,
                sessions: sessions
            });

            if (!!invitationData['coach'] && !!invitationData['coach']['themeColor']) {
                changeColor(invitationData['coach']['themeColor'], 'dynamicCss');
            }

            this.getInvitationsAndStartWS();
        });
    }

    getInvitationsAndStartWS() {
        let invitationData = {
            code: this.state.code
        };

        if (!getPermissions('Coach', this.props) && this.props.invitationData.invitationState !== 'Closed' && this.props.invitationData.invitationState !== 'Waiting' && !!this.props.sessions && ('started' in this.props.sessions && this.props.sessions.started === 0)) {
            invitationData.invitationState = 'Waiting';
        }

        if (getPermissions('Coach', this.props) && this.props.invitationData.invitationState !== 'Closed' && this.props.invitationData.invitationState !== 'Waiting' && !!this.props.sessions && ('started' in this.props.sessions && this.props.sessions.started === 0)) {
            invitationData.invitationState = 'Waiting';
        }

        let config = new StompConfig();
        config.brokerURL = process.env['REACT_APP_WS'] + '/stomp';
        config.connectHeaders = new StompHeaders();

        if (this.props.invitationData.board !== 'healthyworking') {
            if (getPermissions('Coach', this.props)) {
                config.connectHeaders.passcode = token().replace('Bearer ', ''); // use only if user is logged in and JWT token is available
            } else {
                config.connectHeaders.login = 'SINGLE'; // use for identification of session user (eg. browser fingerprint)
            }
        } else {
            if (getPermissions('Coach', this.props)) {
                config.connectHeaders.passcode = token().replace('Bearer ', ''); // use only if user is logged in and JWT token is available
            } else {
                config.connectHeaders.login = this.props.state.userID; // use for identification of session user (eg. browser fingerprint)
            }
        }

        this.client = new Client(config);

        let maxConnectionAttempts = 10;
        let currentTry = 0;

        // this.client.debug = (msg) => { console.log(msg);};
        // this.client.deactivate();

        this.client.beforeConnect = () => {
            currentTry++;

            if (currentTry > maxConnectionAttempts) {
                console.log(`Exceeds max attempts (${maxConnectionAttempts}), will not try to connect now`);

                // It is valid to call deactivate from beforeConnect
                this.client.deactivate().then();
            }
        }


        this.client.activate();


        this.client.onConnect = () => {

            if (invitationData.invitationState !== 'Closed') {

                this.client.subscribe('/user/topic/invitation/' + this.state.code, message => {

                    let invitationData = JSON.parse(message.body);

                    let sessions = Object.assign({}, this.props.state.defaultSessionsData);

                    if (/*invitationData['board'] !== 'healthyworking' &&*/ !getPermissions('Coach', this.props) && !!invitationData['sessions'] && !!invitationData['sessions']['SINGLE'] && !!invitationData['sessions']['SINGLE']['sessionData']) {
                        sessions = Object.assign({}, this.props.state.defaultSessionsData, JSON.parse(invitationData['sessions']['SINGLE']['sessionData']));
                        delete invitationData['sessions'];
                    } else if (getPermissions('Coach', this.props) && !!invitationData['sessions'] && invitationData['sessions']['SINGLE'] && invitationData['board'] !== 'healthyworking') {
                        sessions = Object.assign({}, this.props.state.defaultSessionsData, JSON.parse(invitationData['sessions']['SINGLE']['sessionData']));
                        delete invitationData['sessions'];
                    } else if (getPermissions('Coach', this.props) && !!invitationData['sessions'] && invitationData['board'] === 'healthyworking') {
                        sessions = {};

                        let sess = Object.assign({}, invitationData['sessions']);
                        delete invitationData['sessions'];

                        if (!!sess && Object.keys(sess).length) {
                            Object.keys(sess).forEach(session => {
                                if (!!JSON.parse(sess[session]['sessionData'])['results']) {
                                    sessions[session] = JSON.parse(sess[session]['sessionData'])['results'];
                                }
                            })
                        }

                    } else if (!getPermissions('Coach', this.props) && !!invitationData['sessions'] && invitationData['board'] === 'healthyworking') {
                        sessions = JSON.parse(invitationData['sessions'][this.props.state.userID]['sessionData']);
                    }



                    let questionData = null;
                    if (!!invitationData['questionData']) {
                        questionData = JSON.parse(invitationData['questionData']);
                        delete invitationData['questionData'];

                        if (!!questionData['color']) {
                            changeColor(questionData['color'], 'dynamicCss')
                        }
                    }

                    this.props.setData({
                        invitationData: invitationData,
                        sessions: sessions,
                        questionData: questionData
                    });
                });

                this.publish(invitationData);
            }

        }
    }

    componentWillUnmount() {
        if (!!this.client) {
            this.client.deactivate().then();
        }

        clearInterval(this.timer);
    }

    publish(data) {
        if (!!this.client) {
            this.client.publish({
                destination: '/app/invitation/' + this.state.code,
                body: JSON.stringify(data)
            });
        }
    }

    publishState(state) {
        let invitationData = {};

        invitationData.invitationState = state;

        if (state === 'Active') {
            invitationData.startDate = moment().toISOString();
        }

        this.publish(invitationData);
    }

    changeMedia(data) {
        this.setState(data);
    }

    startTimer() {

        this.start = moment(this.props.invitationData.startDate);

        this.timer = setInterval(() => {
            let difference = moment.duration(moment().diff(this.start));

            let element = document.getElementById('sessionDuration');
            if (!!element) {
                element.innerText = moment.utc(difference.asMilliseconds()).format('HH:mm:ss');
            }
        }, 1000);

    }

    getTimeDifference() {
        clearInterval(this.timer);

        this.start = moment(this.props.invitationData.startDate);
        this.end = moment(this.props.invitationData.endDate);

        let difference = moment.duration(moment(this.end).diff(this.start));

        let element = document.getElementById('sessionDuration');
        if (!!element) {
            element.innerText = moment.utc(difference.asMilliseconds()).format('HH:mm:ss');
        }
    }

    setFullscreen(data) {
        this.setState(data);
    }

    startSession() {
        let oldData = copySessions(this.props);
        oldData['started'] = 1;

        let invitationData = {};

        invitationData.invitationState = 'Active';
        invitationData.startDate = moment().toISOString();

        if (!!invitationData.sessions && !!invitationData.sessions[this.props.state.userID] && !!invitationData.sessions[this.props.state.userID]['sessionData']) {
            invitationData.sessions[this.props.state.userID]['sessionData'] = JSON.stringify(oldData);
        } else {
            invitationData.sessions = {
                'SINGLE': {
                    "@type":"Session",
                    'sessionData': JSON.stringify(oldData)
                }
            };
        }


        this.publish(invitationData);
    }

    resetSession() {
        let invitationData = {};

        invitationData.invitationState = 'Waiting';
        let sessions = copySessions(this.props);


        if (!!sessions) {

            delete sessions['results'];
            delete sessions['chat'];
            delete sessions['started'];
            delete sessions['infoBubble'];

            invitationData.sessions = {
                'SINGLE': {
                    '@type': 'Session',
                    'sessionData': JSON.stringify(sessions)
                }
            };
        }

        this.publish(invitationData);
    }

    coachStartsSession() {
        let oldData = copySessions(this.props);
        oldData['started'] = 0;


        let invitationData = {};

        invitationData.invitationState = 'Joined';
        invitationData.sessions = {
            'SINGLE': {
                '@type': 'Session',
                'sessionData': JSON.stringify(oldData)
            }
        };

        this.publish(invitationData);
    }

    render() {
        if (!!this.props.invitationData) {
            return (
                <div className={'lobbyContainer'}>

                    <Route exact path={'/lobby'}>

                        <div className={'lobby' + (this.state.fullscreen ? ' fullscreen' : '')}>

                            <div className={'connection'}>
                                <div
                                    className={'theme_color'}>{getTranslation(this, this.props.state.lang, 'SecuredConnection')}</div>
                                - <b>{getTranslation(this, this.props.state.lang, 'Duration')}: <span
                                id={'sessionDuration'}>--:--:--</span></b>
                            </div>

                            {/* Coachee is waiting */}
                            {(!!this.props.invitationData.invitationState && this.props.invitationData.invitationState === 'Waiting' && !getPermissions('Coach', this.props)) &&
                            <div className={'waitingLayer'}>
                                <div className={'waiting'}>
                                    <h1>{getTranslation(this, this.props.state.lang, 'WaitingRoom')}</h1>
                                    <h3>{getTranslation(this, this.props.state.lang, 'Hello')} {this.props.invitationData.coacheeName}</h3>

                                    <p>
                                        {getTranslation(this, this.props.state.lang, 'CoachAvailable').replace('*', !!this.props.invitationData['coach'] ? this.props.invitationData['coach']['name'] : '')}<br/>
                                        {getTranslation(this, this.props.state.lang, 'Date')}: {moment(this.props.invitationData.plannedDate).utc().format('DD.MM.YYYY')} / {getTranslation(this, this.props.state.lang, 'Time')}: {moment(this.props.invitationData.plannedDate).utc().format('HH:mm')} Uhr
                                    </p>
                                </div>
                            </div>
                            }

                            {/* Coach is Joined and has started the Call, Coachee can now select audio/video or only audio session */}
                            {(!!this.props.invitationData.invitationState && this.props.invitationData.invitationState === 'Joined' && !getPermissions('Coach', this.props)) &&
                            <div className={'waitingLayer'}>
                                <div className={'waiting'}>
                                    <h1>{getTranslation(this, this.props.state.lang, 'JoinVideoconference')}</h1>
                                    <h3>{getTranslation(this, this.props.state.lang, 'Hello')} {this.props.invitationData.coacheeName}</h3>

                                    <p>
                                        {getTranslation(this, this.props.state.lang, 'CoachReady').replace('*', !!this.props.invitationData['coach'] ? this.props.invitationData['coach']['name'] : '')}
                                    </p>

                                    <div className={'selection'}>
                                        <div className={'option'}>
                                            <input type={'radio'} name={'devices'} id={'audio,video'}
                                                   checked={this.state.devices === 'audio,video'} value={'audio,video'}
                                                   onChange={() => this.changeMedia({
                                                       audio: true,
                                                       video: true,
                                                       devices: 'audio,video'
                                                   })}/>
                                            <label
                                                htmlFor={'audio,video'}>{getTranslation(this, this.props.state.lang, 'AudioVideo')}</label>
                                        </div>

                                        <div className={'option'}>
                                            <input type={'radio'} name={'devices'} id={'audio'}
                                                   checked={this.state.devices === 'audio'} value={'audio'}
                                                   onChange={() => this.changeMedia({
                                                       audio: true,
                                                       video: false,
                                                       devices: 'audio'
                                                   })}/>
                                            <label
                                                htmlFor={'audio'}>{getTranslation(this, this.props.state.lang, 'AudioOnly')}</label>
                                        </div>
                                    </div>

                                    <button className={'button theme_color theme_bordercolor theme_background_hover'}
                                            id="btnStart" onClick={() => {
                                        this.startSession();
                                    }}>{getTranslation(this, this.props.state.lang, 'Join')}</button>
                                </div>
                            </div>
                            }

                            {/* Coach is Ready and coachee is waiting. Coach can now Join and start the session */}
                            {(!!this.props.invitationData.invitationState && this.props.invitationData.invitationState === 'Waiting' && getPermissions('Coach', this.props)) &&
                            <div className={'waitingLayer'}>
                                <div className={'waiting'}>
                                    <h1>{getTranslation(this, this.props.state.lang, 'StartVideoconference')}</h1>
                                    <h3>{getTranslation(this, this.props.state.lang, 'Hello')} {!!this.props.invitationData['coach'] ? this.props.invitationData['coach']['name'] : ''}</h3>

                                    <p>
                                        {getTranslation(this, this.props.state.lang, 'CoachWaitingRoom').replace('*', this.props.invitationData.coacheeName)}<br/>
                                        {getTranslation(this, this.props.state.lang, 'Date')}: {moment(this.props.invitationData.plannedDate).utc().format('DD.MM.YYYY')} / {getTranslation(this, this.props.state.lang, 'Time')}: {moment(this.props.invitationData.plannedDate).utc().format('HH:mm')} Uhr
                                    </p>

                                    <button className={'button theme_color theme_bordercolor theme_background_hover'}
                                            id="btnStart" onClick={() => {
                                        this.coachStartsSession();
                                    }}>{getTranslation(this, this.props.state.lang, 'StartSession')}</button>
                                </div>
                            </div>
                            }

                            {(!!this.props.invitationData.invitationState && this.props.invitationData.invitationState === 'Closed' && getPermissions('Coach', this.props)) &&
                            <div className={'waitingLayer'}>
                                <div className={'waiting'}>
                                    <h1>{getTranslation(this, this.props.state.lang, 'SessionAlreadyEnded')}</h1>

                                    <button className={'button theme_color theme_bordercolor theme_background_hover'}
                                            id="btnStart"
                                            onClick={() => this.props.history.push('/admin')}>{getTranslation(this, this.props.state.lang, 'BackToDashboard')}</button>
                                </div>
                            </div>
                            }

                            {(!!this.props.invitationData.invitationState && this.props.invitationData.invitationState === 'Closed' && !getPermissions('Coach', this.props)) &&
                            <div className={'waitingLayer'}>
                                <div className={'waiting'}>
                                    <h1>{getTranslation(this, this.props.state.lang, 'SessionEnded')}</h1>

                                    <p>
                                        {getTranslation(this, this.props.state.lang, 'SessionEndedByCoach')}<br/>
                                        {getTranslation(this, this.props.state.lang, 'ThankYou')}
                                    </p>
                                </div>
                            </div>
                            }

                            <div className={'lobbyBoxOuter'}>
                                {((!!this.props.invitationData.invitationState && this.props.invitationData.invitationState === 'Active') || (!!this.props.invitationData.invitationState && this.props.invitationData.invitationState === 'Joined' && !!getPermissions('Coach', this.props)))
                                    ?
                                    <VideoLobbyConnected setFullscreen={(e) => this.setFullscreen(e)}
                                                         code={this.state.code} publish={e => this.publish(e)}
                                                         publishState={e => this.publishState(e)} client={this.client}
                                                         audio={this.state.audio} video={this.state.video}/>
                                    :
                                    <VideoLobby setFullscreen={(e) => this.setFullscreen(e)} code={this.state.code}
                                                publish={e => this.publish(e)} publishState={e => this.publishState(e)}
                                                client={this.client} audio={this.state.audio} video={this.state.video}/>
                                }
                            </div>
                        </div>

                        <LobbyFooter resetSession={() => this.resetSession()} publish={e => this.publish(e)}/>
                    </Route>

                    {(this.props.invitationData['board'] === 'balanceboard') &&
                        <Route path={'/lobby/balanceboard'} render={(props) => (
                            <Balanceboard {...props} publish={e => this.publish(e)}/>
                        )}/>
                    }

                    {(this.props.invitationData['board'] === 'healthyworking') &&
                        <Route path={'/lobby/healthyworking'} render={(props) => (
                            <HealthyWorkingBoard {...props} publish={e => this.publish(e)}/>
                        )}/>
                    }


                </div>
            );
        } else {
            return <></>;
        }

    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextState.devices !== this.state.devices) {
            return true
        }

        if (JSON.stringify(nextProps.invitationData) !== JSON.stringify(this.props.invitationData) || JSON.stringify(nextProps.sessions) !== JSON.stringify(this.props.sessions)) {
            return true;
        } else {
            return false;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.invitationData.startDate !== this.props.invitationData.startDate) {
            this.startTimer();
        }

        if (prevProps.invitationData.invitationState !== this.props.invitationData.invitationState && this.props.invitationData.invitationState === 'Closed') {
            this.getTimeDifference();

            if (!getPermissions('Coach', this.props)) {
                logout(this.props);
                this.props.history.push('/login?ended=true');
            }
        }
    }
}


export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                sessions: state.sessions,
                invitationData: state.invitationData,
                language: state.language,
            }
        },
        {
            setData
        }
    )(
        Lobby
    )
);

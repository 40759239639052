import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import marked from "../markdown";

import '../../assets/scss/healthyworkingboard/start.scss';
import TopBox from "./topBox.class";
import moment from "moment";


class Start extends Component {
    render() {
        let text = moment(this.props.invitationData['plannedDate']).format('DD.MM.YYYY') + ' | ' + (!!this.props.invitationData.coacheeName ? this.props.invitationData.coacheeName : ' - ');

        return (
            <div className={'startContainer'}>

                <TopBox section={'MITARBEITERBEFRAGUNG'} setLanguage={this.props.setLanguage} />

                <div className={'subheadline'} dangerouslySetInnerHTML={{__html: (!!text ? marked(text) : '')}} />

                <div className={'start'}>
                    <div className={'sections'}>
                        <section dangerouslySetInnerHTML={{__html: (!!this.props.state.GAStart[this.props.state.lang] ? marked(this.props.state.GAStart[this.props.state.lang]['Text']) : '')}} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                invitationData: state.invitationData,
                sessions: state.sessions
            }
        },
        null
    )(
        Start
    )
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import '../../assets/scss/admin/filter.scss';
import {getTranslation} from "../helper";


class Filter extends Component {
    render() {
        return (
            <div className={'filter'}>
                <div className={'number'}>{getTranslation(this, this.props.state.lang, 'Number')}</div>
                <div className={'date'}>{getTranslation(this, this.props.state.lang, 'Date')}</div>
                <div className={'name'}>{getTranslation(this, this.props.state.lang, 'NameCompany')}</div>
                <div className={'mail'}>{getTranslation(this, this.props.state.lang, 'Mail')}</div>
                <div className={'model'}>{getTranslation(this, this.props.state.lang, 'Licensemodel')}</div>
                <div className={'state'}>{getTranslation(this, this.props.state.lang, 'Status')}</div>
                <div className={'options'} />
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
            }
        },
        null
    )(
        Filter
    )
);

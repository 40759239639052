import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {
    getFreeLinces, getPermissions,
    getTotalInvitations,
    getTranslation,
    getUsedLicences,
    token
} from "../../../helper";
import moment from "moment";
import axios from "axios";
import CircularColor from "react-circular-color";
import TimePicker from "rc-time-picker";

import HealthyWorkingCategory from "./category.class";
import HealthyWorkingNewCategory from './newCategory.class';

import '../../../../assets/scss/admin/add/healthyWorking/form.scss'

import {ReactComponent as Close} from '../../../../assets/img/close.svg';




class NewSession extends Component {

    constructor(props) {
        super(props);

        let questionData = (!!this.props.data.questionData && !!this.props.data.questionData) ? JSON.parse(this.props.data.questionData) : null;

        this.state = {
            showme: 'data',
            name: (!!this.props.data.name) ? this.props.data.name : '',
            url: !!questionData && !!questionData.url ? questionData.url : '',
            plannedDate: (this.props.data.invitationState !== 'Closed' && this.props.data.plannedDate) ? moment(this.props.data.plannedDate).utc().format('YYYY-MM-DDTHH:mm') : (!!this.props.data['code'] ? '' : moment().format('YYYY-MM-DDTHH:mm')),
            endDate: (this.props.data.invitationState !== 'Closed' && this.props.data.endDate) ? moment(this.props.data.endDate).utc().format('YYYY-MM-DDTHH:mm') : (!!this.props.data['code'] ? '' : moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm')),
            categories: !!questionData && !!questionData.questions ? questionData.questions : this.getCategories(),
            color: !!questionData && !!questionData.color ? questionData.color : (!!this.props.state.user.themeColor ? this.props.state.user.themeColor : this.props.state.config.Farbe),
            logo: !!questionData && !!questionData.logo ? questionData.logo : '',
            infoBubbleActive: !!questionData && !!questionData.infoBubbleActive ? questionData.infoBubbleActive : 'true',
        };

        this.getCategories = this.getCategories.bind(this);
        this.addCategory = this.addCategory.bind(this);
        this.removeCategory = this.removeCategory.bind(this);
        this.changeCategory = this.changeCategory.bind(this);

        this.changeAllQuestions = this.changeAllQuestions.bind(this);
        this.addQuestion = this.addQuestion.bind(this);
        this.updateQuestion = this.updateQuestion.bind(this);
        this.removeQuestion = this.removeQuestion.bind(this);

        this.changeColor = this.changeColor.bind(this);
        this.changeImage = this.changeImage.bind(this);
    }

    getCategories() {
        if (!!this.props.state.bgm && !!this.props.state.bgm['de'] && !!this.props.state.bgm['de']) {
            return this.props.state.bgm['de'];
        } else {
            return false;
        }
    }

    addCategory() {
        this.setState({
            categories: Object.assign({}, this.state.categories, {
                ['newCategory' + moment().toISOString()]: {
                    'new': true,
                    'enabled': true,
                    'edit': true,
                    'category': 'Neue Kategorie',
                    'questions': {}
                }
            })
        })
    }

    removeCategory(id) {
        let categories = JSON.parse(JSON.stringify(this.state.categories));
        delete categories[id];

        this.setState({
            categories: categories
        });
    }

    changeCategory(id, name, value) {
        let categories = JSON.parse(JSON.stringify(this.state.categories));
        categories[id][name] = value;

        this.setState({
            categories: categories
        });
    }

    changeAllQuestions(id, name, value) {
        let categories = JSON.parse(JSON.stringify(this.state.categories));
        let category = categories[id];
        let questions = category.questions;


        Object.keys(questions).map(question => {
            return questions[question][name] = value;
        });

        categories[id].questions = questions;
        categories[id][name] = value;

        this.setState({
            categories: categories
        });
    }

    changeAllQuestionsEnabled() {
        const enabled = this.getAllQuestionsEnabledStatus();

        let categories = JSON.parse(JSON.stringify(this.state.categories));

        if (Object.keys(categories).length) {
            Object.keys(categories).map(cat => {
                let category = categories[cat];

                category.enabled = enabled;

                category.questions = Object.keys(category.questions).map(que => {
                    let question = category.questions[que];

                    question.enabled = enabled;

                    return question;
                });

                return category;
            });
        }

        this.setState({
            categories: categories
        });
    }

    getAllQuestionsEnabledStatus() {
        let categories = JSON.parse(JSON.stringify(this.state.categories));
        let all = 0;
        let count = 0;

        if (Object.keys(categories).length) {
            Object.keys(categories).map(cat => {
                let category = categories[cat];

                if (category.enabled) {
                    all+=1;
                }
                count+=1;

                category.questions = Object.keys(category.questions).map(que => {
                    let question = category.questions[que];

                    if (question.enabled) {
                        all+=1;
                    }
                    count+=1;

                    return question;
                });

                return category;
            });
        }

        return all < count;
    }

    addQuestion(id) {
        let categories = JSON.parse(JSON.stringify(this.state.categories));
        let category = categories[id];
        let questions = category.questions;
        let key = Object.keys(questions).length;

        questions[key] = {
            category: category.category,
            question: '',
            enabled: true,
            deleteable: true,
            new: true
        }
        categories[id].questions = questions;

        this.setState({
            categories: categories
        });
    }

    updateQuestion(id, questionkey, name, value) {
        let categories = JSON.parse(JSON.stringify(this.state.categories));
        let category = categories[id];

        category.questions[questionkey][name] = value;
        categories[id].questions = category.questions;

        this.setState({
            categories: categories
        });
    }

    removeQuestion(id, questionkey) {
        let categories = JSON.parse(JSON.stringify(this.state.categories));
        let category = categories[id];

        delete category.questions[questionkey];

        let newQuestions = {};
        Object.keys(category.questions).forEach((value, key) => {
            newQuestions[key] = category.questions[value];
        });

        categories[id]['questions'] = newQuestions;

        this.setState({
            categories: categories
        });
    }

    methods() {
        return {
            getCategories: this.getCategories,
            addCategory: this.addCategory,
            removeCategory: this.removeCategory,
            changeCategory: this.changeCategory,

            changeAllQuestions: this.changeAllQuestions,
            addQuestion: this.addQuestion,
            updateQuestion: this.updateQuestion,
            removeQuestion: this.removeQuestion
        }
    }

    addSession(e) {
        e.preventDefault();

        let categories = Object.keys(this.state.categories).map(category => {
            let cat = this.state.categories[category];

            if (cat['enabled']) {
                cat['questions'] = Object.keys(cat['questions']).filter(c => cat['questions'][c]['enabled'] === true).map(c => cat['questions'][c]);

                return cat;
            } else {
                return false;
            }

        }).filter(q => !!q);

        if (!!categories) {
            let data = {
                "@type": "Invitation",
                coacheeName: this.state.name,
                plannedDate: moment.utc(this.state.plannedDate).format(),
                endDate: moment.utc(this.state.endDate).format(),
                questionData: JSON.stringify({
                    color: this.state.color,
                    logo: this.state.logo,
                    url: this.state.url,
                    infoBubbleActive: this.state.infoBubbleActive.toString(),
                    questions: categories
                }),
                hidden: false,
                invitationState: "Active",
                board : 'healthyworking',
            };

            axios.put(
                process.env['REACT_APP_API'] + '/private/invitation/v1',
                data, {
                    'headers': {
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': token()
                    }
                }
            ).then(() => {
                this.props.methods.getInvitationsAndStartWS();
                this.props.methods.setThisState(false);

            }).catch(error => {
                console.error(error.response);
                this.props.setData({
                    error: error.response.data
                });
            });
        }

    }

    editSession(e) {
        e.preventDefault();

        let invitationData = {};

        invitationData.coacheeName = this.state.name;

        if (!!this.state.plannedDate) {
            invitationData.plannedDate = moment.utc(this.state.plannedDate).format();
        }

        if (!!this.state.endDate) {
            invitationData.endDate = moment.utc(this.state.endDate).format();
        }

        invitationData.board = this.state.board;
        invitationData.questionData = JSON.stringify({
            color: this.state.color,
            logo: this.state.logo,
            url: this.state.url,
            infoBubbleActive: this.state.infoBubbleActive.toString(),
            questions: this.state.categories
        });

        axios.patch(
            process.env['REACT_APP_API'] + '/private/invitation/v1/' + this.props.data.id,
            invitationData, {
                'headers': {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': token()
                }
            }
        ).then(() => {
            if (getPermissions('BigCompany', this.props) || getPermissions('Admin', this.props)) {
                this.methods.props.getUsers();
            }

            this.props.methods.getInvitationsAndStartWS();
            this.props.methods.setThisState(false);

        }).catch(error => {
            console.error(error.response);
            this.props.setData({
                error: error.response.data
            });
        });
    }

    changeColor(color) {
        this.setState({
            color: color
        });
    }

    changeImage(event) {
        let self = this;
        let reader = new FileReader();
        reader.onload = function(e) {
            self.setState({logo: e.target.result});
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    render() {
        if (!!this.state.categories) {
            return (
                <div className={'addUser overlayBox healthyWorking ' + (this.state.showme)}>

                    <Close className={'close'} onClick={() => this.props.methods.setThisState(false)} />

                    <form onSubmit={(e) => (this.props.action === 'addSession') ? this.addSession(e) : this.editSession(e)} className={'addHealthyWorkingSession'}>
                        <h3>
                            {this.props.action === 'addSession'
                                ?
                                    getTranslation(this, this.props.state.lang, 'AddHealthyWorking')
                                :
                                    'BGM Sitzung bearbeiten'
                            } {getFreeLinces(this.props, this.props.invitations) ? '(' + getTotalInvitations(this.props.invitations) + '/' + getUsedLicences(this.props) + ')' : '' }
                        </h3>

                        <ul className={'tabs'}>
                            <li className={'theme_bordercolor_before' + (this.state.showme === 'data' ? ' current theme_bordercolor' : '')} onClick={() => this.setState({showme: 'data'})}>Sitzungsdaten</li>
                            <li className={'theme_bordercolor_before' + (this.state.showme === 'questions' ? ' current theme_bordercolor' : '')} onClick={() => this.setState({showme: 'questions'})}>Fragenkatalog</li>
                        </ul>

                        {this.state.showme === 'data' &&
                            <>
                                <h5>
                                    <div>Name/Firma</div>
                                    <div>Webseite</div>
                                </h5>

                                <div className={'box sessionData'}>
                                    <input type={'text'} placeholder={''} name={'name'} value={this.state.name} onChange={e => this.setState({name: e.target.value})} className={'sessionName'} />

                                    <input type={'url'} placeholder={'https://'} name={'url'} value={this.state.url} onChange={e => this.setState({url: e.target.value})} className={'sessionWebsite'} />
                                </div>

                                <h5>
                                    <label htmlFor={'avatar'}>
                                        {getTranslation(this, this.props.state.lang, 'YourLogo')}
                                    </label>
                                    <label htmlFor={'mail'}>
                                        {getTranslation(this, this.props.state.lang, 'Color')}
                                    </label>
                                </h5>

                                <div className={'box sessionLogoColor'}>
                                    <div className={'flex'}>
                                        <label htmlFor={'avatar'} className={'logo theme_bordercolor'}>
                                            {(!!this.state.logo)
                                                ?
                                                <img src={this.state.logo} alt={getTranslation(this, this.props.state.lang, 'Userpic')} />
                                                :
                                                <img src={'/assets/img/balance_aktiv.png'} alt={getTranslation(this, this.props.state.lang, 'Userpic')} />
                                            }
                                            <input type={'file'} id={'avatar'} name={'avatar'} accept={'.jpg, .jpeg, .png'} onChange={this.changeImage} />
                                        </label>
                                    </div>

                                    <div className={'flex'}>
                                        <div className={'color small'}>
                                            <CircularColor size={160} onChange={this.changeColor} color={!!this.state.color ? this.state.color : '#f29200'} numberOfSectors={60} />
                                        </div>
                                    </div>
                                </div>


                                <h5>
                                    <div>Zeitraum</div>
                                    <div>Bis</div>
                                </h5>

                                <div className={'box dateTime'}>
                                    <input type={'date'} placeholder={''} name={'plannedDate'} value={moment.utc(this.state.plannedDate).format('YYYY-MM-DD')} onChange={e => this.setState({plannedDate: moment.utc(e.target.value + ' ' + moment.utc(this.state.plannedDate).format('HH:mm')).format('YYYY-MM-DDTHH:mm')})} required={true} />
                                    <TimePicker name={'plannedDate'} value={moment.utc(this.state.plannedDate)} minuteStep={5} showSecond={false} onChange={value => this.setState({plannedDate: moment(value).format()})} />

                                    <input type={'date'} placeholder={''} name={'plannedDate'} value={moment.utc(this.state.endDate).format('YYYY-MM-DD')} onChange={e => this.setState({endDate: moment.utc(e.target.value + ' ' + moment.utc(this.state.endDate).format('HH:mm')).format('YYYY-MM-DDTHH:mm')})} required={true} />
                                    <TimePicker name={'plannedDate'} value={moment.utc(this.state.endDate)} minuteStep={5} showSecond={false} onChange={value => this.setState({endDate: moment(value).format()})} />
                                </div>

                                <h5>
                                    <div>Sitzungshinweise anzeigen?</div>
                                </h5>

                                <div className={'box infoBubble'}>
                                    <input type={'checkbox'} id={'infoBubbleActive'} name={'infoBubbleActive'} value={'true'} checked={this.state.infoBubbleActive === 'true'} onChange={e => this.setState({infoBubbleActive: !!e.target.checked ? 'true' : 'false'})} />
                                </div>
                            </>
                        }


                        {this.state.showme === 'questions' &&
                            <div className={'categories'}>
                                <div className={'category__activateAll'}>
                                    <input type={'checkbox'} id={'checkAll'} name={''} checked={!this.getAllQuestionsEnabledStatus()} className={'category__activateAll__enable ' + (!this.getAllQuestionsEnabledStatus() ? 'category__activateAll__enable--disabled' : '')}  onChange={() => this.changeAllQuestionsEnabled()} />
                                    <label htmlFor={'checkAll'} className={'category__activateAll__text'}>
                                        {!this.getAllQuestionsEnabledStatus() ? 'Alle deaktivieren' : 'Alle aktivieren' }
                                    </label>
                                </div>

                                {
                                    Object.keys(this.state.categories).map(_category => {
                                        let questions = this.state.categories[_category]['questions'];
                                        let category = this.state.categories[_category]['category'];

                                        return <HealthyWorkingCategory key={_category} datakey={_category} data={this.state.categories[_category]} category={category} questions={questions} methods={this.methods()} />
                                    })
                                }

                                {Object.keys(this.state.categories).length < 8 &&
                                    <HealthyWorkingNewCategory addCategory={this.addCategory} />
                                }

                            </div>
                        }


                        <div className={'buttonBox'}>
                            <button type={'button'} className={'button theme_color theme_bordercolor theme_background_hover'} onClick={() => this.props.methods.setThisState(false)}>
                                {getTranslation(this, this.props.state.lang, 'Cancel')}
                            </button>

                            <button className={'button theme_color theme_bordercolor theme_background_hover'}>
                                {getTranslation(this, this.props.state.lang, (this.props.action === 'editSession' ? 'SaveSession' : 'AddNewSession'))}
                            </button>
                        </div>

                    </form>
                </div>
            );
        } else {
            return false;
        }

    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
            }
        },
        null
    )(
        NewSession
    )
);

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../../assets/scss/healthyworkingboard/form.scss';
import Cell from "./cellDiv.class";
import Result from "./resultDiv.class";
import {connect} from "react-redux";
import {setData} from "../../redux/actions";
import TopBox from "./topBox.class";
import marked from "../markdown";

const ResultCellFunc = (props) => {
    let cell = [];
    let sub = [];
    let subWith = '';
    let add = [];
    let addWith = '';

    let subCount = 0;
    if (!!props.results[-3] || !!props.results[-2] || props.results[-1]) {
        subCount = (!!props.results[-3] ? props.results[-3] : 0) + (!!props.results[-2] ? props.results[-2] : 0) + (!!props.results[-1] ? props.results[-1] : 0)
    }
    for(let i = -3; i < 0; i++) {
        if (!!props.results && !!props.results[i]) {
            sub.push(<Result width={props.results[i] / subCount * 100} elm={i} key={'cellDiv_' + props.category + '_' + props.question + '__' + i} type={'normal'} sessions={(!!props.sessions && props.sessions.results ? props.sessions.results : null)} results={!!props.results ? (!!props.results[i] ? props.results[i] : 0) : 0} importants={!!props.importants ? props.importants : 0} positive={!!props.positive ? props.positive : 0} question={props.question} section={props.section} />);
            subWith += (props.results[i] / subCount * 100) + '% ';
        }
    }

    cell.push(
        <div className={'sub'} style={{gridTemplateColumns: subWith}} key={'sub_' + props.category + '_' + props.question + '_' + 0}>
            {sub}
        </div>
    );

    cell.push(<Result elm={'negative'} key={'cellDiv_' + props.category + '_' + props.question + '__negative__' + 0} sessions={(!!props.sessions && props.sessions.results ? props.sessions.results : null)} results={!!props.results ? (!!props.results[0] ? props.results[0] : 0) : 0} importants={!!props.importants ? props.importants : props.allImportants} positive={!!props.positive ? props.positive : props.allPositive} question={props.question} section={props.section} />);
    cell.push(<Result elm={'positive'} key={'cellDiv_' + props.category + '_' + props.question + '__positive__' + 0} sessions={(!!props.sessions && props.sessions.results ? props.sessions.results : null)} results={!!props.results ? (!!props.results[0] ? props.results[0] : 0) : 0} importants={!!props.importants ? props.importants : props.allImportants} positive={!!props.positive ? props.positive : props.allPositive} question={props.question} section={props.section} />);

    let addCount = 0;
    if (!!props.results[1] || props.results[2] || props.results[3]) {
        addCount = (!!props.results[1] ? props.results[1] : 0) + (!!props.results[2] ? props.results[2] : 0) + (!!props.results[3] ? props.results[3] : 0)
    }
    for(let i = 1; i <= 3; i++) {
        if (!!props.results && !!props.results[i]) {
            add.push(<Result width={props.results[i] / addCount * 100} elm={i} key={'cellDiv_' + props.category + '_' + props.question + '__' + i} sessions={(!!props.sessions && props.sessions.results ? props.sessions.results : null)} results={!!props.results ? (!!props.results[i] ? props.results[i] : 0) : 0} importants={!!props.importants ? props.importants : 0} positive={!!props.positive ? props.positive : 0} question={props.question} section={props.section} />);
            addWith += (props.results[i] / addCount * 100) + '% ';
        }
    }

    cell.push(
        <div className={'add'} style={{gridTemplateColumns: addWith}} key={'add_' + props.category + '_' + props.question + '_' + 0}>
            {add}
        </div>
    );

    return cell;
}

const CellFunc = (props) => {
    let cells = [];

    if (props.show === 'options') {
        for(let i = -3; i <= 3; i++) {
            cells.push(
                <Cell {...props}
                      elm={i}
                      key={'cellDiv_' + props.data.category + '_' + props.question + '_' + i}
                      type={'normal'}
                      importants={!!props.sessions.results[props.question] ? props.sessions.results[props.question]['important']: []}
                      allImportants={props.allImportants}
                      allPositive={props.allPositive}
                />);
        }
    } else {
        cells.push(<ResultCellFunc key={'ResultCellFunc_' + props.question} category={props.data.category} question={props.question} section={props.section} sessions={props.sessions} results={props.results} importants={props.importants} positive={props.positive} allImportants={props.allImportants} allPositive={props.allPositive} />);
    }

    return cells;
};


class Form extends Component {

    render() {
        let headline = this.props.data['category'];
        let text = !!this.props.state['subheadline'] ? this.props.state['subheadline'][this.props.state.lang][this.props.section] : '';

        let path = this.props.location.pathname;
        let page = 1;

        if (path.indexOf('/rating') > -1) {
            page = 2;
        } else {
            page = 1;
        }

        let questions;

        if(this.props.sorting) {
            if (this.props.sortDirection === 'desc') {
                questions = this.props.data['questions'].sort((a,b) => {
                    return b[this.props.sorting] - a[this.props.sorting];
                });
            } else {
                questions = this.props.data['questions'].sort((a,b) => {
                    return a[this.props.sorting] - b[this.props.sorting];
                });
            }
        } else {
            questions = this.props.data['questions']
        }

        return (
            <div className={'formData'}>

                {!!headline &&
                    <>
                        <TopBox section={(this.props.dataKey + 1) + ') ' + headline} setLanguage={this.props.setLanguage} />

                        <div className={'subheadline'} dangerouslySetInnerHTML={{__html: (!!text ? marked(text) : '')}} />
                    </>
                }


                {(!this.props.hideLabelBar) &&
                    <div className={'labels'}>

                        <div className={'question'}>
                            Frage<br/> Nr.
                        </div>

                        <div className={'line left'}/>

                        <div className={'noMatch'}>
                            Trifft für mich<br/> eher nicht zu
                        </div>

                        <div className={'actionRequired'}>
                            Ihre Auswahl<br/> (Schritt {page}/2)
                        </div>

                        <div className={'match'}>
                            Trifft für mich<br/> eher zu
                        </div>

                        <div className={'line right'}/>

                        <div className={'space'}/>
                    </div>
                }

                <div className={'questions'}>
                    {!!this.props.data['questions'] && questions.map((question, i) => {
                        return (
                            <div key={'q_' + i} className={'question'}>
                                <div className={'top'}>
                                    <div className={'question'}>
                                        {question.qnr + ') '}
                                    </div>

                                    <div className={'line left'} />

                                    <div className={'questionText'}>
                                        <span className={'questionNumber'}>
                                            {question.qnr + ') '}
                                        </span>
                                        {question.question}
                                    </div>

                                    <div className={'line right'} />

                                    <div className={'space'} />
                                </div>

                                <div className={'slider'}>
                                    <div className={'space'} />

                                    <div className={'line left'} />

                                    <div className={'selectionBox ' + (this.props.show ? this.props.show : '')}>
                                        <CellFunc
                                            key={'cellfunc_' + this.props.dataKey + '_' + question.nr} {...this.props}
                                            data={question}
                                            publish={this.props.publish}
                                            section={this.props.dataKey}
                                            question={question.nr}
                                            area={'q'}
                                            show={this.props.show}
                                            results={!!this.props.results ? (!!this.props.results[question.nr] ? this.props.results[question.nr] : false) : (!!question.results ? question.results : false)}
                                            importants={!!this.props.importants ? (this.props.importants[question.nr] ? this.props.importants[question.nr] : false) : false}
                                            positive={!!this.props.positive ? (this.props.positive[question.nr] ? this.props.positive[question.nr] : false) : false}
                                            allImportants={!!this.props.allImportants ? this.props.allImportants : (!!question.allImportants ? question.allImportants : 0)}
                                            allPositive={!!this.props.allPositive ? this.props.allPositive : (!!question.allPositives ? question.allPositives : 0)}
                                        />
                                    </div>

                                    <div className={'line right'} />

                                    <div className={'space'} />
                                </div>
                            </div>
                        );
                    })}
                </div>

            </div>
        );
    }

    componentDidMount() {
        if (this.props['infoBubble']) {
            this.props.toggleBlur({
                section: this.props.section.replace(' ', '-'),
                data: this.props['infoBubble']
            });
        }

        if (this.props.dataKey === 0) {
            this.props.callObserver();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps['infoBubble'] !== this.props['infoBubble']) {
            this.props.toggleBlur({
                section: this.props.section.replace(' ', '-'),
                data: this.props['infoBubble']
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (JSON.stringify(nextProps.state.invitationData) !== JSON.stringify(this.props.state.invitationData) || JSON.stringify(nextProps.state.sessions) !== JSON.stringify(this.props.state.sessions) || this.props.location.pathname !== nextProps.location.pathname || this.props.sorting !== nextProps.sorting || this.props.sortDirection !== nextProps.sortDirection)
    }

}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                sessions: state.sessions,
                questionData: state.questionData,
                userID: state.userID
            }
        },
        {
            setData
        }
    )(
        Form
    )
);

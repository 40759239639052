import React, {Component} from 'react';
import marked from "../markdown";

import {ReactComponent as Info} from '../../assets/img/info.svg';
import {ReactComponent as Close} from '../../assets/img/close.svg';
import '../../assets/scss/balanceboard/infobubble.scss';
import {connect} from "react-redux";
import {setData} from "../../redux/actions";
import {copySessions} from "../helper";


class InfoBubble extends Component {

    close() {

        let oldData = copySessions(this.props);
        if (!oldData['infoBubble']) {
            oldData['infoBubble'] = {};
        }
        oldData['infoBubble'][this.props.section] = true;

        this.props.publish({sessions: {
            'SINGLE': {
                "@type":"Session",
                'sessionData': JSON.stringify(oldData)
            }
        }});
        this.props.toggleBlur(false);
    }


    render() {
        return (
            <div className={'infoBubbleBox'}>
                <div className={'infoBubble theme_background theme_borderEdgecolor_before'}>
                    <div className={'infoBubbleInner'}>
                        <Close className={'close'} onClick={() => this.close()} />

                        <h3>{this.props.infoBubble['headline']}</h3>
                        <div className={'infoText'} dangerouslySetInnerHTML={{__html: (!!this.props.infoBubble['text'] ? marked(this.props.infoBubble['text']) : '')}} />

                        <Info className={'infobubble'} />
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            state: state,
            sessions: state.sessions
        }
    },
    {
        setData
    }
)(
    InfoBubble
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import '../../../../assets/scss/admin/add/healthyWorking/question.scss';


class HealthyWorkingQuestion extends Component {

    render() {
        return (
            <div className={'category__question'}>
                <input type={'checkbox'} name={''} checked={this.props.question.enabled} className={'category__question__enable ' + (!this.props.question.enabled ? 'category__question__enable--disabled' : '')}  onChange={e => this.props.methods.updateQuestion(this.props.datakey, this.props.questionkey, 'enabled', e.target.checked)} />

                <textarea rows={2} name={''} value={this.props.question['question']} readOnly={!this.props.question.enabled} className={'category__question__name' + (!this.props.question.enabled ? ' category__question__name--disabled' : '')} onChange={e => this.props.methods.updateQuestion(this.props.datakey, this.props.questionkey, 'question', e.target.value)} />

                <div className={'category__question__remove material-icons'} onClick={() => this.props.methods.removeQuestion(this.props.datakey, this.props.questionkey)}>clear</div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        null
    )(
        HealthyWorkingQuestion
    )
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import moment from "moment";

import {getPermissions, getTranslation} from "../helper";

import '../../assets/scss/lobby/chat.scss';


class Chat extends Component {

    constructor(props) {
        super(props);

        this.state = {
            chat: {
                user: '',
                date: '',
                message: ''
            }
        };

        this.chat = this.chat.bind(this);
        this.setChatMessage = this.setChatMessage.bind(this);
    }

    chat(event) {
        if (event.keyCode === 13 && !!this.state.chat.message) {
            event.preventDefault();

            let messages;
            if (!!this.props.sessions.chat) {
                messages = [...this.props.sessions.chat];
            } else {
                messages = [];
            }

            messages.push(this.state.chat);

            this.setState({
                chat: {
                    user: '',
                    date: '',
                    message: ''
                }
            });

            let data = Object.assign({}, this.props.sessions);

            data.chat = messages;

            this.props.publish({
                "sessions": {
                    'SINGLE': {
                        '@type': 'Session',
                        'sessionData': JSON.stringify(data)
                    }
                }
            });

        }
    }

    setChatMessage(event) {
        let name;
        if (!!getPermissions('Coach', this.props)) {
            name = !!this.props.invitationData.coach ? this.props.invitationData.coach.name : '';
        } else {
            name = this.props.invitationData.coacheeName;
        }

        this.setState({
            chat: {
                user: name,
                date: moment().toISOString(),
                message: event.target.value
            }
        });
    }

    render() {
        let name;

        if (getPermissions('Coach', this.props)) {
            name = (!!this.props.invitationData['coach'] && !!this.props.invitationData['coach']['name']) ? this.props.invitationData['coach']['name'] : '';
        } else {
            name = !!this.props.invitationData.coacheeName ? this.props.invitationData.coacheeName : ''
        }

        let chat;
        if (!!!!this.props.sessions && !!this.props.sessions.chat) {
            chat = [...this.props.sessions.chat].reverse();
        }

        return (
            <div className={'chatBox'}>

                <h3>{getTranslation(this, this.props.state.lang, 'Chat')}</h3>

                <div className={'chatBoxInner'}>

                    <div className={'left'}>
                        <div className={'chatBoxInput'}>
                            <input type={'text'} placeholder={getTranslation(this, this.props.state.lang, 'Hello') + ' ' + (name)} onChange={e => this.setChatMessage(e)} onKeyUp={e => this.chat(e)} value={this.state.chat.message} disabled={this.props.invitationData['invitationState'] === 'Sent'}/>
                            <span className={'icon-up material-icons'}>subdirectory_arrow_left</span>
                        </div>
                    </div>

                    <div className={'right'}>
                        {!!chat &&
                        chat.map((chatMessage, key) => {
                            return (
                                <div className={'chatMessage'} key={'chatMessage_' + key}>
                                    <tt className={'time'}>{moment(chatMessage.date).format('HH:mm')} - </tt>
                                    <strong>{chatMessage.user}: </strong>
                                    <span>{chatMessage.message}</span>
                                </div>
                            )
                        })
                        }
                    </div>

                </div>

            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                invitationData: state.invitationData,
                sessions: state.sessions,
                language: state.language,
            }
        },
        null
    )(
        Chat
    )
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getTranslation, loggedin} from "../helper";
import Forms from "./forms.class";
import Field from "./field.class";
import {ReactComponent as Minimize} from "../../assets/img/minimize.svg";
import {ReactComponent as Maximize} from "../../assets/img/maximize.svg";
import InfoBubble from "./infobubble.class";
import {cookie} from "cookie.js";

import {setData} from "../../redux/actions";
import queryString from "query-string";

class Routes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            view: 'form'
        };

        this.params = queryString.parse(this.props.location.search);
        this.code = '';
        if (!!this.params['code']) {
            this.code = '?code=' + this.params['code'];
        }

        this.toggleBlur = this.toggleBlur.bind(this);
        this.setLanguage = this.setLanguage.bind(this);
    }

    toggleZoom(state) {
        this.props.setData({
            zoomed: state
        });
    }

    toggleBlur(state) {
        if (!!state) {
            this.setState({
                infoBubble: state
            });
        }
    }

    setLanguage(lang) {
        cookie.set('language', lang);

        this.language = lang;

        this.props.setData({
            lang: lang
        })
    }

    render() {

        if (!!this.props.structure && Object.keys(this.props.invitationData).length) {
            let infoBubbleState = (!!this.state.infoBubble && !!this.state.infoBubble['data'] && (!this.props.sessions.infoBubble || !this.props.sessions.infoBubble[this.state.infoBubble['section']]) && this.props.sessions.infoBubbleActive === 'true');

            return (
                <>
                    <ul className={'switcher ' + this.state.view}>
                        <li className={this.state.view === 'form' ? 'current theme_bordercolor_before theme_color': ''} onClick={() => this.setState({view: 'form'})}>{getTranslation(this, this.props.lang, 'Statement')}</li>
                        <li className={this.state.view === 'game' ? 'current theme_bordercolor_before theme_color': ''} onClick={() => this.setState({view: 'game'})}>{getTranslation(this, this.props.lang, 'Field')}</li>
                    </ul>

                    <div className={'app' + (!loggedin() || (infoBubbleState ? ' blur' : '')) + ' ' + this.props.position + ' ' + this.state.view}>
                        <Forms publish={this.props.publish} switchBoard={this.switchBoard} position={this.position} toggleBlur={this.toggleBlur} setLanguage={this.setLanguage} />

                        <div className={'game'}>
                            <div className={'gameBox'}>
                                <Field publish={this.props.publish} />
                            </div>
                        </div>

                        {['/Sinn', '/Arbeit', '/Gesundheit', '/Soziale-Beziehungen'].indexOf(this.props.location.pathname) > -1 && (
                            this.props.zoomed ?
                                <Minimize onClick={() => this.toggleZoom(false)} className={'zoom theme_svg_hover'} title={'ZOOM'} />
                                :
                                <Maximize onClick={() => this.toggleZoom(true)} className={'zoom theme_svg_hover'} title={'ZOOM'} />
                        )}
                    </div>

                    {(infoBubbleState && this.props.sessions.infoBubbleActive === 'true') &&
                        <InfoBubble section={this.state.infoBubble['section']} infoBubble={this.state.infoBubble['data']} publish={(e) => this.props.publish(e)} toggleBlur={this.toggleBlur} />
                    }
                </>
            );
        } else {
            return <></>;
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.sessions.path !== this.props.sessions.path) {
            this.props.history.push({
                pathname: this.props.sessions.path,
                search: this.code
            })
            // history.push(this.props.sessions.path);

            if (!!this.props.sessions && ['Sinn', 'Arbeit', 'Gesundheit', 'Soziale-Beziehungen'].indexOf(this.props.sessions.path.replace('/lobby/balanceboard/','')) > -1) {
                this.props.setData({
                    zoomed: true
                });
            } else {
                this.props.setData({
                    zoomed: false
                });
            }
        }
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                // state: state,
                invitationData: state.invitationData,
                sessions: state.sessions,
                lang: state.lang,
                position: state.position,
                zoomed: state.zoomed,
                structure: state.structure,
                language: state.language,
            }
        },
        {
            setData
        }
    )(
        Routes
    )
);

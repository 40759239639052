/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
import {cookie} from "cookie.js";

export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

/**
 * Deep merge two objects.
 * @param target
 * @param sources
 */
export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (source.hasOwnProperty(key)) {
                if (isObject(source[key])) {
                    if (!target[key]) Object.assign(target, { [key]: {} });
                    mergeDeep(target[key], source[key]);
                } else {
                    Object.assign(target, { [key]: source[key] });
                }
            }
        }
    }

    return mergeDeep(target, ...sources);
}


export function getTotalPercentage(props, section) {
    let data = props.state[section];
    let total = 0;

    if (!!data) {
        Object.keys(data).forEach(d => {
            total += data[d].value;
        })
    }

    return total;
}

export function loggedin() {
    let storage = localStorage.getItem('user');
    let data = JSON.parse(storage);

    if (!!storage && !!data) {
        return data;
    } else {
        return false;
    }
}

export function token() {
    let storage = localStorage.getItem('token');
    let data = JSON.parse(storage);

    if (!!storage && !!data) {
        return data;
    } else {
        return false;
    }
}

export function userType() {
    let storage = localStorage.getItem('userType');
    let data = JSON.parse(storage);

    if (!!storage && !!data) {
        return data;
    } else {
        return false;
    }
}


export function getCode() {
    let storage = localStorage.getItem('code');
    let data = JSON.parse(storage);

    if (!!storage && !!data) {
        return data;
    } else {
        return false;
    }
}

export function boardPosition() {
    let data = localStorage.getItem('position');

    if (!!data) {
        return data;
    } else {
        return 'right';
    }
}

/* ... */
export function hexToBase64(str) {
    return btoa(String.fromCharCode.apply(null,
        str.replace(/[\r\n]/g, '').replace(/([\da-fA-F]{2}) ?/g, '0x$1 ').replace(/ +$/, '').split(' '))
    );
}


export function logout(props) {
    cookie.set('userID', null, {
        path:'/',
    });

    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('code');
    localStorage.removeItem('position');

    props.setData({
        questions: mergeDeep({}, props.state.dataBackup.questions),
        time: mergeDeep({}, props.state.dataBackup.time),
        meaning: mergeDeep({}, props.state.dataBackup.meaning),
        infoBubble: mergeDeep({}, props.state.dataBackup.infoBubble),
        user: false,
        userType: '',
        users: [],
        position: 'right',
        logout: false
    });
}

export function getTranslation(self, language, str) {
    if (!!self.props.language && self.props.language[language]) {
        if (!!self.props.language[language][str]) {
            return self.props.language[language][str]
        } else {
            return '';
        }
    } else {
        return '';
    }
}

export function getTranslationHeadline(self, lang, str) {
    if (!!self && self[lang] && self[lang][str]) {
        if (!!self[lang][str]) {
            return self[lang][str]
        } else {
            return '';
        }
    } else {
        return '';
    }
}

export function getPermissions(permission, props) {
    if (!!props.state && !!props.state.user && !!props.state.user.roles) {
        return props.state.user.roles.includes(permission);
    } else {
        return false;
    }
}

export function getLicenceModel(self, props) {
    if (!!props.state.user) {
        if (!!props.state.user.restriction && !!props.state.user.restriction.maxInvitationCount && props.state.user.restriction.maxInvitationCount !== 0) {
            return getTranslation(self, props.state.lang, props.state.user.restriction.maxInvitationCount + 'er');
        } else {
            return 'Flatrate';
        }
    } else {
        return '';
    }
}

export function getLicenceUser(self, data) {
    if (!!data.restriction && !!data.restriction.maxInvitationCount && data.restriction.maxInvitationCount !== 0) {
        return getTranslation(self, self.props.state.lang, data.restriction.maxInvitationCount + 'er');
    } else {
        return 'Flatrate';
    }
}

export function getTotalInvitations(invitations) {
    let total = '';
    if (!!invitations) {
        total = 0;
        if (!!invitations.open) {
            total += Object.keys(invitations.open).length;
        }

        if (!!invitations.closed) {
            total += Object.keys(invitations.closed).length;
        }
    }

    return total;
}

export function getUsedLicences(props) {
    if (!!props.state.user) {
        if (!!props.state.user.restriction && !!props.state.user.restriction.maxInvitationCount && props.state.user.restriction.maxInvitationCount !== 0) {
            return props.state.user.restriction.maxInvitationCount;
        } else {
            return '';
        }
    } else {
        return '';
    }
}

export function getFreeLinces(props, invitations) {
    if (!!props.state.user) {
        if (!!props.state.user.restriction && !!props.state.user.restriction.maxInvitationCount && props.state.user.restriction.maxInvitationCount !== 0) {
            return Number(props.state.user.restriction.maxInvitationCount) - Number(getTotalInvitations(invitations));
        } else {
            return '';
        }
    } else {
        return '';
    }
}

export function clearUrl(props) {
    return props.location.pathname.replace('/lobby/balanceboard/','').replace('/lobby/healthylivingboard/','');
}

export function copySessions(props) {
    if (!!props['sessions']) {
        return JSON.parse(JSON.stringify(props['sessions']));
    } else {
        return {};
    }
}

export function getCellData(props, section, question) {
    if (!!props.sessions && !!props.sessions['results'] && !!props.sessions['results'][section] && !!props.sessions['results'][section][question]) {
        return parseInt(props.sessions['results'][section][question]);
    } else {
        return 0;
    }
}

export function getBGMCellData(props, section, question) {
    if (!!props.sessions && !!props.sessions['results'] && !!props.sessions['results'][section] && !!props.sessions['results'][section]['answers'] && !!props.sessions['results'][section]['answers'][question]) {
        return parseInt(props.sessions['results'][section]['answers'][question]);
    } else {
        return 0;
    }
}

export function getImportantBGMCellData(props, section, question) {
    return (!!props.sessions && !!props.sessions['results'] && !!props.sessions['results'][section] && !!props.sessions['results'][section]['important'] && props.sessions['results'][section]['important'].includes(question));
}
export function getPositiveBGMCellData(props, section, question) {
    return (!!props.sessions && !!props.sessions['results'] && !!props.sessions['results'][section] && !!props.sessions['results'][section]['positive'] && props.sessions['results'][section]['positive'].includes(question));
}

export function getQuestionsWithResults(props, section, location) {

    let sessionResult = props.sessions['results'];
    let data;

    if (section === 'questions') {
        data = props.state[section][props.state.lang][location];
    } else {
        if (!!section && !!props.state[section]) {
            data = props.state[section]['questions'][props.state.lang];
        } else if (!!props.state[location]) {
            data = props.state[location]['questions'][props.state.lang];
        }
    }

    if (!!data) {
        let _results = {};
        let sum = 0;

        if (section === 'questions' && !!sessionResult && !!sessionResult[location]) {

            Object.keys(data).filter((_question, key) => {
                return sessionResult[location][_question]
            }).map(_question => {
                let answer = sessionResult[location][_question];

                if (!!answer) {
                    sum += Number(answer);
                    return _results[_question] = answer;
                } else {
                    return false;
                }
            });

            return {[location]: {
                    questions: Object.keys(data).length,
                    answered: Object.keys(_results).length,
                    results: _results,
                    total: sum
            }};

        } else if (!!sessionResult && !!sessionResult[section] && !!sessionResult[section][location] && !!section) {

            let answer = sessionResult[section][location];

            if (!!answer) {
                sum = answer;
                _results[location] = answer;
            } else {
                return false;
            }

        } else if (!!sessionResult && !!sessionResult[location] && !section) {

            Object.keys(data).filter((_question, key) => {
                return sessionResult[location][_question]
            }).map(_question => {
                let answer = sessionResult[location][_question];

                if (!!answer) {
                    sum += Number(answer);
                    return _results[_question] = answer;
                } else {
                    return false;
                }
            });

        } else {
            return null
        }

        return {
            questions: Object.keys(data).length,
            answered: Object.keys(_results).length,
            results: _results,
            total: sum
        };

    } else {
        return null;
    }
}

export function changeColor(color, id) {
    let style;

    if (document.getElementById(id)) {
        style = document.getElementById(id);
    } else {
        style = document.createElement('style');
        style.type = 'text/css';
        style.id = id
    }


    if (!!style) {
        let contrast = getContrastYIQ(color);

        style.innerHTML =
            'h2 { color: ' + color + ' !important; }' +
            '.theme_color { color: ' + color + ' !important; }' +
            '.theme_color_hover:hover { color: ' + color + ' !important; }' +
            '.theme_background, .theme_highlight, .theme_highlight_before:before { color:' + contrast + ' !important; background-color: ' + color + ' !important; }' +
            '.theme_background_hover:hover, .theme_highlight_before_hover:hover:before { color:' + contrast + ' !important; background-color: ' + color + ' !important; }' +
            '.theme_background_hover:hover svg text { fill:#fff; }' +
            '.theme_bordercolor, .theme_bordercolorHover:hover, .theme_bordercolor_before:before { border-color: ' + color + ' !important; }' +
            '.theme_borderEdgecolor_before:before { border-color: ' + color + ' transparent transparent transparent !important; }' +
            '.theme_svg, .theme_svg path, .theme_svg_hover:hover, .theme_svg_hover:hover path, .theme_hover:hover svg, .theme_hover:hover svg path { fill: ' + color + ' !important; }' +
            '.startContainer .start h4 { color: ' + color + ' !important; }' +
            '.theme_shadow {box-shadow:0 0 10px ' + color + ' }'
        ;
    }

    if (!document.getElementById(id)) {
        document.getElementsByTagName('head')[0].appendChild(style);
    }
}

export function getContrastYIQ(hexcolor){
    if (!!hexcolor) {
        hexcolor = hexcolor.replace("#", "");
        var r = parseInt(hexcolor.substr(0,2),16);
        var g = parseInt(hexcolor.substr(2,2),16);
        var b = parseInt(hexcolor.substr(4,2),16);
        var yiq = ((r*299)+(g*587)+(b*114))/1000;
        return (yiq >= 159) ? '#000' : '#fff';
    } else {
        return '#fff';
    }
}


export function sumAllQuestionResults(sessions) {
    // console.log('sessions', sessions);
    if (!!sessions) {
        let allSession = Object.keys(sessions);
        let allResults = {};
        let allImportants = {};
        let allPositives = {};

        /* all session */
        if (!!allSession && allSession.length) {
            allSession.forEach(sessionKey => {

                // a session
                if (!!sessions[sessionKey]) {
                    let questions = Object.keys(sessions[sessionKey]);

                    // all questions answered
                    if (questions.length) {
                        // iterate all questions
                        questions.forEach(categoryKey => {
                            if (!allResults[categoryKey]) {
                                allResults[categoryKey] = {};
                            }

                            // all answers
                            if (sessions[sessionKey][categoryKey]) {

                                if (!!sessions[sessionKey][categoryKey]['answers']) {
                                    Object.keys(sessions[sessionKey][categoryKey]['answers']).forEach(questionKey => {
                                        if (!allResults[categoryKey][questionKey]) {
                                            allResults[categoryKey][questionKey] = {}
                                        }

                                        let answer = sessions[sessionKey][categoryKey]['answers'][questionKey];

                                        if (!allResults[categoryKey][questionKey][answer.toString()]) {
                                            allResults[categoryKey][questionKey][answer.toString()] = 0
                                        }

                                        if (!!sessions[sessionKey][categoryKey]['important'] && sessions[sessionKey][categoryKey]['important'].includes(parseInt(questionKey))) {
                                            // console.log(sessions[sessionKey][categoryKey], questionKey, sessionKey, categoryKey);
                                            allResults[categoryKey][questionKey][answer.toString()] += 2;
                                        } else if (!!sessions[sessionKey][categoryKey]['positive'] && sessions[sessionKey][categoryKey]['positive'].includes(parseInt(questionKey))) {
                                                allResults[categoryKey][questionKey][answer.toString()] += 2;
                                        } else {
                                            allResults[categoryKey][questionKey][answer.toString()] += 1;
                                        }

                                    });
                                }

                                if (!!sessions[sessionKey][categoryKey]['important']) {
                                    Object.keys(sessions[sessionKey][categoryKey]['important']).forEach(questionKey => {
                                        let key = sessions[sessionKey][categoryKey]['important'][questionKey];

                                        if (!allImportants[categoryKey]) {
                                            allImportants[categoryKey] = {}
                                        }

                                        if (!allImportants[categoryKey][key]) {
                                            allImportants[categoryKey][key] = 0;
                                        }

                                        if (!!sessions && !!sessions[sessionKey] && !!sessions[sessionKey][categoryKey] && !!sessions[sessionKey][categoryKey]['important'] && sessions[sessionKey][categoryKey]['important'].includes(parseInt(key))) {
                                            allImportants[categoryKey][key] += 1;
                                        }
                                    });
                                }

                                if (!!sessions[sessionKey][categoryKey]['positive']) {
                                    Object.keys(sessions[sessionKey][categoryKey]['positive']).forEach(questionKey => {
                                        let key = sessions[sessionKey][categoryKey]['positive'][questionKey];

                                        if (!allPositives[categoryKey]) {
                                            allPositives[categoryKey] = {}
                                        }

                                        if (!allPositives[categoryKey][key]) {
                                            allPositives[categoryKey][key] = 0;
                                        }

                                        if (!!sessions && !!sessions[sessionKey] && !!sessions[sessionKey][categoryKey] && !!sessions[sessionKey][categoryKey]['positive'] && sessions[sessionKey][categoryKey]['positive'].includes(parseInt(key))) {
                                            allPositives[categoryKey][key] += 1;
                                        }
                                    });
                                }
                            }
                        });

                    }
                }
            });
        }

        // console.log('allResults', allResults);

        return {
            allResults: allResults,
            allImportants: allImportants,
            allPositives: allPositives
        };

    }
}

export function getAllImportants(allImportants) {
    let len = 0;

    if (!!allImportants) {
        for (let k in allImportants) {
            len += Object.keys(allImportants[k]).length;
        }
    }

    return len;
}

export function getAllPositive(allPositive) {
    let len = 0;

    if (!!allPositive) {
        for (let k in allPositive) {
            len += Object.keys(allPositive[k]).length;
        }
    }
    return len;
}

export function setQuestionResults(questions, results) {
    if (!!questions && !!questions.questions && results.allResults) {
        questions.questions.forEach((category, key) => {
            if (!!category.questions && !!results.allResults[key]) {
                category.questions.forEach((question, qkey) => {
                    if (results.allResults[key][qkey]) {
                        const val = results.allResults[key][qkey];
                        const imp = (!!results.allImportants[key] && !!results.allImportants[key][qkey] ? results.allImportants[key][qkey] : 0);
                        const pos = (!!results.allPositives[key] && !!results.allPositives[key][qkey] ? results.allPositives[key][qkey] : 0);

                        // console.log(imp, pos);

                        questions.questions[key]['questions'][qkey]['important'] = (val[-3] || 0) + (val[-2] || 0) + (val[-1] || 0);
                        questions.questions[key]['questions'][qkey]['positive'] = (val[1] || 0)  + (val[2] || 0) + (val[3] || 0);

                        questions.questions[key]['questions'][qkey]['allImportants'] = (imp || 0);
                        questions.questions[key]['questions'][qkey]['allPositives'] = (pos || 0);
                    } else {
                        questions.questions[key]['questions'][qkey]['important'] = 0;
                        questions.questions[key]['questions'][qkey]['positive'] = 0;

                        questions.questions[key]['questions'][qkey]['allImportants'] = 0;
                        questions.questions[key]['questions'][qkey]['allPositives'] = 0;
                    }

                    questions.questions[key]['questions'][qkey]['category'] = key;
                    questions.questions[key]['questions'][qkey]['nr'] = qkey;
                    questions.questions[key]['questions'][qkey]['qnr'] = (key+1) + '.' + (qkey + 1);
                });
            } else {
                category.questions.forEach((question, qkey) => {
                    questions.questions[key]['questions'][qkey]['important'] = 0;
                    questions.questions[key]['questions'][qkey]['positive'] = 0;

                    questions.questions[key]['questions'][qkey]['allImportants'] = 0;
                    questions.questions[key]['questions'][qkey]['allPositives'] = 0;

                    questions.questions[key]['questions'][qkey]['category'] = key;
                    questions.questions[key]['questions'][qkey]['nr'] = qkey;
                    questions.questions[key]['questions'][qkey]['qnr'] = (key+1) + '.' + (qkey + 1);
                });
            }
        })
    }
}

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import '../../../../assets/scss/admin/add/healthyWorking/newQuestion.scss';


class HealthyWorkingNewQuestion extends Component {

    constructor(props) {
        super(props);

        this.state = {
            edit: false
        };
    }

    render() {
        return (
            <div className={'category__questions__new'}>
                <div className={'category__questions__new__add theme_color theme_bordercolor theme_background_hover'} onClick={() => this.props.addQuestion(this.props.datakey)}>Frage hinzufügen</div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        null
    )(
        HealthyWorkingNewQuestion
    )
);

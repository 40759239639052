import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {setData} from "../../redux/actions";

class Error extends Component {
    render() {
        return (
            <div>
                {console.log(this.props.state.error)}
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        {
            setData
        }
    )(
        Error
    )
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import CircularColor from 'react-circular-color';
import axios from "axios";
import {sha256} from "js-sha256";
import PasswordStrengthBar from "react-password-strength-bar";

import {setData} from "../../redux/actions";
import {changeColor, getTranslation, logout, token} from "../helper";

import '../../assets/scss/admin/profile.scss';
import {ReactComponent as Close} from '../../assets/img/close.svg';

import loading from "../../assets/img/loading.gif";


class Profile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tab: 'profile',
            loading: false,

            name: !!this.props.state.user.name ? this.props.state.user.name : '',
            mail: !!this.props.state.user.email ? this.props.state.user.email : '',
            logo: !!this.props.state.user.avatar ? this.props.state.user.avatar : '',
            color: !!this.props.state.user.themeColor ? this.props.state.user.themeColor : this.props.state.config.Farbe,
            website: !!this.props.state.user.website ? this.props.state.user.website : '',

            oldpassword: '',
            password: '',
            passwordRepeat: '',
            score: 0
        }

        this.change = this.change.bind(this);
        this.changeColor = this.changeColor.bind(this);
        this.changeImage = this.changeImage.bind(this);
        this.setPassword = this.setPassword.bind(this);
    }


    change(event) {

        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            console.log(this.state);
        });


    }

    changeColor(color) {
        changeColor(color, 'dynamicCustomCss');

        this.setState({
            color: color
        });
    }

    publish() {

        let state = {};

        if (!!this.state.name) {
            state['name'] = this.state.name;
        }
        if (!!this.state.color) {
            state['themeColor'] = this.state.color;
        }
        if (!!this.state.logo) {
            state['avatar'] = this.state.logo;
        }

        state['website'] = this.state.website;

        axios.patch(
            process.env['REACT_APP_API'] + '/private/user/v1',
            state,
            {
                'headers': {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': token()
                }
            }
        ).then((result) => {

            if (!!result.data) {
                localStorage.setItem('user', JSON.stringify(result.data));

                this.props.setData({
                    user: result.data
                });

                if (!!result.data['themeColor']) {
                    changeColor(result.data['themeColor'], 'dynamicCss');
                }

                this.props.methods.setPopup();
            } else {
                this.setState({
                    error: this.props.state.language[this.props.state.lang]['AdminLoginError'],
                    loading: false
                });
            }

        }).catch(() => {
            logout(this.props);
        });
    }

    changeImage(event) {
        let self = this;
        let reader = new FileReader();
        reader.onload = function(e) {
            self.setState({logo: e.target.result});
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    setPassword(e) {
        e.preventDefault();


        this.setState({
            loading: true
        });

        axios.patch(
            process.env['REACT_APP_API'] + '/private/user/v1/password',
            null,
            {
                'headers': {
                    'Content-Type': 'application/json',
                    'authorization': token(),
                    'newPassword': sha256(this.state.password),
                    'oldPassword': sha256(this.state.oldpassword)
                }
            }
        ).then(result => {
            console.log(result);

            this.setState({
                loading: false
            });
            logout(this.props);
        }).catch(error => {
            if (!!error.request.response) {
                this.setState({
                    error: error.request.response,
                    loading: false
                });
            } else {
                this.setState({
                    error: 'Network error',
                    loading: false
                });
            }
        });

    }

    chpwd(score) {
        this.setState({
            score: score
        })
    }

    render() {
        return (
            <div className={'overlay'}>
                <div className={'overlayBox editProfile'}>

                    <Close className={'close'} onClick={() => this.props.methods.setPopup()} />

                    <ul>
                        <li className={'theme_bordercolor_before theme_color_hover ' + (this.state.tab === 'profile' ? 'current theme_color' : '')} onClick={() => this.setState({tab: 'profile'})}>{getTranslation(this, this.props.state.lang, 'EditProfile')}</li>
                        <li className={'theme_bordercolor_before theme_color_hover ' + (this.state.tab === 'password' ? 'current theme_color' : '')} onClick={() => this.setState({tab: 'password'})}>{getTranslation(this, this.props.state.lang, 'ChangePassword')}</li>
                    </ul>
                    <div className={'horizontalShadow'} />

                    {this.state.tab === 'profile' &&
                        <>
                            <div className={'userData'}>
                                <div className={'box'}>
                                    <div className={'flex'}>
                                        <label htmlFor={'name'}>
                                            {getTranslation(this, this.props.state.lang, 'NameCompany')}
                                        </label>
                                        <input type={'text'} id={'name'} name={'name'} value={this.state.name} onChange={this.change} />
                                    </div>

                                    <div className={'flex'}>
                                        <label>
                                            {getTranslation(this, this.props.state.lang, 'Mail')}
                                        </label>
                                        <input type={'text'} name={'mail'} value={this.state.mail} readOnly={true} />
                                    </div>
                                </div>

                                {(!!this.props.state.user && !!this.props.state.user.restriction && this.props.state.user.restriction.brandingActivated) &&
                                    <>
                                        <div className={'box'}>
                                            <div className={'flex'}>
                                                <label htmlFor={'website'}>
                                                    {getTranslation(this, this.props.state.lang, 'website')}
                                                </label>
                                                <input type={'url'} id={'website'} name={'website'} value={this.state.website} onChange={this.change} placeholder={'https://'} pattern="https://.*" />
                                            </div>
                                        </div>

                                        <div className={'box'}>
                                            <div className={'flex'}>
                                                <label htmlFor={'avatar'}>
                                                    {getTranslation(this, this.props.state.lang, 'YourLogo')}
                                                </label>
                                                <label htmlFor={'avatar'} className={'logo theme_bordercolor'}>
                                                    {(!!this.state.logo)
                                                        ?
                                                        <img src={this.state.logo} alt={getTranslation(this, this.props.state.lang, 'Userpic')} />
                                                        :
                                                        <img src={'/assets/img/balance_aktiv.png'} alt={getTranslation(this, this.props.state.lang, 'Userpic')} />
                                                    }
                                                    <input type={'file'} id={'avatar'} name={'avatar'} accept={'.jpg, .jpeg, .png'} onChange={this.changeImage} />
                                                </label>
                                            </div>

                                            <div className={'flex'}>
                                                <label htmlFor={'mail'}>
                                                    {getTranslation(this, this.props.state.lang, 'Color')}
                                                </label>
                                                <div className={'color small'}>
                                                    <CircularColor size={160} onChange={this.changeColor} color={!!this.state.color ? this.state.color : '#f29200'} numberOfSectors={60} />
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                                <div className={'buttonBox'}>
                                    <button className={'button theme_color theme_bordercolor theme_background_hover'} onClick={() => this.publish()}>
                                        {getTranslation(this, this.props.state.lang, 'SaveProfile')}
                                    </button>
                                </div>
                            </div>
                        </>
                    }

                    {this.state.tab === 'password' &&
                        <>
                            <div className={'changePassword'}>

                                <div className={'box'}>
                                    <div className={'flex'}>
                                        <label htmlFor={'oldpassword'}>
                                            {getTranslation(this, this.props.state.lang, 'OldPassword')}
                                        </label>
                                        <input type={'password'} id={'oldpassword'} name={'oldpassword'} value={this.state.oldpassword} onChange={e => this.setState({oldpassword: e.target.value})} placeholder={getTranslation(this, this.props.state.lang, 'OldPassword')} />
                                    </div>
                                </div>

                                <div className={'box'}>
                                    <div className={'flex'}>
                                        <label htmlFor={'password'}>
                                            {getTranslation(this, this.props.state.lang, 'SavePassword')}
                                        </label>
                                        <input type={'password'} id={'password'} name={'password'} value={this.state.password} onChange={e => this.setState({password: e.target.value})} placeholder={getTranslation(this, this.props.state.lang, 'Password')} />
                                        <PasswordStrengthBar className={'bar'} scoreWordClassName={'knuff'} minLength={8} shortScoreWord={getTranslation(this, this.props.state.lang, 'PasswordToShort')} scoreWords={[getTranslation(this, this.props.state.lang, 'toWeak'), getTranslation(this, this.props.state.lang, 'weak'), getTranslation(this, this.props.state.lang, 'okay'), getTranslation(this, this.props.state.lang, 'well'), getTranslation(this, this.props.state.lang, 'veryStrong')]} password={this.state.password} onChangeScore={(data) => this.chpwd(data)} />
                                    </div>

                                    <div className={'flex'}>
                                        <label htmlFor={'passwordRepeat'}>
                                            {getTranslation(this, this.props.state.lang, 'PasswordRepeat')}
                                        </label>
                                        <input type={'password'} id={'passwordRepeat'} name={'passwordRepeat'} value={this.state.passwordRepeat} onChange={e => this.setState({passwordRepeat: e.target.value})} placeholder={getTranslation(this, this.props.state.lang, 'RepeatPassword')} />
                                        <div className={'passwordRepeat' + ((this.state.password !== this.state.passwordRepeat && this.state.password !== '' && this.state.passwordRepeat !== '') ? ' notSame' : (this.state.password === '' || this.state.passwordRepeat === '' ? ' empty' : ' same'))}>
                                            {(this.state.password !== this.state.passwordRepeat && this.state.password !=='' && this.state.passwordRepeat !== '') ? getTranslation(this, this.props.state.lang, 'ReapeatPasswordIncorrect') : (this.state.password === '' || this.state.passwordRepeat === '' ? getTranslation(this, this.props.state.lang, 'PleaseRepeatPassword') : getTranslation(this, this.props.state.lang, 'RepeatPasswordCorrect'))}
                                        </div>
                                    </div>
                                </div>

                                <div className={'hint'}>
                                    {getTranslation(this, this.props.state.lang, 'PasswordChangeHint')}
                                </div>

                                <div className={'buttonBox'}>
                                    <button disabled={(!this.state.oldpassword || !this.state.password || this.state.password !== this.state.passwordRepeat || this.state.score === 0)} className={'button ' + ((!!this.state.password && this.state.password === this.state.passwordRepeat && this.state.score > 0 && !!this.state.oldpassword) ? 'theme_color theme_bordercolor theme_background_hover' : '')} onClick={this.setPassword}>
                                        {this.state.loading ?
                                            <img src={loading} alt={getTranslation(this, this.props.state.lang, 'loading')} />:
                                            getTranslation(this, this.props.state.lang, 'ChangePassword')
                                        }
                                    </button>
                                </div>

                            </div>
                        </>
                    }
                </div>

            </div>
        );
    }

    componentWillUnmount() {
        let style = document.getElementById('dynamicCustomCss');

        if (!!style) {
            style.remove();
        }
    }

}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
            }
        },
        {
            setData
        }
    )(
        Profile
    )
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {
    getTranslation,
} from "../../helper";
import {ReactComponent as Close} from "../../../assets/img/close.svg";


class SetOptions extends Component {

    constructor(props) {
        super(props);

        this.state = this.props.data;

        this.setRole = this.setRole.bind(this);
    }

    setRole() {
        let roles = this.props.state.user.roles;

        if (roles.includes('Coach')) {
            let index = roles.indexOf('Coach');
            roles.splice(index, 1);
        } else {
            roles.push('Coach');
        }

        this.props.change({
            roles: roles
        });
    }

    render() {
        return (
            <div className={'addUser overlayBox'}>

                <Close className={'close'} onClick={() => this.props.methods.setThisState(false)} />

                <form onSubmit={(e) => this.props.addUser(e)}>

                    <h3>{getTranslation(this, this.props.state.lang, 'Settings')}</h3>

                    <div className={'box'}>
                        <input type={'text'} placeholder={getTranslation(this, this.props.state.lang, 'NameCompany')} value={this.state.name} onChange={e => this.props.change({name: e.target.value})} />
                        <input type={'mail'} placeholder={getTranslation(this, this.props.state.lang, 'Mail')} value={this.state.mail} onChange={e => this.props.change({mail: e.target.value})} readOnly={true} />
                    </div>

                    <div className={'branding'}>
                        <label htmlFor={'coach_branding'}>
                            <input type={'checkbox'} id={'coach_branding'} value={this.state.roles.includes('Coach')} onChange={e => this.setRole()} />
                            {getTranslation(this, this.props.state.lang, 'IAmACoach')}
                        </label>
                        <p>{getTranslation(this, this.props.state.lang, 'Contingent')}</p>
                    </div>

                    <div className={'buttonBox'}>
                        <button type={'button'} className={'button theme_color theme_bordercolor theme_background_hover'} onClick={() => this.props.methods.setThisState(false)}>
                            {getTranslation(this, this.props.state.lang, 'Cancel')}
                        </button>

                        <button className={'button theme_color theme_bordercolor theme_background_hover'}>
                            {getTranslation(this, this.props.state.lang, 'Save')}
                        </button>
                    </div>

                </form>
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.setState(this.props.data);
        }
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
            }
        },
        null
    )(
        SetOptions
    )
);

import React, {Component} from 'react';
import {pdf} from "@react-pdf/renderer";
import {PdfDocument} from "./pdf";
// import {saveAs} from 'file-saver';
import {connect} from "react-redux";

const generatePdfDocument = async (fileName, documentData, done) => {
    pdf(<PdfDocument data={documentData} />).toBlob().then((blob) => {
        window.open(URL.createObjectURL(new Blob([blob], {type: 'application/pdf'})), '_blank');
        // saveAs(blob, fileName);
        done();
    });
};


class PdfDownload extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };

        this.done = this.done.bind(this);
    }

    done() {
        this.setState({
            loading: false
        })
    }

    render() {

        return (
                <div className={'download'}>
                    {
                        (this.state.loading === true
                                ?
                                    <div className={'loading btn prev theme_background_hover'}>
                                        <div className="spinner">
                                            <div className="bounce1" />
                                            <div className="bounce2" />
                                            <div className="bounce3" />
                                        </div>
                                    </div>
                                :
                                    <button title={'Auswertung Downloaden'} className={'generate btn prev theme_background_hover'} onClick={() => this.setState({loading: true})}>
                                        Auswertung Downloaden
                                    </button>
                        )
                    }
                </div>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.a !== this.state.loading && this.state.loading === true) {
            generatePdfDocument('Auswertung_GesundesArbeiten.pdf', this.props, this.done);
        }
    }

}

export default connect(
    (state) => {
        return {
            state: state,
            language: state.language,
            sessions: state.sessions,
            questionData: state.questionData,
            invitationData: state.invitationData
        }
    },
    null
)(
    PdfDownload
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import axios from "axios";
import {getTranslation, logout, token} from "../helper";
import Profile from './profile.class';


import {setData} from "../../redux/actions";

import '../../assets/scss/admin/popup.scss';
import {ReactComponent as Close} from '../../assets/img/close.svg';


class Popup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            tab: '',
            name: '',
            mail: '',
            roles: [],
            branding: false
        };
    }

    deleteUser(e) {
        e.preventDefault();

        axios.delete(
            process.env['REACT_APP_API'] + '/private/user/v1',
            {
                "@type": "User",
                "email": this.state.mail,
                "brandingActivated": this.state.branding,
                "locale": {
                    "language": "de",
                    "name": "German",
                    "tag": "de"
                },
                "name": this.state.name,
                "roles": this.state.roles
            }, {
                'headers': {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': token()
                }
            }
        ).then(() => {
            this.getUsers();
        }).catch(() => {
            logout(this.props);
        });
    }

    render() {
        return (
            <div className={'overlay'}>
                <div className={'popup overlayBox'}>

                    <Close className={'close'} onClick={() => this.props.methods.setPopup()} />

                    {this.props.type === 'delete' &&
                        <div className={''}>
                            <h3>{getTranslation(this, this.props.state.lang, 'DeleteUser')}</h3>
                            <p>{getTranslation(this, this.props.state.lang, 'RealyDeleteUser')}</p>

                            <div className={'buttonBox'}>
                                <button className={'button theme_color theme_bordercolor theme_background_hover'}>
                                    {getTranslation(this, this.props.state.lang, 'Yes')}
                                </button>

                                <button type={'button'} className={'button theme_color theme_bordercolor theme_background_hover'} onClick={() => this.props.methods.setPopup()}>
                                    {getTranslation(this, this.props.state.lang, 'No')}
                                </button>
                            </div>

                        </div>
                    }
                    {this.props.type === 'invite' &&
                        <div className={''}>
                            <h3>{getTranslation(this, this.props.state.lang, 'Resend')}</h3>
                            <p>{getTranslation(this, this.props.state.lang, 'RealyResend')}</p>

                            {!!this.props.state.error &&
                                <div className={'error'}>
                                    {getTranslation(this, this.props.state.lang, 'ErrorAction')}
                                </div>
                            }

                            <div className={'buttonBox'}>
                                <button className={'button theme_color theme_bordercolor theme_background_hover'} onClick={() => this.props.methods.resendInvitation(this.props.code)}>
                                    {getTranslation(this, this.props.state.lang, 'Yes')}
                                </button>

                                <button type={'button'} className={'button theme_color theme_bordercolor theme_background_hover'} onClick={() => this.props.methods.setPopup()}>
                                    {getTranslation(this, this.props.state.lang, 'No')}
                                </button>
                            </div>

                        </div>
                    }
                    {this.props.type === 'profile' &&
                        <Profile />
                    }
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
            }
        },
        {
            setData
        }
    )(
        Popup
    )
);

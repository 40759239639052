import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../../assets/scss/balanceboard/form.scss';
import Cell from "./cellDiv.class";
import TopBox from "./topBox.class";
import {connect} from "react-redux";
import marked from "../markdown";
import {copySessions, getCellData, getQuestionsWithResults, getTranslationHeadline} from "../helper";
import {setData} from "../../redux/actions";
import Slider from "./slider.class";


const CellFunc = (props) => {

    let cells = [];
    for(let i = 0; i <= 100; i=i+5) {
        cells.push(<Cell {...props} elm={i} key={'cellDiv_' + props.section + '_' + props.question + '_' + i} type={'percentage'} />);
    }

    return cells;
};

class TimeMeaning extends Component {

    change(e, section, question) {
        let oldData = copySessions(this.props);
        if (!oldData['results']) {
            oldData['results'] = {};
        }
        if (!oldData['results'][section]) {
            oldData['results'][section] = {};
        }
        oldData['results'][section][question] = e.target.value;

        this.props.publish({sessions: {
            'SINGLE': {
                "@type":"Session",
                'sessionData': JSON.stringify(oldData)
            }
        }});

    }

    render() {

        if (!!this.props.info) {
            let data = !!this.props.data['questions'] ? this.props.data['questions'][this.props.state.lang] : null;

            if (!!data) {
                let total = 0;

                let headline = !!this.props.add ? this.props.add[this.props.state.lang]['Ueberschrift'] : '';
                let text = !!this.props.add ? this.props.add[this.props.state.lang]['Text'] : '';

                return (
                    <div className={'formData'}>

                        <TopBox section={headline} setLanguage={this.props.setLanguage} />

                        <div className={'subheadline'} dangerouslySetInnerHTML={{__html: (!!text ? marked(text) : '')}} />

                        <div className={'questions'}>
                            {!!data && Object.keys(data).map((e, i) => {
                                let g = data[e];
                                let results = getQuestionsWithResults(this.props, this.props.section, e);


                                let rest = 100 - (!!results ? results.total : 0)
                                let section_total = !!results ? results.total : 0;
                                total += Number(section_total);

                                return (
                                    <div key={'q_' + i} className={'question'}>
                                        <div className={'top'}>
                                            {getTranslationHeadline(this.props.state.headlines, this.props.state.lang, g.section.replace(' ', '-'))} <span>({section_total} %)</span>
                                        </div>

                                        <div className={'slider slider100'}>
                                            <CellFunc publish={this.props.publish} section={this.props.section} question={e} data={g} rest={rest} area={'tm'} />
                                        </div>

                                        <Slider min={0} max={100} step={5} default={getCellData(this.props, this.props.section.replace(' ', '-'), e)} publish={this.props.publish} section={this.props.section.replace(' ', '-')} question={e} labelSkip={5} />
                                    </div>
                                );
                            })}

                            <div className={'total'}>
                                <div className={'val'}>
                                    {this.props.state.language[this.props.state.lang]['Points'].replace('*total*', total)}
                                </div>
                            </div>

                        </div>

                    </div>
                );
            } else {
                return <></>;
            }
        } else {
            return <></>;
        }

    }

    componentDidMount() {
        if (this.props.state.results.timeMeaning === false) {
            let data = Object.assign({}, this.props.state.results);
            data.timeMeaning = true;

            this.props.setData({
                results: data
            });
        }

        if (!!this.props['infoBubble'] && this.props['infoBubble']['status'] === true) {
            this.props.toggleBlur({
                section: this.jsUcfirst(this.props.section.replace(' ', '-')),
                data: this.props['infoBubble']
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps['infoBubble']['status'] !== this.props['infoBubble']['status']) {
            this.props.toggleBlur({
                section: this.jsUcfirst(this.props.section.replace(' ', '-')),
                data: this.props['infoBubble']
            });
        }
    }

    jsUcfirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                sessions: state.sessions,
                userID: state.userID
            }
        },
        {
            setData,
            getQuestionsWithResults
        }
    )(
        TimeMeaning
    )
);

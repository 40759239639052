import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';

import {v4 as uuid4} from 'uuid';
import moment from "moment";
import marked from "./markdown";

import {cookie} from "cookie.js";

import {setData} from "../redux/actions";
import '../assets/scss/login.scss';


import axios from "axios";
import loading from "../assets/img/loading.gif";
import {getTranslation, token} from "./helper";



class Login extends Component {
    constructor(props) {
        super(props);

        let params = queryString.parse(this.props.location.search);

        let error;
        if (!!params['ended']) {
            error = getTranslation(this, this.props.state.lang, 'CoachEndedSession');
        }

        this.state = {
            loading: false,

            message: {
                status: null,
                text: null
            },

            code: !!params.code ? params.code : '',
            error: error
        }

        this.change = this.change.bind(this);
        this.login = this.login.bind(this);
    }


    change(section, e) {
        let target = e.target;

        this.setState({
            [target.name]: target.value
        });
    }


    async loginData(result, usertoken) {
        if ((!result.hidden || result.hidden === false) && result.invitationState !== 'Closed' && (result.plannedDate === '' || moment().isBetween(moment(result.plannedDate).subtract(25, 'hour'), moment(result.endDate)))) {
            localStorage.setItem('code', JSON.stringify(result.code));
            localStorage.setItem('user', JSON.stringify(result));

            this.props.setData({
                invitation: result
            });

            this.setState({
                loading: false
            });

            axios.patch(process.env['REACT_APP_API'] + '/public/invitation/v1/' + this.state.code + "/" + usertoken, {
                invitationState: 'Waiting'
            }, {
                'headers': {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': token()
                }
            }).then(() => {
                let link = '/';
                if (this.props.state['invitation']['board'] !== 'false') {
                    let sessions = (!!this.props.state['invitation'] && !!this.props.state['invitation']['sessions'] && !!this.props.state['invitation']['sessions']['SINGLE'] && !!this.props.state['invitation']['sessions']['SINGLE']['sessionData']) ? JSON.parse(this.props.state['invitation']['sessions']['SINGLE']['sessionData']) : null;

                    if (!!sessions && sessions.withVideo === 'false') {
                        link = '/lobby/' + this.props.state['invitation']['board'] + '/start';
                    } else if (this.props.state['invitation']['board'] === 'healthyworking') {
                        link = '/lobby/' + this.props.state['invitation']['board'] + '/start';

                        this.props.setData({
                            userID: usertoken
                        });

                    }
                }

                this.props.history.push(link);
            }).catch(error => {
                console.error(error.response.data);
                this.props.setData({
                    error: error.response.data
                });

                this.props.history.push('/');
            });
        } else {
            this.setState({
                loading: false,
                error: getTranslation(this, this.props.state.lang, 'SessionExpired')
            });
        }
    }

    async login(e) {
        if (!!e) {
            e.preventDefault();
        }

        this.setState({
            error: '',
            loading: true
        });

        let usertoken;

        if (!!cookie.get('userID') && cookie.get('userID') !== 'null') {
            usertoken = cookie.get('userID')
        } else {
            usertoken = uuid4();

            cookie.set('userID', usertoken, {
                path:'/',
            });
        }


        axios.get(process.env['REACT_APP_API'] + '/public/invitation/v1/' + this.state.code + "/" + usertoken, {
            'headers': {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest'
            }
        }).then((result) => {

            if (result.data.board !== 'healthyworking') {
                const userId = uuid4();

                cookie.set('userID', userId, {path:'/'});

                axios.get(process.env['REACT_APP_API'] + '/public/invitation/v1/' + this.state.code + "/" + userId, {
                    'headers': {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                }).then((result) => {
                    this.loginData(result.data, userId);
                })
            } else {
                this.loginData(result.data, usertoken);
            }
        }).catch((result) => {

            let message;

            if (!!result.response) {
                message = result.response.data.message;
                if (result.response.data.indication) {
                    message = getTranslation(this, this.props.state.lang, 'SessionNotFound')
                }

            }

            this.setState({
                error: message,
                loading: false
            });

        });
    }

    render() {
        if (Object.keys(this.props.state['language']).length) {
            return (
                <div className={'login'}>
                    <form onSubmit={e => this.login(e)}>

                        <div className={'balanceAktiveLogo'} style={{backgroundImage: 'url(/assets/img/balance_aktiv.png)'}} />

                        <p>{this.props.state['language'][this.props.state.lang]['Welcome']}</p>

                        <div className={'boxCenter'}>
                            <input type={'text'} name={'code'} placeholder={this.props.state['language'][this.props.state.lang]['Code']} value={this.state.code} onChange={e => this.change('login', e)} />
                        </div>

                        {!!this.state.error &&
                            <div className={'error'} dangerouslySetInnerHTML={{__html: marked(this.state.error)}} />
                        }

                        <div className={'bottomBox'}>
                            <div className={'space'} />

                            <button type={'submit'} className={'button theme_color theme_bordercolor theme_background_hover'}>
                                {this.state.loading ?
                                    <img src={loading} alt={'Loading...'} />:
                                    this.props.state['language'][this.props.state.lang]['SignIn']
                                }
                            </button>

                            <div className={'space'} />
                        </div>

                    </form>
                </div>
            );
        } else {
            return <></>;
        }
    }


    componentDidMount() {

        let params = queryString.parse(this.props.location.search)

        if (!!params.login && !!params.user && !!params.password) {
            setTimeout(() => {
                this.submitLogin();
            }, 200);
        }
    }
}


export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
            }
        },
        {
            setData
        }
    )(
        Login
    )
);

import React from 'react';
import {Page, Text, View, Document, Font, Image, Link} from '@react-pdf/renderer';

import regular from '../../assets/font/Merriweather-Regular.ttf';
import bold from '../../assets/font/Merriweather-Bold.ttf';
import italic from '../../assets/font/Merriweather-Italic.ttf';

import shadow from '../../assets/img/shadow.png';
import moment from "moment";


Font.register({
    family: 'Open Sans',
    fonts: [
        {src: regular},
        {src: bold, fontWeight: 700},
        {src: italic, fontStyle: 'italic'}
    ]
});


function RenderCode(props) {
    let text = 'Datum: ' + moment(props.data.invitationData['plannedDate']).format('DD.MM.YYYY HH:mm') + ' - ' + moment(props.data.invitationData['endDate']).format('DD.MM.YYYY HH:mm') + ' | COACH: ' + props.data.invitationData['coach']['name'];

    return (
        <View>
            <View style={{display:'flex', justifyContent:'center', flexDirection:'row', marginBottom:10}}>
                <Text style={{textTransform:'uppercase', display:'flex', fontSize:14, borderBottom:'1 solid ' + props.data.state.config['Farbe'], padding:'0 20'}}>
                    {props.data.invitationData['coacheeName']}
                </Text>
            </View>

            <View style={{display:'flex', justifyContent:'center', flexDirection:'row', marginBottom:20, textAlign:'center'}}>
                <Text style={{display:'flex', fontSize:10, whiteSpace:'pre-line', fontStyle:'italic', textAlign:'center'}}>
                    {text}
                </Text>
            </View>

            <View style={{display:'flex', flexDirection:'row', flexWrap:'wrap', justifyContent:'center', position:'relative', textAlign:'center', marginTop:150}}>
                <Image src={props.qrCodeBlob} width={200} height={200} style={{width:'200px', height:'200px'}} />
            </View>
        </View>
    )
}

function RenderFooter(props) {

    let questionData
    if (!!props.data.invitationData && !!props.data.invitationData.questionData) {
        questionData = JSON.parse(props.data.invitationData.questionData);
    }

    let logo;
    if (!!questionData && !!questionData['logo']) {
        logo = questionData['logo'];
    } else if (!!props.data.state.invitationData && !!props.data.state.invitationData['coach'] && !!props.data.state.invitationData['coach']['avatar']) {
        logo = props.data.state.invitationData['coach']['avatar'];
    } else {
        logo = props.data.state.config['Logo'];
    }

    let link = props.data.state.config['Link'];
    if (!!questionData && !!questionData['url']) {
        link = questionData['url'];
    } else if (!!props.data.invitationData && !!props.data.invitationData['coach'] && !!props.data.invitationData['coach']['website']) {
        link = props.data.invitationData['coach']['website'];
    } else if (!!props.data.invitationData['coach']['avatar']) {
        link = '';
    }

    return (
        <View style={{position:'absolute', right:20, bottom:10, left:20, display:'flex', flexDirection:'column'}}>

            <View style={{position:'absolute', right:5, bottom:5, left:5}}>
                <Image src={shadow} style={{width:'100%'}} />

                <View style={{display:'flex', flexDirection:'row', justifyContent:'space-between', margin:'5 15 0'}}>
                    <View style={{flex:'1', display:'flex', flexDirection:'column', justifyContent:'center'}}>
                        <Link src={'https://balanceaktiv.de'} style={{margin:'auto auto auto 0', display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
                            <Image src={'assets/img/balance_aktiv.png'} style={{width:'80px'}} />
                        </Link>
                    </View>

                    <View style={{flex:'1'}} />

                    <View style={{flex:'1', display:'flex', flexDirection:'column', justifyContent:'center'}}>
                        {!!link
                            ?
                                <Link href={link} style={{margin:'auto 0 auto auto', display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
                                    <Image src={logo} style={{maxHeight:30}} />
                                </Link>
                            :
                                <View style={{margin:'auto 0 auto auto', display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
                                    <Image src={logo} style={{maxHeight:30}} />
                                </View>
                        }
                    </View>
                </View>
            </View>

        </View>
    )
}

function RenderPages(props) {

    let arr = [];

    arr.push(
        <Page wrap={false} size="A4" style={{fontFamily:'Open Sans', display:'flex', flexDirection:'column', justifyContent:'flex-start', backgroundColor:'#fff', padding:'4vw 10vw', fontSize:8, boxSizing:'border-box'}} key={'page_game'}>
            <RenderCode {...props} key={'code'} />
            <RenderFooter {...props} page={1} />
        </Page>
    );

    return arr;
}

export function PdfDocument(props) {
    return (
        <Document>
            <RenderPages {...props} />
        </Document>
    )
}

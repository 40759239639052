import React, {Component} from 'react';
import {Router, withRouter, Route, Redirect} from 'react-router-dom';
import { createBrowserHistory } from "history";
import {connect} from "react-redux";
import axios from 'axios';

import {setData} from "./redux/actions";
import {changeColor, getPermissions, /*getTranslation,*/ loggedin} from "./components/helper";

import './assets/scss/normalize.scss';
import './assets/scss/iconfont.scss';
import './assets/scss/app.scss';

import Login from './components/login.class';

import Lobby from './components/lobby/lobby.class';

import Imprint from './components/imprint.class';

import AdminLogin from './components/admin/adminlogin.class';
import AdminFooter from './components/admin/footer.class';

import AdminDashboard from './components/admin/dashboard.class';
import ActivateAccount from './components/activateAccount.class';


const history = createBrowserHistory();


const ProtectedRoute = ({ component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        loggedin()
            ? <Component {...props} />
            : <Redirect to={rest.redirect} />
    )
    } />
);

class AppRouter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            view: 'form',
            route: this.props.history.location.pathname,
            infoBubble: false
        };

        this.timer = null;

        this.change = this.change.bind(this);
    }

    async change(section, question, value) {
        let timestamp = new Date().getTime();

        await axios.post(process.env['REACT_APP_API'] + '?controller=data&action=add', {
            section: section,
            question: question,
            answer: value,
            timestamp: timestamp
        }, {
            'headers': {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
                Authorization: loggedin()
            }
        }).then(() => {
            let changes;

            if (question === 7 || question === 8) {
                let area = (question === 7) ? 'Time' : 'Meaning';

                changes = Object.assign({}, this.props.state[area], {
                    'questions': Object.assign({}, this.props.state[area]['questions'], {
                        'de': Object.assign({}, this.props.state[area]['questions']['de'], {
                            [section]: Object.assign(
                                {},
                                this.props.state[area]['questions']['de'][section],
                                Object.assign({},
                                    this.props.state[area]['questions']['de'][section],
                                    {
                                        'value': parseInt(value)
                                    }
                                )
                            )
                        }),
                        'en': Object.assign({}, this.props.state[area]['questions']['en'], {
                            [section]: Object.assign(
                                {},
                                this.props.state[area]['questions']['en'][section],
                                Object.assign({},
                                    this.props.state[area]['questions']['en'][section],
                                    {
                                        'value': parseInt(value)
                                    }
                                )
                            )
                        })
                    })
                });
            } else {
                changes = {
                    'de': Object.assign({}, this.props.state.questions['de'], {
                        [section] : Object.assign(
                            {},
                            this.props.state.questions['de'][section],
                            {
                                [question]: Object.assign({},
                                    this.props.state.questions['de'][section][question],
                                    {
                                        value: parseInt(value)
                                    }
                                )
                            }
                        )
                    }),
                    'en': Object.assign({}, this.props.state.questions['en'], {
                        [section] : Object.assign(
                            {},
                            this.props.state.questions['en'][section],
                            {
                                [question]: Object.assign({},
                                    this.props.state.questions['en'][section][question],
                                    {
                                        value: parseInt(value)
                                    }
                                )
                            }
                        )
                    })
                };
            }

            if (question === 7) {
                this.props.setData({
                    Time: changes,
                    timestamp: timestamp
                });
            } else if (question === 8) {
                this.props.setData({
                    Meaning: changes,
                    timestamp: timestamp
                });
            } else {
                this.props.setData({
                    questions: changes,
                    timestamp: timestamp
                });
            }
        });

    }

    render() {

        let adminRoute;

        if (loggedin()) {
            if (getPermissions('Coach', this.props)) {
                adminRoute = <Redirect to={'/admin/dashboard/invitations'}/>;
            } else if (getPermissions('Admin', this.props)) {
                adminRoute = <Redirect to={'/admin/dashboard/coaches'} />;
            } else {
                adminRoute = <Redirect to={'/admin/dashboard'} />;
            }
        } else {
            adminRoute = <Redirect to={'/admin/login'} />;
        }

        return (
            <Router history={history}>

                <section role={'main'} id={'main'}>
                    <Route exact path={'/'}>
                        {loggedin() ?
                            <Redirect to={'/lobby'} />
                            :
                            <Redirect to={'/login'} />
                        }
                    </Route>

                    <ProtectedRoute path={'/lobby'} component={Lobby} redirect={'/'} />

                    <Route exact path={'/admin'}>
                        {adminRoute}
                    </Route>

                    <ProtectedRoute path={'/admin/dashboard'} component={AdminDashboard} redirect={'/admin/login'} />


                    <Route path={'/login'} component={Login} />
                    <Route path={'/admin/login'} component={AdminLogin} />

                    <Route path={'/imprint'} component={Imprint} />

                    <Route path={'/activate'} component={ActivateAccount} />


                    {/*<div className={'notSupported'}>*/}
                    {/*    <div className={'balanceAktiveLogo'} style={{backgroundImage: 'url(/assets/img/balance_aktiv.png)'}} />*/}
                    {/*    <div className={'text'}>{getTranslation(this, this.props.state.lang, 'Resolution')}</div>*/}
                    {/*</div>*/}
                </section>


                <Route path={'/admin'} component={AdminFooter} />

            </Router>
        );
    }

    getQuestions(structureData, section, _language, data) {
        let _section = section.replace(' ', '-');

        let key = 0;
        let _key = 0;
        let lang = 'de';
        for(let elm in data) {
            if (data.hasOwnProperty(elm)) {
                let value = data[elm];

                if (!!value['Typ'] && (value['Typ'] === 'Frage' || value['Typ'] === 'GA')) {
                    if (!!value['Language']) {
                        if (value['Language'] !== lang) {
                            key = 0;
                        }
                        lang = value['Language'];
                    }

                    if (!structureData[lang]) {
                        structureData[lang] = {};
                    }
                    if (!structureData[lang][_section] && value['Typ'] !== 'GA') {
                        structureData[lang][_section] = {};
                    }

                    if (value['Typ'] === 'Zeit' || value['Typ'] === 'Bedeutung') {
                        structureData[lang][_section] = {
                            section: section,
                            coin: value['Jeton'],
                            value: 0
                        };
                    } else if (value['Typ'] === 'GA') {
                        let category = value['Kategorie'].replace(' ', '-');

                        if (!structureData[lang][category]) {
                            _key = 0;
                            structureData[lang][category] = {
                                category: value['Kategorie'],
                                questions: {},
                                enabled: true
                            }
                        }

                        structureData[lang][category]['questions'][_key] = {
                            category: value['Kategorie'],
                            question: value['Text'],
                            enabled: true
                        };

                        _key++;
                    } else {
                        structureData[lang][_section][key] = {
                            section: section,
                            question: value['Text'],
                            coin: value['Jeton'],
                            value: 0
                        }

                        key++;
                    }
                }
            }
        }
    }

    getTimeMeaningQuestions(structureData, section, _language, data, type) {
        let _section = section.replace(' ', '-');

        for(let elm in data) {
            if (data.hasOwnProperty(elm)) {
                let value = data[elm];
                let lang = 'de';
                if (!!value['Language']) {
                    lang = value['Language'];
                }

                if (!!value['Typ'] && value['Typ'] === type) {
                    if (!structureData[lang]) {
                        structureData[lang] = {};
                    }
                    if (!structureData[lang][_section]) {
                        structureData[lang][_section] = {};
                    }

                    structureData[lang][_section] = {
                        section: section,
                        coin: value['Jeton'],
                        value: 0
                    };
                }
            }
        }
    }

    getInfos(structureData, section, data) {
        section = section.replace(' ', '-');

        for(let elm in data) {
            if (data.hasOwnProperty(elm)) {
                let value = data[elm];

                let lang = 'de';
                if (!!value['Language']) {
                    lang = value['Language'];
                }

                if (!structureData[lang]) {
                    structureData[lang] = {};
                }

                if (!structureData[lang][section]) {
                    structureData[lang][section] = {
                        headline: '',
                        text: '',
                        status: false
                    };
                }


                if (value['Typ'] === 'InfoBubbleUeberschrift' || value['Typ'] === 'InfoBubbleText') {
                    let type = (value['Typ'] === 'InfoBubbleUeberschrift') ? 'headline' : 'text';

                    structureData[lang][section][type] = value['Text'];
                    structureData[lang][section]['status'] = true;
                }

            }
        }
    }


    parseLanguage(structureData, field, data) {
        if (!structureData[field]) {
            structureData[field] = {};
        }

        for(let _language in data) {
            if (data.hasOwnProperty(_language)) {
                let value = data[_language];

                let lang = 'de';
                if (!!value['Language']) {
                    lang = value['Language'];
                }

                if (!structureData[field][lang]) {
                    structureData[field][lang] = {};
                }

                if (!!value['Typ'] && value['Text']) {
                    structureData[field][lang][value['Typ']] = value['Text'];
                }
            }
        }
    }

    parseStructuredLanguage(structureData, field, data) {
        structureData[field] = {};

        for(let elm in data) {
            if (data.hasOwnProperty(elm)) {

                let value = data[elm];

                Object.keys(value).map(e => {
                    let lang = 'de';
                    if (value['Language']) {
                        lang = value['Language'];
                    }

                    if (!structureData[field][lang]) {
                        structureData[field][lang] = {};
                    }

                    return structureData[field][lang][e] = value[e];
                });
            }
        }
    }

    parseHeadlinesLanguage(structureData, section, type, data) {
        let _section = section.replace(' ', '-');

        for(let elm in data) {
            if (data.hasOwnProperty(elm)) {
                let value = data[elm];

                let lang = null;
                if (!!value['Language']) {
                    lang = value['Language'];
                }

                if (!!value['Typ'] && value['Typ'] === type) {

                    if (!!lang) {
                        if (!structureData[lang] && !!value['Text'] && !!value['Typ']) {
                            structureData[lang] = {};
                        }

                        if (!structureData[lang][_section]) {
                            structureData[lang][_section] = {};
                        }

                        structureData[lang][_section] = value['Text'];
                    } else {
                        if (!structureData[_section] && !!value['Text']) {
                            structureData[_section] = {};
                        }

                        structureData[_section] = value['Text'];
                    }

                }
            }
        }

    }

    async getXMLData() {
        if (['Sinn', 'Arbeit', 'Gesundheit', 'Soziale-Beziehungen'].indexOf(this.props.location.pathname.replace('/lobby/balanceboard/','')) > -1) {
            this.props.setData({
                zoomed: true
            });
        } else {
            this.props.setData({
                zoomed: false
            });
        }

        this.props.setData({
            loading: true
        });

        axios.get(process.env['REACT_APP_API'] + '/public/cms/v1').then(result => {
            let data = result.data;

            let structureData = {};

            if (!!data['Struktur']) {
                data['Struktur'].map(s => {

                    if (!structureData['headlines']) {
                        structureData['headlines'] = {};
                    }

                    if (!structureData['Time']) {
                        structureData['Time'] = {};
                    }
                    if (!structureData['Time']['questions']) {
                        structureData['Time']['questions'] = {};
                    }

                    if (!structureData['Meaning']) {
                        structureData['Meaning'] = {};
                    }
                    if (!structureData['Meaning']['questions']) {
                        structureData['Meaning']['questions'] = {};
                    }

                    if (s['View'] === 'Fragen') {
                        if (!structureData['questions']) {
                            structureData['questions'] = {};
                        }
                        if (!structureData['bgm']) {
                            structureData['bgm'] = {};
                        }
                        if (!structureData['infoBubble']) {
                            structureData['infoBubble'] = {};
                        }

                        if (!structureData['backgrounds']) {
                            structureData['backgrounds'] = {};
                        }

                        if (!structureData['subheadline']) {
                            structureData['subheadline'] = {};
                        }

                        if (!structureData['structure']) {
                            structureData['structure'] = new Map();
                        }

                        if (s['ID'] !== 'GA') {
                            this.getQuestions(structureData['questions'], s['Lasche'], this.props.state.lang, data[s['Lasche']]);
                        } else {
                            this.getQuestions(structureData['bgm'], s['Lasche'], this.props.state.lang, data[s['Lasche']]);
                        }

                        this.getInfos(structureData['infoBubble'], s['Lasche'], data[s['Lasche']]);
                        if (s['ID'] !== 'GA') {
                            structureData['structure'].set(s['Lasche'].replace(' ', '-'));
                        }

                        this.parseHeadlinesLanguage(structureData['subheadline'], s['Lasche'], 'Text', data[s['Lasche']]);

                        this.parseHeadlinesLanguage(structureData['backgrounds'], s['Lasche'], 'Hintergrund', data[s['Lasche']]);

                        if (data[s['Lasche']].filter(e => e['Typ'] === 'Zeit').length) {
                            this.getTimeMeaningQuestions(structureData['Time']['questions'], s['Lasche'], this.props.state.lang, data[s['Lasche']], 'Zeit');
                        }

                        if (data[s['Lasche']].filter(e => e['Typ'] === 'Bedeutung').length) {
                            this.getTimeMeaningQuestions(structureData['Meaning']['questions'], s['Lasche'], this.props.state.lang, data[s['Lasche']], 'Bedeutung');
                        }

                        structureData['dataBackup'] = {
                            questions: structureData['questions'],
                            bgm: structureData['bgm'],
                            infoBubble: structureData['infoBubble'],
                            Time: structureData['Time'],
                            Meaning: structureData['Meaning'],
                            backgrounds: structureData['backgrounds'],
                            headlines: structureData['headlines'],
                            subheadline: structureData['subheadline']
                        };
                    } else if (s['View'] === 'Start') {
                        this.parseStructuredLanguage(structureData, 'Start', data[s['Lasche']]);
                    } else if (s['View'] === 'GAStart') {
                        this.parseStructuredLanguage(structureData, 'GAStart', data[s['Lasche']]);
                    } else if (s['View'] === 'Impressum') {
                        this.parseLanguage(structureData, 'imprint', data[s['Lasche']]);
                    } else if (s['View'] === 'Konfiguration') {

                        structureData['config'] = data[s['Lasche']][0];

                        if (!!this.props.state.user && !!this.props.state.user.themeColor) {
                            changeColor(this.props.state.user.themeColor, 'dynamicCss');
                        } else {
                            changeColor(structureData['config']['Farbe'], 'dynamicCss');
                        }

                    } else {
                        if (s['View'] === 'Uebersetzungen') {
                            structureData['language'] = {};

                            for (const key in Object.entries(data[s['Lasche']])) {
                                let value = data[s['Lasche']][key];

                                let lang = null;
                                if (!!value['Language']) {
                                    lang = value['Language'];
                                }

                                if (!structureData['language'][lang]) {
                                    structureData['language'][lang] = {};
                                }

                                structureData['language'][lang][value['Typ']] = value['Text'];
                            }
                        } else if (s['Lasche'] === 'Zeit') {
                            this.parseLanguage(structureData, 'Time', data[s['Lasche']]);
                            this.getInfos(structureData['infoBubble'], 'Time', data[s['Lasche']]);
                        } else if (s['Lasche'] === 'Bedeutung') {
                            this.parseLanguage(structureData, 'Meaning', data[s['Lasche']]);
                            this.getInfos(structureData['infoBubble'], 'Meaning', data[s['Lasche']]);
                        } else if (s['Lasche'] === 'Auswertung') {
                            structureData[s['ID']] = {};

                            this.parseLanguage(structureData, s['ID'], data[s['Lasche']]);
                            this.getInfos(structureData['infoBubble'], 'Auswertung', data[s['Lasche']]);
                        } else {
                            structureData[s['ID']] = {};

                            this.parseLanguage(structureData, s['ID'], data[s['Lasche']]);
                        }
                    }

                    this.parseHeadlinesLanguage(structureData['headlines'], s['ID'], 'Ueberschrift', data[s['Lasche']]);

                    return structureData;
                });
            }

            structureData['loading'] = false;

            // console.log(structureData, this.props.state.lang, lang);
            //
            // if (!!structureData['Auswertung']) {
            //     window.document.title = 'BalanceAktiv - ' + structureData['Auswertung'][this.props.state.lang]['Coach'];
            // }

            // console.log(structureData);

            this.props.setData(structureData);
        });
    }

    async componentDidMount() {
        await this.getXMLData();
    }

}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        {
            setData
        }
    )(
        AppRouter
    )
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, withRouter} from 'react-router-dom';
import Forms from "./forms.class";
import {cookie} from "cookie.js";
import queryString from "query-string";

import {setData} from '../../redux/actions';
import InfoBubble from './infobubble.class';

class Routes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            view: 'form',
            infoBubble: false
        };

        this.params = queryString.parse(this.props.location.search);
        this.code = '';
        if (!!this.params['code']) {
            this.code = '?code=' + this.params['code'];
        }

        this.setLanguage = this.setLanguage.bind(this);
        this.toggleBlur = this.toggleBlur.bind(this);
    }

    toggleBlur(state) {
        this.setState({
            infoBubble: state
        });
    }

    setLanguage(lang) {
        cookie.set('language', lang);

        this.language = lang;

        this.props.setData({
            lang: lang
        })
    }

    render() {
        if (!!this.props.structure && Object.keys(this.props.invitationData).length) {
            return (
                <>
                    <div className={'app healthyWorking' + (this.state.infoBubble ? ' blur' : '')}>
                        <Forms publish={this.props.publish} setLanguage={this.setLanguage} toggleBlur={this.toggleBlur} />
                    </div>

                    {((!!this.props.questionData && !!this.props.questionData.infoBubbleActive && this.props.questionData.infoBubbleActive === 'true') && !!this.props.sessions['infoBubble'] && (!this.props.sessions['infoBubble']['healthyworking'] || this.props.sessions['infoBubble']['healthyworking'] === false)) &&
                        <Route path={'/lobby/healthyworking/section/rating'} key={'section'}>
                            <InfoBubble publish={(e) => this.props.publish(e)} toggleBlur={this.toggleBlur} />
                        </Route>
                    }
                </>
            );
        } else {
            return <></>;
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.sessions.path !== this.props.sessions.path) {
            this.props.history.push({
                pathname: this.props.sessions.path,
                search: this.code
            })
        }
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                // state: state,
                invitationData: state.invitationData,
                sessions: state.sessions,
                lang: state.lang,
                position: state.position,
                structure: state.structure,
                language: state.language,
                questionData: state.questionData,
            }
        },
        {
            setData
        }
    )(
        Routes
    )
);

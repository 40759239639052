import React, {Component} from 'react';
import {pdf} from "@react-pdf/renderer";
import {PdfDocument} from "../healthyworkingboard/qrpdf";
import {saveAs} from 'file-saver';
import {connect} from "react-redux";

import { QRCode } from 'react-qrcode-logo';

const generatePdfDocument = async (fileName, documentData, qrCodeBlob, done) => {
    pdf(<PdfDocument data={documentData} qrCodeBlob={qrCodeBlob} />).toBlob().then((blob) => {
        saveAs(blob, fileName);
        done();
    });
};


class PdfDownload extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            qrCodeBlob: null
        };

        this.done = this.done.bind(this);
    }

    done() {
        this.setState({
            loading: false
        })
    }

    async componentDidMount() {
        const canvas = this.refs['qr_' + this.props.invitationData.code].canvas.current;
        const pngUrl = canvas.toDataURL();

        this.setState({
            qrCodeBlob: pngUrl
        });
    }

    render() {

        let link = window.location.protocol + "//" + window.location.host + '/login?code=' + this.props.invitationData.code;

        return (
                <div className={'download'}>
                    {
                        (this.state.loading === true
                                ?
                                <div className={'loading'}>
                                    <div className="spinner">
                                        <div className="bounce1" />
                                        <div className="bounce2" />
                                        <div className="bounce3" />
                                    </div>
                                </div>
                                :
                                <button title={'QR Code Generieren'} className={'generate material-icons'} onClick={() => this.setState({loading: true})}>
                                    qr_code_2
                                </button>
                        )
                    }

                    <QRCode
                        id={'qr_' + this.props.invitationData.code}
                        className={'QRCode'}
                        value={link}
                        quietZone={0}
                        size={400}
                        bgColor={'#ffffff'}
                        fgColor={'#000000'}
                        ref={'qr_' + this.props.invitationData.code}
                    />

                </div>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.a !== this.state.loading && this.state.loading === true) {
            generatePdfDocument('BalanceAktivQRCode.pdf', this.props, this.state.qrCodeBlob, this.done);
        }
    }

}

export default connect(
    (state) => {
        return {
            state: state,
        }
    },
    null
)(
    PdfDownload
);

import {createStore} from 'redux';

// only uncomment if store logging is needed
//import {applyMiddleware, createStore} from 'redux';

import rootReducer from '../reducers';

// only uncomment if store logging is needed
// import logger from 'redux-logger';

const store = createStore(
    rootReducer,
    // only uncomment if store logging is needed
    // applyMiddleware(logger)
);

export default store;

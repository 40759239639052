import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {getPermissions, getTranslation} from "../helper";

import Chat from './chat.class.js';


class VideoLobby extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fullscreen: false,
        }
    }

    toggleFullscreen() {
        let fullscreen = !this.state.fullscreen;

        this.setState({fullscreen:fullscreen});
        this.props.setFullscreen({fullscreen:fullscreen});

        let elem = document.querySelector('#videoArea');

        if (!document.fullscreenElement) {
            elem.requestFullscreen().catch(err => {
                alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
        } else {
            document.exitFullscreen();
        }
    }


    render() {
        return (
            <div className={'lobbyBox' + (this.state.fullscreen ? ' fullscreen' : '')}>
                <div id={'videoArea'} className={'videoArea'}>
                    <div className={'videoBox local'}>
                        <div className={'options'}>
                            <span className={'name'}>
                                {getPermissions('Coach', this.props)
                                    ?
                                    (!!this.props.invitationData['coach'] && !!this.props.invitationData['coach']['name']) ? this.props.invitationData['coach']['name'] : ''
                                    :
                                    !!this.props.invitationData.coacheeName ? this.props.invitationData.coacheeName : ''
                                }
                            </span>

                            <div className={'buttons'}>
                                <div className={'material-icons audio'} onClick={() => this.props.changeMedia({video:this.props.video, audio:!this.props.audio})} title={this.props.audio ? getTranslation(this, this.props.state.lang, 'MicOff') : getTranslation(this, this.props.state.lang, 'MicOn')}>{this.props.audio ? 'mic' : 'mic_off'}</div>
                                <div className={'material-icons vidoe'} onClick={() => this.props.changeMedia({video:!this.props.video, audio:this.props.audio})} title={this.props.video ? getTranslation(this, this.props.state.lang, 'CamOff') : getTranslation(this, this.props.state.lang, 'CamOn')}>{this.props.video ? 'videocam' : 'videocam_off'}</div>
                                {/*<div className={'material-icons'} onClick={() => this.shareScreen()} title={this.state.screenshare ? 'Bildschirm nicht mehr teilen' : 'Bildschirm teilen'}>{this.state.screenshare ? 'stop_screen_share' : 'screen_share'}</div>*/}
                                <div className={'material-icons fullscreen'} onClick={() => this.toggleFullscreen()} title={this.state.fullscreen ? getTranslation(this, this.props.state.lang, 'FullscreenOff') : getTranslation(this, this.props.state.lang, 'FullscreenOn')}>{this.state.fullscreen ? 'fullscreen_exit' : 'fullscreen'}</div>
                            </div>
                        </div>

                        <div className={'video'} onLoadCapture={() => this.peerConnection(null)}>
                            <video id="vidLocal" autoPlay muted={true} />
                            <div id={'videoOverlay'} className={'videoOverlay material-icons'}>video_call</div>
                        </div>
                    </div>

                    <div className={'videoBox remote'}>
                        <div className={'options remote'}>
                            <span className={'name'}>
                                {getPermissions('Coach', this.props)
                                    ?
                                        !!this.props.invitationData.coacheeName ? this.props.invitationData.coacheeName : ''
                                    :
                                        (!!this.props.invitationData['coach'] && !!this.props.invitationData['coach']['name']) ? this.props.invitationData['coach']['name'] : ''
                                }
                            </span>
                        </div>

                        <div className={'video'}>
                            <video id="vidRemote" autoPlay />
                            <div id={'videoRemoteOverlay'} className={'videoOverlay material-icons'}>videocam_off</div>
                        </div>
                    </div>
                </div>

                <div className={'horizontalShadow'} />

                <Chat publish={this.props.publish} />

            </div>
        );
    }
}


export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                invitationData: state.invitationData,
                sessions: state.sessions,
                language: state.language,
            }
        },
        null
    )(
        VideoLobby
    )
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Route, NavLink, Switch} from 'react-router-dom';
import moment from 'moment';

import {
    getPermissions,
    logout,
    token,
    getLicenceModel,
    changeColor,
    getTranslation,
    getFreeLinces
} from "../helper";
import {Client, StompConfig, StompHeaders} from "@stomp/stompjs";
import axios from "axios";

import {setData} from "../../redux/actions";

import Filter from './filter.class';
import UserFilter from './userFilter.class';
import ListItem from './listItem.class';
import UserListItem from './userListItem.class';

import AddUser from './addUser.class';
import Popup from './popup.class';
import Profile from './profile.class';
import Error from './error.class';

import SideMenu from './sideMenu.class';

import '../../assets/scss/admin/dashboard.scss';
import Edituser from "./editUser.class";


class Dashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            invitations: {
                open: {},
                closed: {}
            },
            user: [],
            filter: {
                invitations: null
            },
            addUser: false,
            popup: null,
            code: null,
            menu: false,
            hidden: false,
            edituser: null,
            invitationData: null,
            action: 'addSession'
        };

        if (!!props.state.user['themeColor']) {
            changeColor(props.state.user['themeColor'], 'dynamicCss');
        }

        this.setThisState = this.setThisState.bind(this);
        this.setPopup = this.setPopup.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.deleteSession = this.deleteSession.bind(this);
        this.getInvitationsAndStartWS = this.getInvitationsAndStartWS.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.resendInvitation = this.resendInvitation.bind(this);
        this.editUser = this.editUser.bind(this);
        this.setSessions = this.setSessions.bind(this);

        console.log(props.state)
    }

    getInvitationsAndStartWS() {
        let config = new StompConfig();
        config.brokerURL = process.env['REACT_APP_WS'] + '/stomp';
        config.connectHeaders = new StompHeaders();
        config.connectHeaders.passcode = token().replace('Bearer ', ''); // use only if user is logged in and JWT token is available

        this.client = new Client(config);


        let maxConnectionAttempts = 10;
        let currentTry = 0;
        // this.client.debug = (str) => {
        //     console.error(str)
        // };

        this.client.deactivate();

        this.client.beforeConnect = () => {
            currentTry++;

            console.log(`Connection attempt: ${currentTry}`);
            if (currentTry > maxConnectionAttempts) {
                console.log(`Exceeds max attempts (${maxConnectionAttempts}), will not try to connect now`);

                // It is valid to call deactivate from beforeConnect
                this.client.deactivate();
            }
        }

        if (getPermissions('Coach', this.props) || getPermissions('BigCompany', this.props) || getPermissions('SmallCompany', this.props) || getPermissions('Admin', this.props)) {
            if (getPermissions('Coach', this.props)) {
                axios.get(process.env['REACT_APP_API'] + '/private/invitation/v1', {
                    'headers': {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'X-Requested-With': 'XMLHttpRequest',
                        'Authorization': token()
                    }
                }).then(result => {

                    if (!!result && result.data) {

                        this.client.activate();
                        let invitations = {
                            open: {},
                            closed: {}
                        };

                        this.client.onConnect = () => {
                            result.data.forEach(r => {
                                if ((!invitations.open[r.code] && r['invitationState'] !== 'Closed') && r['hidden'] === false) {
                                    invitations.open[r.code] = {};
                                }
                                if ((!invitations.closed[r.code] && r['invitationState'] === 'Closed')) {
                                    invitations.closed[r.code] = {};
                                }

                                if (r['invitationState'] !== 'Closed' && !r['hidden'] && !moment(r.endDate).isBefore(moment())) {
                                    this.client.subscribe('/user/topic/invitation/' + r.code, message => {
                                        let data = JSON.parse(message.body);

                                        if ((!!data.hidden && data.hidden === true) || data.invitationState === 'Closed') {
                                            delete this.state.invitations.open[r.code];

                                            this.setState({
                                                invitations: {
                                                    open: this.state.invitations.open,
                                                    closed: Object.assign({}, this.state.invitations.closed, {
                                                        [r.code]: data
                                                    })
                                                }
                                            });
                                        } else {
                                            this.setState({
                                                invitations: {
                                                    open: Object.assign({}, this.state.invitations.open, {
                                                        [r.code]: data
                                                    }),
                                                    closed: this.state.invitations.closed
                                                }
                                            });
                                        }
                                    });
                                }

                                if (r['invitationState'] !== 'Closed' && r['hidden'] === false) {
                                    invitations.open[r.code] = r;
                                } else {
                                    invitations.closed[r.code] = r;
                                }
                            });

                            this.setState({
                                invitations: invitations
                            });
                        }
                    }

                }).catch(error => {
                    console.log('err',error);
                })
            }


            this.getUsers();
        } else {
            logout(this.props);
        }
    }

    getInvitations(data) {

    }

    componentDidMount() {

        this.getInvitationsAndStartWS()

    }

    getUsers() {
        axios.get(process.env['REACT_APP_API'] + '/private/user/v1/owningUsers', {
            'headers': {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': token()
            }
        }).then(result => {

            let user = {
                coaches: [],
                groups: []
            };

            if (!!result && !!result.data) {
                result.data.forEach(val => {
                    if (!!val['roles'] && val['roles'].includes('Coach')) {
                        user.coaches.push(val);
                    } else {
                        user.groups.push(val);
                    }
                });

                this.setState({
                    user: user
                });
            }
        }).catch(error => {
            console.log(error, error.response);
            if (!!error.response && !!error.response.data.area && error.response.data.area === 'Security' && !!error.response.data.indication && error.response.data.indication === 'LoginFailed') {
                logout(this.props);
            } else {
                this.props.setData({
                    error: (!!error.response && !!error.response.data) ? error.response.data : error
                });
            }
        });
    }

    deleteUser(userID) {
        if (getPermissions('Coach', this.props) || getPermissions('Admin', this.props)) {
            axios.delete(process.env['REACT_APP_API'] + '/private/user/v1/' + userID, {
                'headers': {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': token()
                }
            }).then(result => {
                this.getUsers();
            }).catch(error => {
                console.error(error.response.data);
                this.props.setData({
                    error: error.response.data
                });
            });
        }
    }

    deleteSession(code) {
        if (getPermissions('Coach', this.props)) {
            this.client.publish({
                destination: '/app/invitation/' + code,
                body: JSON.stringify({
                    invitationState: 'Closed',
                    hidden: true
                })
            });


            if (!!this.state.invitations.open && this.state.invitations.open[code]) {
                let data = this.state.invitations.open[code];
                data.hidden = true;

                delete this.state.invitations.open[code];


                this.setState({
                    invitations: {
                        open: this.state.invitations.open,
                        closed: Object.assign({}, this.state.invitations.closed, {
                            [code]: data
                        })
                    }
                });
            } else {
                let data = this.state.invitations.closed[code];
                data.hidden = true;

                this.setState({
                    invitations: {
                        open: this.state.invitations.open,
                        closed: Object.assign({}, this.state.invitations.closed, {
                            [code]: data
                        })
                    }
                });
            }

            // axios.patch(process.env['REACT_APP_API'] + '/public/invitation/v1/' + code, {
            //     hidden: true
            // }, {
            //     'headers': {
            //         'Content-Type': 'application/json',
            //         'X-Requested-With': 'XMLHttpRequest',
            //         'Authorization': token()
            //     }
            // }).then(result => {
            //     // write code for "deleted" session
            //     // this.getInvitationsAndStartWS();
            // }).catch(error => {
            //     console.error(error.response.data);
            //     this.props.setData({
            //         error: error.response.data
            //     });
            // });
        }
    }

    resendInvitation(code) {
        if (getPermissions('Coach', this.props)) {
            this.props.setData({
                error: null
            });

            axios.post(process.env['REACT_APP_API'] + '/private/invitation/v1/' + code + '/resend', {}, {
                'headers': {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': token()
                }
            }).then(result => {
                this.setPopup();
                // write code for "deleted" session
                // this.getInvitationsAndStartWS();
            }).catch(error => {
                console.error(error.response.data);
                this.props.setData({
                    error: error.response.data
                });
            });
        }
    }

    setThisState(popup = false) {
        this.setState({
            addUser: popup
        });
    }

    setPopup(popup = null, code = null) {
        this.props.setData({
            error: null
        });

        this.setState({
            popup: popup,
            code: code
        });
    }

    editUser(id) {
        this.setState({
            edituser: id,
        });
    }

    setSessions(popup, state, data) {
        this.setThisState(null);

    console.log(data);
        setTimeout(() => {
            this.setState({
                invitationData: data,
                addUser: popup,
                action: state
            });
        },100);
    }

    methods() {
        return {
            setThisState: this.setThisState,
            setPopup: this.setPopup,
            getUsers: this.getUsers,
            deleteUser: this.deleteUser,
            deleteSession: this.deleteSession,
            getInvitationsAndStartWS: this.getInvitationsAndStartWS,
            resendInvitation: this.resendInvitation,
            editUser: this.editUser,
            setSessions: this.setSessions
        }
    }


    render() {
        return (
            <>
                <div className={'dashboard'}>

                    <div className={'topBar'}>
                        <div className={'flex'} />

                        <div className={'centerBox'}>
                            <h2>Balanceaktiv Dashboard</h2>

                            {!!getPermissions('Coach', this.props) &&
                                <>
                                    &nbsp;/&nbsp;
                                    <h3>
                                        <Switch>
                                            <Route path={'/admin/dashboard/coaches'}>{getTranslation(this, this.props.state.lang, 'Coaches')}</Route>
                                            <Route path={'/admin/dashboard/profile'}>{getTranslation(this, this.props.state.lang, 'Profile')}</Route>
                                            <Route path={'/admin/dashboard/vouchers'} exact>{getTranslation(this, this.props.state.lang, 'Vouchers')}</Route>
                                            <Route path={'/admin/dashboard/invitations'} exact>{getTranslation(this, this.props.state.lang, 'OpenSessions')}</Route>
                                            <Route path={'/admin/dashboard/invitations/closed'} exact>{getTranslation(this, this.props.state.lang, 'ClosedSessions')}</Route>
                                        </Switch>
                                    </h3>
                                    &nbsp;|&nbsp;
                                    <h3>
                                        {getTranslation(this, this.props.state.lang, 'License')}: {getLicenceModel(this, this.props)}
                                    </h3>
                                </>
                            }

                            {(!!getPermissions('Admin', this.props) && !!this.state.user.coaches) &&
                                <>
                                    &nbsp;
                                    (
                                        {this.state.user.coaches.filter(e => e['userState'] === 'Active').length} {getTranslation(this, this.props.state.lang, 'ActiveUser')}
                                    )
                                </>
                            }

                        </div>

                        <SideMenu methods={this.methods()} invitations={this.state.invitations} user={this.state.user} />
                    </div>


                    <div className={'listBox'}>


                        {!!getPermissions('Coach', this.props) &&
                            <>
                                <div className={'links'}>
                                    <NavLink exact to={'/admin/dashboard/vouchers'} activeClassName={'current theme_color'} className={'theme_bordercolor_before'}>{getTranslation(this, this.props.state.lang, 'Vouchers')} ({!!this.state.invitations && !!this.state.invitations.open ? Object.keys(this.state.invitations.open).filter(key => !this.state.invitations.open[key]['plannedDate']).length : 0})</NavLink>
                                    <NavLink exact to={'/admin/dashboard/invitations'} activeClassName={'current theme_color'} className={'theme_bordercolor_before'}>{getTranslation(this, this.props.state.lang, 'OpenSessions')} ({!!this.state.invitations && !!this.state.invitations.open ? Object.keys(this.state.invitations.open).filter(key => !!this.state.invitations.open[key]['plannedDate']).length : 0})</NavLink>
                                    <NavLink to={'/admin/dashboard/invitations/closed'} activeClassName={'current theme_color'} className={'theme_bordercolor_before'}>{getTranslation(this, this.props.state.lang, 'ClosedSessions')} ({!!this.state.invitations && !!this.state.invitations.closed ? Object.keys(this.state.invitations.closed).length : 0})</NavLink>
                                </div>

                                <div className={'horizontalShadow'} />

                                <Route exact path={'/admin/dashboard/vouchers'}>

                                    <Filter filter={this.state.filter.invitations} />

                                    {(!!this.state.invitations.open && Object.keys(this.state.invitations.open).filter(key => !this.state.invitations.open[key]['plannedDate']).length > 0) &&
                                        Object.keys(this.state.invitations.open).filter(key => !this.state.invitations.open[key]['plannedDate']).sort((a,b) => moment(this.state.invitations.open[b]['plannedDate']).diff(moment(this.state.invitations.open[a]['plannedDate']))).map((e, key) => {
                                            return <ListItem data={this.state.invitations.open[e]} index={key} key={key} methods={this.methods()} />
                                        })
                                    }

                                    {(!this.state.invitations.open || Object.keys(this.state.invitations.open).filter(key => !this.state.invitations.open[key]['plannedDate']).length === 0) &&
                                        <div className={'listItemEmpty'}>{getTranslation(this, this.props.state.lang, 'NoSession')}</div>
                                    }

                                    {(getLicenceModel(this, this.props) === 'Flatrate' || (getFreeLinces(this.props, this.state.invitations) > 0)) &&
                                        <div className={'buttonBox'}>
                                            <button className={'button theme_color theme_bordercolor theme_background_hover'} onClick={() => this.setState({addUser:'vouchers'})}>{getTranslation(this, this.props.state.lang, 'addVouchers')}</button>
                                        </div>
                                    }
                                </Route>

                                <Route exact path={'/admin/dashboard/invitations'}>

                                    <Filter filter={this.state.filter.invitations} />

                                    {(!!this.state.invitations.open && Object.keys(this.state.invitations.open).filter(key => !!this.state.invitations.open[key]['plannedDate']).length > 0) &&
                                        Object.keys(this.state.invitations.open).filter(key => !!this.state.invitations.open[key]['plannedDate']).sort((a,b) => moment(this.state.invitations.open[b]['plannedDate']).diff(moment(this.state.invitations.open[a]['plannedDate']))).map((e, key) => {
                                            return <ListItem data={this.state.invitations.open[e]} index={key} key={key} methods={this.methods()} />
                                        })
                                    }
                                    {(!this.state.invitations.open || Object.keys(this.state.invitations.open).filter(key => !!this.state.invitations.open[key]['plannedDate']).length === 0) &&
                                        <div className={'listItemEmpty'}>{getTranslation(this, this.props.state.lang, 'NoSession')}</div>
                                    }

                                    {(getLicenceModel(this, this.props) === 'Flatrate' || (getFreeLinces(this.props, this.state.invitations) > 0)) &&
                                        <div className={'buttonBox'}>
                                            <button className={'button theme_color theme_bordercolor theme_background_hover'} onClick={() => this.setState({addUser:'chooseSessionType', action:'addSession', invitationData: null})}>{getTranslation(this, this.props.state.lang, 'AddSession')}</button>
                                        </div>
                                    }
                                </Route>

                                <Route exact path={'/admin/dashboard/invitations/closed'}>

                                    <Filter filter={this.state.filter.invitations} methods={this.methods()} />

                                    {(!!this.state.invitations.closed && Object.keys(this.state.invitations.closed).length > 0) &&
                                        Object.keys(this.state.invitations.closed).filter(e => this.state.invitations.closed[e].hidden === false).sort((a,b) => moment(this.state.invitations.closed[b]['plannedDate']).diff(moment(this.state.invitations.closed[a]['plannedDate']))).map((e, key) => {
                                            return <ListItem data={this.state.invitations.closed[e]} index={key} key={key} methods={this.methods()} />
                                        })
                                    }

                                    {(!!this.state.invitations.closed && Object.keys(this.state.invitations.closed).filter(e => this.state.invitations.closed[e].hidden === true).length !== 0) &&
                                        <div className={'listItemEmpty'}>
                                            {getTranslation(this, this.props.state.lang, 'XDeletedSessions').replace('*', Object.keys(this.state.invitations.closed).filter(e => this.state.invitations.closed[e].hidden === true).length)}
                                        </div>
                                    }

                                    {(!this.state.invitations.closed || (Object.keys(this.state.invitations.closed).length === 0 && Object.keys(this.state.invitations.closed).filter(e => this.state.invitations.closed[e].hidden === false).length === 0)) &&
                                        <div className={'listItemEmpty'}>
                                            {getTranslation(this, this.props.state.lang, 'NoFinishedSession')}
                                            {(Object.keys(this.state.invitations.closed).filter(e => this.state.invitations.closed[e].hidden === true).length > 0) &&
                                            ', ' + getTranslation(this, this.props.state.lang, 'XDeletedSessions').replace('*', Object.keys(this.state.invitations.closed).filter(e => this.state.invitations.closed[e].hidden === true).length)
                                            }
                                        </div>
                                    }
                                </Route>
                            </>
                        }


                        <Route exact path={'/admin/dashboard/coaches'}>
                            <UserFilter filter={this.state.filter.user} />

                            {(!!this.state.user.coaches && Object.keys(this.state.user.coaches).length > 0) &&
                                Object.keys(this.state.user.coaches).map((e, key) => {
                                    return <UserListItem data={this.state.user.coaches[e]} index={key} key={key} methods={this.methods()} />
                                })
                            }
                            {(!this.state.user.coaches || Object.keys(this.state.user.coaches).length === 0) &&
                                <div className={'listItemEmpty'}>{getTranslation(this, this.props.state.lang, 'CoachAdd')}</div>
                            }

                            <div className={'buttonBox'}>
                                <button className={'button theme_color theme_bordercolor theme_background_hover'} onClick={() => this.setState({addUser:'coach'})}>{getTranslation(this, this.props.state.lang, 'CoachAdd')}</button>
                                {/*<button className={'button theme_background theme_color_hover theme_bordercolorHover'} onClick={() => this.setState({addUser:'options'})}>{getTranslation(this, this.props.state.lang, 'Options')}</button>*/}
                            </div>
                        </Route>

                        <Route exact path={'/admin/dashboard/companies'}>
                            <UserFilter filter={this.state.filter.user} />

                            {(!!this.state.user.groups && Object.keys(this.state.user.groups).length > 0) &&
                                Object.keys(this.state.user.groups).map((e, key) => {
                                    return <UserListItem data={this.state.user.groups[e]} index={key} key={key} methods={this.methods()} />
                                })
                            }
                            {(!this.state.user.groups || Object.keys(this.state.user.groups).length === 0) &&
                                <div className={'listItemEmpty'}>{getTranslation(this, this.props.state.lang, 'NoCompany')}</div>
                            }

                            <div className={'buttonBox'}>
                                <button className={'button theme_color theme_bordercolor theme_background_hover'} onClick={() => this.setState({addUser:'company'})}>{getTranslation(this, this.props.state.lang, 'CompanyAdd')}</button>
                            </div>
                        </Route>
                    </div>

                </div>

                {this.state.addUser &&
                    <AddUser methods={this.methods()} section={this.state.addUser} invitations={this.state.invitations} invitationData={this.state.invitationData} action={this.state.action} />
                }
                {(this.state.popup && this.state.popup !== 'profile') &&
                    <Popup type={this.state.popup} methods={this.methods()} code={this.state.code} />
                }

                {(this.state.popup && this.state.popup === 'profile') &&
                    <Profile methods={this.methods()} />
                }

                {(!!this.state.edituser) &&
                    <Edituser methods={this.methods()} data={this.state.user.coaches.filter(e => e.id === this.state.edituser)[0]} />
                }


                {!!this.props.state.error &&
                    <Error />
                }
            </>
        );
    }

    componentWillUnmount() {
        this.client.deactivate();
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
            }
        },
        {
            setData
        }
    )(
        Dashboard
    )
);

import {boardPosition, loggedin, userType, getCode} from "../../components/helper";
import {cookie} from "cookie.js";

const availableLanguages = ["de", "en"];
let language;

if (!!cookie.get('language')) {
    language = cookie.get('language');
} else {
    language =
        [
            ...(window.navigator.languages || []),
            window.navigator.language,
            window.navigator.browserLanguage,
            window.navigator.userLanguage,
            window.navigator.systemLanguage
        ]
            .filter(Boolean)
            .map(_language => _language.substr(0, 2))
            .find(_language => availableLanguages.includes(_language)) || "de";

    language = language.length ? language : 'de';
}


const initialState = {
    dataBackup: {
        questions: {},
        time: {},
        meaning: {},
    },
    config: {},

    Start: {},
    questions: {},
    structure: new Map(),
    backgrounds: {},
    Time: {},
    Meaning: {},
    imprint: '',
    timestamp: '',
    route: window.location.pathname,
    user: loggedin(),
    userType: userType(),
    users: [],
    username: null,
    position: boardPosition(),

    timeMeaning: false,

    lang: language,
    language: {},

    zoomed: false,
    logout: false,

    results: {
        received: '',
        willDo: '',
        downloadAllowed: false,
        timeMeaning: false
    },

    loading: false,
    error: false,
    code: getCode(),

    invitationData: {},

    userID: !!cookie.get('userID') ? cookie.get('userID') : 'SINGLE',

    sessions: {
        results: {},
        started: false,
        infoBubble: {},
        chat: null
    },
    defaultSessionsData: {
        results: {},
        started: false,
        infoBubble: {},
        chat: null
    },

    questionData: []
};

function rootReducer(state = initialState, action) {
    switch(action.type) {
        case 'SET_DATA': {
            let data = action.payload;

            return Object.assign({}, state, data);
        }

        default: {
            return state;
        }
    }
}

export default rootReducer;

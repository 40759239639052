import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getPermissions, getTranslation, logout} from "../helper";
import moment from "moment";
import {setData} from "../../redux/actions";
import queryString from "query-string";

import Modal from '../modal.class';


class Footer extends Component {
    constructor(props) {
        super(props);

        this.params = queryString.parse(props.location.search);
    }

    render() {
        let code = '';
        if (!!this.params.code) {
            code = '?code=' + this.params.code;
        }

        return (
            <footer className={'lobbyFooter'}>
                <div className={'horizontalShadow'} />

                <div className={'footerInner'}>
                    <div className={'logoBox'}>
                        {(!!this.props.invitationData && !!this.props.invitationData['coach'] && !!this.props.invitationData['coach']['avatar'])
                            ?
                                <div className={'logo'} style={{backgroundImage: 'url(' + this.props.invitationData['coach']['avatar'] + ')'}} />
                            :
                                <div className={'logo'} style={{backgroundImage: 'url(/assets/img/balance_aktiv.png)'}} />
                        }
                    </div>

                    <div className={'centerBox'}>
                        {(this.props.invitationData['invitationState'] !== 'Closed' && this.props.invitationData.invitationState !== 'Waiting'  && this.props.invitationData.invitationState === 'Active' && !!this.props.invitationData['board'] && this.props.invitationData['board'] === 'balanceboard') &&
                            <a href={'/lobby/balanceboard/start' + code} target={'balanceaktiv'} className={'footer_button button theme_color theme_bordercolor theme_background_hover'}>
                                <span>{getTranslation(this, this.props.state.lang, 'StartBalanceboard')}</span>
                            </a>
                        }

                    </div>

                    <div className={'linkBox'}>

                        {getPermissions('Coach', this.props) &&
                            <Modal button={{
                                title: getTranslation(this, this.props.state.lang, 'Hint'),
                                text: getTranslation(this, this.props.state.lang, 'SessionRestart'),
                                className: 'footer_button button reset',
                                id: 'btnStart',
                                confirm: () => this.props.resetSession(),
                                button: getTranslation(this, this.props.state.lang, 'Restart')
                            }} />
                        }

                        {(this.props.invitationData['invitationState'] !== 'Sent' && getPermissions('Coach', this.props)) &&
                            <Modal button={{
                                title: getTranslation(this, this.props.state.lang, 'Hint'),
                                text: getTranslation(this, this.props.state.lang, 'EndSessionText'),
                                className: 'footer_button button theme_color theme_bordercolor theme_background_hover',
                                id: 'btnStart',
                                confirm: e => {
                                    e.preventDefault();

                                    this.props.publish({
                                        invitationState: 'Closed',
                                        endDate: moment().utc().toISOString()
                                    });
                                    window.close();
                                },
                                button: getTranslation(this, this.props.state.lang, 'EndSession')
                            }} />
                        }

                        {!getPermissions('Coach', this.props) &&
                            <Modal button={{
                                title: getTranslation(this, this.props.state.lang, 'Hint'),
                                text: getTranslation(this, this.props.state.lang, 'EndSessionText'),
                                className: 'footer_button button theme_color theme_bordercolor theme_background_hover',
                                id: 'btnStart',
                                confirm: e => logout(this.props),
                                button: getTranslation(this, this.props.state.lang, 'LeaveSession')
                            }} />
                        }
                    </div>
                </div>
            </footer>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                invitationData: state.invitationData,
                sessions: state.sessions,
                language: state.language,
            }
        },
        {
            setData
        }
    )(
        Footer
    )
);

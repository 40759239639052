import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";

import AppRouter from './AppRouter.class';

import './assets/scss/main.scss';


function App() {
    return (
        <Router>
            <AppRouter />
        </Router>
    );
}

export default App;

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../../assets/scss/balanceboard/cell.scss';
import {connect} from "react-redux";
import {copySessions, getCellData} from "../helper";

let current = 0;
let currentCoin = null;

class CellDiv extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current: getCellData(props, props.section, props.question)
        };

        this.onDrop = this.onDrop.bind(this);
        this.dragOver = this.dragOver.bind(this);
        this.onClick = this.onClick.bind(this);
    }


    onDrag(e) {
        currentCoin = e.target.getAttribute('dataquestion');
    }

    change() {
        let oldData = copySessions(this.props);
        if (!oldData['results']) {
            oldData['results'] = {};
        }
        if (!oldData['results'][this.props.section]) {
            oldData['results'][this.props.section] = {};
        }
        oldData['results'][this.props.section.replace(' ', '-')][this.props.question] = current;

        this.props.publish({sessions: {
            'SINGLE': {
                "@type":"Session",
                'sessionData': JSON.stringify(oldData)
            }
        }});
    }

    onDrop(e) {
        e.preventDefault();

        let cell = e.target.getAttribute('dataquestion');
        let elm = e.target;

        elm.classList.remove('highlight', 'background_hover_add', 'theme_highlight_before');

        if (cell === currentCoin && !elm.classList.contains('notAvailable') && !elm.hasAttribute('disabled')) {
            this.change()
        }
    }

    dragOver(e) {
        e.preventDefault();

        let cell = e.target.getAttribute('dataquestion');
        let elm = e.target;

        if (cell === currentCoin && !elm.classList.contains('notAvailable') && !elm.hasAttribute('disabled')) {
            elm.classList.add('highlight', 'background_hover_add', 'theme_highlight_before');

            if (!!elm.getAttribute('elm')) {
                current = parseInt(elm.getAttribute('elm'));
            }
        }
    }

    dragLeave(e) {
        let elm = e.target;

        elm.classList.remove('highlight', 'background_hover_add', 'theme_highlight_before');
    }

    onClick(e) {
        e.preventDefault();

        let cell = e.target.getAttribute('dataquestion');
        let elm = e.target;
        current = elm.getAttribute('elm');

        elm.classList.remove('highlight', 'background_hover_add', 'theme_highlight_before');

        if (!!cell && !elm.classList.contains('notAvailable') && !elm.hasAttribute('disabled')) {
            this.change();
        }
    }


    render() {
        let div;

        if (this.props.elm === this.state.current) {
            div = React.createElement(
                'img',
                {
                    elm: this.props.elm,
                    key: 'jeton_',
                    className: 'jeton',
                    draggable: true,
                    onDragStart: this.onDrag,
                    dataquestion: this.props.question,
                    src: '../assets/img/coins/' + this.props.data.coin
                }
            )
        }

        let inner = React.createElement(
            'svg',
            {
                key: 'index_',
                className: 'index',
                width:'100%',
                height:'100%'
            },
            React.createElement(
                'text',
                {
                    key: 'index_text',
                    className: 'index_text',
                    x:'50%',
                    y:'50%',
                    textAnchor: 'middle',
                    alignmentBaseline: 'central',
                    dominantBaseline: 'middle',
                    fill: '#999',
                    fontSize: (this.props.elm === 0 && this.props.type !== 'percentage') ? 11 : 16,
                },
                this.props.elm === 0 ? (this.props.type === 'percentage' ? 0 : 'Start') : this.props.elm
            )
        );


        return React.createElement(
            'div',
            {
                elm: this.props.elm,
                className: 'cellDiv' + (this.props.area === 'q' && (this.props.elm === 0) ? ' zero' : '') + /*(this.props.area === 'tm' && (this.state.current + this.props.rest < this.props.elm) ? ' notAvailable' : '') +*/ (this.props.area === 'tm' && (this.props.elm / 5 % 2 === 1) ? ' small background_hover_before' : (this.props.elm === 0 && this.props.type === 'normal' ? '' : ' background_hover')),
                dataquestion: this.props.question,
                onDragOver: this.dragOver,
                onDragLeave: this.dragLeave,
                onDrop: this.onDrop,
                onClick: this.onClick,
                disabled: this.props.elm === 0 && this.props.type === 'normal'
            },
            [
                div,
                inner
            ]
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.results !== this.props.results) {
            this.setState({
                current: getCellData(this.props, this.props.section, this.props.question)
            });
        }
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                results: state.sessions.results,
                sessions: state.sessions,
                userID: state.userID
            }
        },
        null
    )(
        CellDiv
    )
);

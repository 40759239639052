import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';

import {getTranslation, loggedin, logout} from "../helper";
import {setData} from "../../redux/actions";


class Footer extends Component {

    constructor(props) {
        super(props);

        this.logout = this.logout.bind(this);
    }


    logout(e) {
        e.preventDefault();
        logout(this.props);
    }

    render() {
        return (
            <footer className={'adminFooter'}>
                <div className={'horizontalShadow'} />

                <div className={'footerInner'}>
                    <div className={'logoBox'}>
                        {(!!this.props.state.user && !!this.props.state.user['avatar'])
                            ?
                            <div className={'logo'} style={{backgroundImage: 'url(' + this.props.state.user['avatar'] + ')'}} />
                            :
                            <div className={'logo'} style={{backgroundImage: 'url(/assets/img/balance_aktiv.png)'}} />
                        }
                    </div>

                    <div className={'linkBox'}>
                        {loggedin()
                            ? <Link className={'button theme_color theme_bordercolor theme_background_hover'} to={'/admin/login'} onClick={(e) => this.logout(e)}>{getTranslation(this, this.props.state.lang, 'Logout')}</Link>
                            : <Link className={'button theme_color theme_bordercolor theme_background_hover'} to={'/login'}>{getTranslation(this, this.props.state.lang, 'SignIn')}</Link>
                        }
                    </div>
                </div>
            </footer>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
            }
        },
        {
            setData
        }
    )(
        Footer
    )
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import HealthyWorkingQuestion from './question.class';

import '../../../../assets/scss/admin/add/healthyWorking/category.scss';
import HealthyWorkingNewQuestion from "./newQuestion.class";


class HealthyWorkingCategory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            edit: !!this.props.data.edit ? this.props.data.edit : false
        };
    }

    toggleWriting() {
        if (this.props.data.enabled) {
            this.setState({edit: !this.state.edit})
        }
    }

    render() {
        return (
            <div className={'category' + (this.props.data.enabled ? ' category--enabled' : '')}>

                <div className={'category__head'}>
                    <input type={'checkbox'} name={''} checked={this.props.data.enabled} className={'category__head__enable ' + (!this.props.data.enabled ? 'category__head__enable--disabled' : '')}  onChange={() => this.props.methods.changeAllQuestions(this.props.datakey, 'enabled', !this.props.data.enabled)} />

                    <input type={'text'} name={'category'} value={this.props.category} readOnly={!this.props.data.enabled} autoFocus={this.props.data.enabled} className={'category__head__name' + (!this.props.data.enabled ? ' category__head__name--disabled' : ' category__head__name--enabled') + (this.state.edit ? ' category__head__name--readonly' : '')} onChange={(e) => this.props.methods.changeCategory(this.props.datakey, e.target.name, e.target.value)} onClick={() => this.toggleWriting()} onBlur={() => this.toggleWriting()} />

                    <div className={'category__head__remove material-icons' + (!this.props.data.enabled ? ' category__head__remove--disabled' : '')} onClick={() => this.props.methods.removeCategory(this.props.datakey)}>clear</div>
                </div>

                <div className={'category__questions' + (this.props.data.enabled ? ' category__questions--enabled': '')}>
                    {(!!this.props.questions) &&
                        Object.keys(this.props.questions).map(key => {
                            let question = this.props.questions[key];

                            return <HealthyWorkingQuestion key={key} datakey={this.props.datakey} questionkey={key} question={question} methods={this.props.methods} />;
                        })
                    }

                    {
                        Object.keys(this.props.questions).filter(q => this.props.questions[q].enabled === true).length < 8 &&
                            <HealthyWorkingNewQuestion datakey={this.props.datakey} addQuestion={this.props.methods.addQuestion} />
                    }
                </div>

            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        null
    )(
        HealthyWorkingCategory
    )
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {copySessions} from "../helper";
import {setData} from "../../redux/actions";

let timer;

class SliderClass extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: !!props.default ? props.default : 0
        }

        this.change = this.change.bind(this);
    }


    change(e, section, question) {
        if (!!timer) {
            clearTimeout(timer);
        }
        this.setState({
            value: e.target.value
        });

        let self = this;

        timer = setTimeout(() => {
            let oldData = copySessions(self.props);
            if (!oldData['results']) {
                oldData['results'] = {};
            }
            if (!oldData['results'][section]) {
                oldData['results'][section] = {};
            }
            oldData['results'][section][question] = e.target.value;

            self.props.publish({sessions: {
                'SINGLE': {
                    "@type":"Session",
                    'sessionData': JSON.stringify(oldData)
                }
            }});
        }, 500);
    }

    render() {

        let options = [];
        let labels = [];

        for(let i = this.props.min; i <= this.props.max; i+=this.props.step) {

            if (!!this.props.labelSkip) {
                if (String(i).indexOf(this.props.labelSkip) === -1) {
                    options.push(<option value={i} label={i} key={'option_' + i} />);
                    labels.push(<span key={'option_' + i}>{i}</span>);
                }
            } else {
                options.push(<option value={i} label={i} key={'option_' + i} />);
                labels.push(<span key={'option_' + i}>{i}</span>);
            }
        }

        return (
            <div className={'htmlSlider'}>
                <div className={'datalist'} id={'question_' + this.props.question}>
                    {
                        labels
                    }
                </div>
                <input type={'range'} list={'question_' + this.props.question} min={this.props.min} max={this.props.max} value={this.state.value} step={this.props.step} onChange={e => this.change(e, this.props.section, this.props.question)} />
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                sessions: state.sessions,
            }
        },
        {
            setData
        }
    )(
        SliderClass
    )
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, NavLink, withRouter} from 'react-router-dom';
import {setData} from "../../redux/actions";


import '../../assets/scss/admin/sideMenu.scss';
import {getLicenceModel, getPermissions, getTranslation, logout} from "../helper";

class SideMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            menu: false
        };
    }

    render() {
        return (
            <>
                <div className={'sideMenuButton'} onClick={() => this.setState({menu: !this.state.menu})}>
                    <div className={'username'}>
                        {this.props.state.user.name}
                    </div>

                    <div className={'userpic'}>
                        <div className={'userpicInner theme_bordercolor'}>
                            {(!!this.props.state.user && !!this.props.state.user.avatar)
                                ?
                                <img src={this.props.state.user.avatar} alt={getTranslation(this, this.props.state.lang, 'Userpic')} />
                                :
                                <img src={'/assets/img/balance_aktiv.png'} alt={getTranslation(this, this.props.state.lang, 'Userpic')} />
                            }
                        </div>
                    </div>
                </div>

                <div role={'menu'} className={'sideMenu ' + (this.state.menu ? 'visible': '')}>
                    <div className={'overlay'} onClick={() => this.setState({menu: !this.state.menu})} />

                    <div className={'menuInner'}>
                        <div className={'userpic theme_background'}>
                            <div className={'userpicInner'} onClick={(e) => {
                                e.preventDefault();

                                this.setState({menu: false})
                                this.props.methods.setPopup('profile')
                            }}>
                                {(!!this.props.state.user && !!this.props.state.user.avatar)
                                    ?
                                    <img src={this.props.state.user.avatar} alt={getTranslation(this, this.props.state.lang, 'Userpic')} />
                                    :
                                    <img src={'/assets/img/balance_aktiv.png'} alt={getTranslation(this, this.props.state.lang, 'Userpic')} />
                                }
                            </div>
                        </div>

                        <div className={'userBox theme_background'}>
                            <div className={'username'}>
                                {this.props.state.user.name}
                            </div>
                            <div className={'licence'}>

                                {!!getPermissions('Coach', this.props) &&
                                    <>
                                        {getTranslation(this, this.props.state.lang, 'License')}: {getLicenceModel(this, this.props)}
                                    </>
                                }

                                {(!!getPermissions('Admin', this.props) && !!this.props.user && !!this.props.user.coaches) &&
                                    <>
                                        {this.props.user.coaches.filter(e => e['userState'] === 'Active').length} {getTranslation(this, this.props.state.lang, 'ActiveUser')}
                                    </>
                                }
                            </div>
                        </div>

                        <ul className={'navigation'}>
                            <li>
                                <a href={'#profile'} onClick={(e) => {
                                    e.preventDefault();

                                    this.setState({menu: false})
                                    this.props.methods.setPopup('profile')
                                }}>
                                    {getTranslation(this, this.props.state.lang, 'EditProfile')}
                                </a>
                            </li>

                            {getPermissions('Coach', this.props) &&
                                <>
                                    <li>
                                        <hr />
                                    </li>
                                    <li>
                                        <NavLink exact to={'/admin/dashboard/vouchers'} activeClassName={'current theme_color'} className={'theme_bordercolor_before'}>{getTranslation(this, this.props.state.lang, 'Vouchers')} ({!!this.props.invitations && !!this.props.invitations.open ? Object.keys(this.props.invitations.open).filter(key => !this.props.invitations.open[key]['plannedDate']).length : 0})</NavLink>
                                    </li>
                                    <li>
                                        <hr />
                                    </li>
                                    <li>
                                        <NavLink exact to={'/admin/dashboard/invitations'} activeClassName={'current theme_color'} className={'theme_bordercolor_before'}>{getTranslation(this, this.props.state.lang, 'OpenSessions')} ({!!this.props.invitations && !!this.props.invitations.open ? Object.keys(this.props.invitations.open).length : 0})</NavLink>
                                    </li>
                                    <li>
                                        <hr />
                                    </li>
                                    <li>
                                        <NavLink to={'/admin/dashboard/invitations/closed'} activeClassName={'current theme_color'} className={'theme_bordercolor_before'}>{getTranslation(this, this.props.state.lang, 'ClosedSessions')} ({!!this.props.invitations && !!this.props.invitations.closed ? Object.keys(this.props.invitations.closed).length : 0})</NavLink>
                                    </li>
                                </>
                            }
                            {(getPermissions('BigCompany', this.props) || getPermissions('Admin', this.props)) &&
                                <>
                                    <li>
                                        <hr />
                                    </li>
                                    <li>
                                        <Link to={'/admin/dashboard/coaches'} onClick={() => this.setState({menu: false})}>{getTranslation(this, this.props.state.lang, 'Coaches')} ({!!this.props.user.coaches ? this.props.user.coaches.length : 0}{!!this.props.state.user.restriction && !!this.props.state.user.restriction.maxInvitationCount ? + ' /' + this.props.state.user.restriction.maxInvitationCount : ''})</Link>
                                    </li>
                                </>
                            }
                            {/*getPermissions('Admin', this.props) &&
                                <li>
                                    <Link to={'/admin/dashboard/companies'} onClick={() => this.setState({menu: false})}>Unternehmen</Link>
                                </li>
                            */}

                            <li>
                                <hr />
                            </li>
                        </ul>

                        <Link className={'logoff'} to={'/admin/login'} onClick={() => logout(this.props)}>{getTranslation(this, this.props.state.lang, 'Logout')}</Link>
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language
            }
        },
        {
            setData
        }
    )(
        SideMenu
    )
);

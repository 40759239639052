import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import moment from "moment";
import axios from "axios";
import {setData} from "../../../../redux/actions";

import TimePicker from "rc-time-picker";
import 'rc-time-picker/assets/index.css';

import {
    getFreeLinces, getPermissions, getTotalInvitations,
    getTranslation, getUsedLicences, token,
} from "../../../helper";
import {ReactComponent as Close} from "../../../../assets/img/close.svg";


class AddSession extends Component {

    constructor(props) {
        super(props);

        this.state = this.props.data;

        this.addSession = this.addSession.bind(this);
        this.editSession = this.editSession.bind(this);
    }

    addSession(e) {
        e.preventDefault();

        let data = {
            "@type": "Invitation",
            coacheeEmail: this.state.mail,
            coacheeName: this.state.name,

            "coachOnline": false,
            "coacheeOnline": false,
            "hidden": false,
            "invitationState": "New",
            "plannedDate": moment.utc(this.state.plannedDate).format(),
            "endDate": moment.utc(this.state.plannedDate).add(2, 'hours').format(),
            "board" : this.state.board,
            "sessions": {
                'SINGLE' : {
                    '@type': 'Session',
                    'sessionData': JSON.stringify({
                        "withVideo": this.state.withVideo,
                        "infoBubbleActive": this.state.infoBubbleActive.toString()
                    })
                }
            }
        };
        console.log(data);

        axios.put(
            process.env['REACT_APP_API'] + '/private/invitation/v1',
            data, {
                'headers': {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': token()
                }
            }
        ).then(() => {
            if (getPermissions('BigCompany', this.props) || getPermissions('Admin', this.props)) {
                this.methods.props.getUsers();
            }

            this.props.methods.getInvitationsAndStartWS();
            this.props.methods.setThisState(false);

        }).catch(error => {
            console.error(error.response);
            this.props.setData({
                error: error.response.data
            });
        });
    }

    editSession(e) {
        e.preventDefault();

        let invitationData = {};
        let sessions = (!!this.props.invitationData['sessions'] && !!this.props.invitationData['sessions']['SINGLE'] && !!this.props.invitationData['sessions']['SINGLE']['sessionData']) ? JSON.parse(this.props.invitationData['sessions']['SINGLE']['sessionData']) : null;

        invitationData.coacheeEmail = this.state.mail;
        invitationData.coacheeName = this.state.name;

        if (!!this.state.plannedDate) {
            invitationData.plannedDate = moment.utc(this.state.plannedDate).format();
        }

        invitationData.board = this.state.board;


        if (!!invitationData['sessions'] && !!invitationData['sessions']['SINGLE'] && !!invitationData['sessions']['SINGLE']['sessionData']) {
            invitationData['sessions']['SINGLE']['sessionData'] = JSON.stringify(Object.assign({}, sessions, {
                withVideo: this.state.withVideo,
                infoBubbleActive: this.state.infoBubbleActive
            }));
        } else {
            invitationData['sessions'] = {
                'SINGLE': {
                    '@type': 'Session',
                    'sessionData': JSON.stringify(Object.assign({}, sessions, {
                        withVideo: this.state.withVideo,
                        infoBubbleActive: this.state.infoBubbleActive
                    }))
                }
            };
        }
        console.log(invitationData, this.props.data);

        axios.patch(
            process.env['REACT_APP_API'] + '/private/invitation/v1/' + this.props.data.id,
            invitationData, {
                'headers': {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': token()
                }
            }
        ).then(() => {
            if (getPermissions('BigCompany', this.props) || getPermissions('Admin', this.props)) {
                this.methods.props.getUsers();
            }

            this.props.methods.getInvitationsAndStartWS();
            this.props.methods.setThisState(false);

        }).catch(error => {
            console.error(error.response);
            this.props.setData({
                error: error.response.data
            });
        });
    }

    render() {
        console.log(this.props.data, this.props.action);
        return (
            <div className={'addUser overlayBox'}>

                <Close className={'close'} onClick={() => this.props.methods.setThisState(false)} />

                <form onSubmit={(e) => (this.props.action === 'addSession') ? this.addSession(e) : this.editSession(e)} className={''}>
                    <h3>
                        {getTranslation(this, this.props.state.lang, (this.props.action === 'editSession' ? 'EditSession' : 'NewSession'))} {getFreeLinces(this.props, this.props.invitations) ? '(' + getTotalInvitations(this.props.invitations) + '/' + getUsedLicences(this.props) + ')' : '' }
                    </h3>

                    <div className={'box'}>
                        <input type={'text'} placeholder={getTranslation(this, this.props.state.lang, 'NameCoachee')} value={this.state.name} onChange={e => this.setState({name: e.target.value})} />
                        <input type={'mail'} placeholder={getTranslation(this, this.props.state.lang, 'Mail')} value={this.state.mail} onChange={e => this.setState({mail: e.target.value})} />
                    </div>

                    <h5>{getTranslation(this, this.props.state.lang, 'SessionDateTime')}</h5>

                    <div className={'box dateTime'}>
                        <input type={'date'} name={'plannedDate'} value={!!this.state.plannedDate ? moment.utc(this.state.plannedDate).format('YYYY-MM-DD') : ''} onChange={e => this.setState({plannedDate: moment.utc(e.target.value + (!!this.state.plannedDate ? ' ' + moment.utc(this.state.plannedDate).format('HH:mm') : '')).format('YYYY-MM-DDTHH:mm')})} />

                        <TimePicker name={'plannedDate'} value={!!this.state.plannedDate ? moment.utc(this.state.plannedDate) : null} placeholder={'--:--'} minuteStep={5} showSecond={false} onChange={value => this.setState({plannedDate: moment(value).format()})} />
                    </div>

                    <h5>{getTranslation(this, this.props.state.lang, 'Coachingtool')}</h5>

                    <div className={'boardType'}>
                        <label htmlFor={'withBalanceboard'}>
                            <input type={'checkbox'} id={'withBalanceboard'} name={'board'} value={'balanceboard'} checked={this.state.board === 'balanceboard'} onChange={e => this.setState({board: !!e.target.checked ? 'balanceboard' : 'false'})} />
                            {getTranslation(this, this.props.state.lang, 'BalanceboardActive')}
                        </label>
                    </div>

                    <h5>{getTranslation(this, this.props.state.lang, 'Settings')}</h5>

                    <div className={'settings'}>
                        <label htmlFor={'withVideo'}>
                            <input type={'checkbox'} id={'withVideo'} name={'video'} value={'withVideo'} checked={this.state.withVideo === 'true'} onChange={e => this.setState({withVideo: !!e.target.checked ? 'true' : 'false'})} />
                            {getTranslation(this, this.props.state.lang, 'SessionVideo')}
                        </label>

                        <label htmlFor={'infoBubble'}>
                            <input type={'checkbox'} id={'infoBubble'} name={'infoBubbleActive'} value={'true'} checked={this.state.infoBubbleActive === 'true'} onChange={e => this.setState({infoBubbleActive: !!e.target.checked ? 'true' : 'false'})} />
                            Sitzungshinweise anzeigen?
                        </label>
                    </div>

                    <div className={'buttonBox'}>
                        <button type={'button'} className={'button theme_color theme_bordercolor theme_background_hover'} onClick={() => this.props.methods.setThisState(false)}>
                            {getTranslation(this, this.props.state.lang, 'Cancel')}
                        </button>

                        <button className={'button theme_color theme_bordercolor theme_background_hover'}>
                            {getTranslation(this, this.props.state.lang, (this.props.action === 'editSession' ? 'SaveSession' : 'AddNewSession'))}
                        </button>
                    </div>

                </form>
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
                invitationData: state.invitationData
            }
        },
        {
            setData
        }
    )(
        AddSession
    )
);

// import './wdyr'; // <--- first import

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {Provider} from 'react-redux';

import store from './redux/store';

import * as Sentry from "@sentry/browser";

if (window.location.hostname !== "localhost" || window.location.hostname !== "127.0.0.1") {
    Sentry.init({
        dsn: "https://26ef7ee1fada4929801ea7e6b8b73981@o1037183.ingest.sentry.io/6004888",
        integrations: [
            new Sentry.Integrations.Breadcrumbs({
                console: false
            })
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../../assets/scss/balanceboard/cell.scss';
import {copySessions, getCellData} from "../helper";
import {connect} from "react-redux";

let current = 0;
let currentCoin = null;
let jeton;

class Cell extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current: getCellData(props, props.section, props.question)
        };

        this.onDrop = this.onDrop.bind(this);
        this.dragOver = this.dragOver.bind(this);
        this.onClick = this.onClick.bind(this);
    }


    onDrag(e) {
        currentCoin = e.target.getAttribute('dataquestion');

        jeton = e.target;
        jeton.classList.add('onDrag');
    }

    change() {
        let oldData = copySessions(this.props);
        if (!oldData['results']) {
            oldData['results'] = {};
        }
        if (!oldData['results'][this.props.section]) {
            oldData['results'][this.props.section] = {};
        }
        oldData['results'][this.props.section.replace(' ', '-')][this.props.question] = current;


        this.props.publish({sessions: {
            'SINGLE': {
                "@type":"Session",
                'sessionData': JSON.stringify(oldData)
            }
        }});
    }

    onDrop(e) {
        e.preventDefault();

        let cell = e.target.getAttribute('dataquestion');
        let elm = e.target;

        elm.classList.remove('highlight', 'background_hover_add', 'theme_highlight_before');

        jeton.classList.remove('onDrag');
        jeton = null;

        if (cell === currentCoin && !elm.classList.contains('notAvailable') && !elm.hasAttribute('disabled')) {
            this.change()
        }
    }

    dragOver(e) {
        e.preventDefault();

        let cell = e.target.getAttribute('dataquestion');
        let elm = e.target;

        if (cell === currentCoin && !elm.hasAttribute('disabled')) {
            elm.classList.add('highlight', 'background_hover_add');

            if (!!elm.getAttribute('elm')) {
                current = parseInt(elm.getAttribute('elm'));
            }
        }
    }

    dragLeave(e) {
        let elm = e.target;

        elm.classList.remove('highlight', 'background_hover_add');
    }

    onClick(e) {
        e.preventDefault();

        let cell = e.target.getAttribute('dataquestion');
        let elm = e.target;
        current = elm.getAttribute('elm');

        elm.classList.remove('highlight', 'background_hover_add', 'theme_highlight_before');

        if (!!cell && !elm.classList.contains('notAvailable') && !elm.hasAttribute('disabled')) {
            this.change();
        }
    }

    render() {

        let div;
        let tooltip;

        if ( this.props.elm === this.state.current) {
            div = React.createElement(
                'img',
                {
                    key: 'q_' + this.props.elm,
                    elm: this.props.elm,
                    className: 'jeton',
                    draggable: true,
                    onDragStart: this.onDrag,
                    dataquestion: this.props.question,
                    src: '../assets/img/coins/' + this.props.data.coin
                }
            )

            tooltip = React.createElement(
                'div',
                {
                    key: 't_' + this.props.elm,
                    elm: this.props.elm,
                    className: 'tooltip' + (this.props.elm > 0 ? ' left' : ''),
                    dataquestion: this.props.question,
                },
                this.props.data.question
            );
        }


        return React.createElement(
            'td',
            {
                elm: this.props.elm,
                className: 'cell' + (this.props.elm === 0 ? ' zero' : ' background_hover'),
                dataquestion: this.props.question,
                onDragOver: this.dragOver,
                onDragLeave: this.dragLeave,
                onDrop: this.onDrop,
                onClick: this.onClick,
                disabled: this.props.elm === 0 || this.props.disabled
            },
            [div, tooltip]
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.results !== this.props.results) {
            this.setState({
                current: getCellData(this.props, this.props.section, this.props.question)
            });
        }
    }
}


export default withRouter(
    connect(
        (state) => {
            return {
                results: state.sessions.results,
                sessions: state.sessions,
                userID: state.userID
            }
        },
        null
    )(
        Cell
    )
);

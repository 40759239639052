import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import '../../assets/scss/healthyworkingboard/topBox.scss';

// import {ReactComponent as User} from '../../assets/img/user.svg';


class TopBox extends Component {

    render() {
        return (
            <div className={'topBox'}>

                <div className={'section theme_color theme_bordercolor'}>
                    {this.props.section}
                </div>

            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        null
    )(
        TopBox
    )
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';
import moment from "moment";

import '../../assets/scss/admin/listItem.scss';

import {ReactComponent as Menu} from '../../assets/img/menu.svg';
import {getTranslation} from "../helper";

import PDF from './pdfDownload.class';
import PDFQRCode from './pdfQRCode.class';


class Listitem extends Component {

    copyLink(e, data) {
        e.preventDefault();
        let buttontext = e.target.innerText;

        let link = window.location.protocol + "//" + window.location.host + '/lobby/';
        if (data['board'] !== 'false') {
            let sessions = (!!data['sessions'] && !!data['sessions']['SINGLE'] && !!data['sessions']['SINGLE']['sessionData']) ? JSON.parse(data['sessions']['SINGLE']['sessionData']) : null;

            if ((!!sessions && (sessions.withVideo === false || sessions.withVideo === 'false')) || data['board'] === 'healthyworking') {
                link = window.location.protocol + "//" + window.location.host + '/lobby/' + data['board'] + '/start';
            }
        }

        let text = link + '?code=' + data['code']

        navigator.clipboard.writeText(text);

        e.target.innerText = "Link kopiert...";


        setTimeout(() => {
            e.target.innerText = buttontext;
        }, 4000);
    }

    render() {
        const {data, index} = this.props;
        let key = index+1;

        let sessions = (!!data['sessions'] && !!data['sessions']['SINGLE'] && !!data['sessions']['SINGLE']['sessionData']) ? JSON.parse(data['sessions']['SINGLE']['sessionData']) : null;

        let link = '/lobby/';
        if (data['board'] !== 'false') {
            if ((!!sessions && (!sessions.withVideo || sessions.withVideo === 'false'))) {
                link = '/lobby/' + data['board'] + '/start';
            }

            if (data['board'] === 'healthyworking') {
                link = '/lobby/' + data['board'] + '/auswertung';
            }
        }

        let pdf;
        let section = 'session';

        if (data['board'] !== 'false') {
            if (!!sessions && !!sessions['results'] && !!sessions['results']['downloadAllowed'] && data['board'] === 'balanceboard') {
                pdf = <PDF invitationData={data} sessions={sessions}/>;
            } else if (data['board'] === 'healthyworking') {
                pdf = <PDFQRCode invitationData={data} />;
                section = 'healthyWorking';
            } else {
                pdf = '-';
            }
        } else {
            pdf = '-';
        }



        return (
            <div className={'listItem'}>
                <div className={'number'}>
                    {key}
                </div>
                <div className={'date'}>
                    {!!data['plannedDate'] ? moment(data['plannedDate']).utc().format('DD.MM.YYYY') : '-'}
                </div>
                <div className={'time'}>
                    {!!data['plannedDate'] ? moment(data['plannedDate']).utc().format('HH:mm') : '-'}
                </div>
                <div className={'name'}>
                    {!!data['coacheeName'] ? data['coacheeName'] : '-'}
                </div>
                <div className={'mail'}>
                    {data['coacheeEmail']}
                </div>
                <div className={'code'}>
                    {data['code']}
                </div>
                <div className={'state'}>
                    {
                        data['board'] === 'healthyworking' ?
                            <Link to={link + '?code=' + data['code']} target={'_blank'} className={'active button theme_color theme_bordercolor theme_background_hover'}>{getTranslation(this, this.props.state.lang, 'Active')}</Link>
                        :
                        <>
                            {data['invitationState'] === 'Sent' &&
                                <button className={'new'}>{getTranslation(this, this.props.state.lang, 'Invited')}</button>
                            }
                            {data['invitationState'] === 'New' &&
                                <button className={'new'}>{getTranslation(this, this.props.state.lang, 'New')}</button>
                            }
                            {data['invitationState'] === 'Waiting' &&
                                <Link to={link + '?code=' + data['code']} target={'_blank'} className={'waiting theme_shadow button theme_color theme_bordercolor theme_background_hover'}>{getTranslation(this, this.props.state.lang, 'Waiting')}</Link>
                            }
                            {data['invitationState'] === 'Joined' &&
                                <Link to={link + '?code=' + data['code']} target={'_blank'} className={'joined button theme_color theme_bordercolor theme_background_hover'}>{getTranslation(this, this.props.state.lang, 'CoachJoined')}</Link>
                            }
                            {data['invitationState'] === 'Active' &&
                                <Link to={link + '?code=' + data['code']} target={'_blank'} className={'active button theme_color theme_bordercolor theme_background_hover'}>{getTranslation(this, this.props.state.lang, 'Active')}</Link>
                            }
                            {data['invitationState'] === 'Closed' &&
                                <button className={'new'}>{getTranslation(this, this.props.state.lang, 'SessionEnded')}</button>
                            }
                        </>
                    }

                </div>
                <div className={'video'}>
                    {(!!sessions && !!sessions.withVideo && sessions.withVideo === 'true')
                        ?
                        <span className={'material-icons'} title={'Sitzung mit Video'}>video_call</span>
                        :
                        <span className={'material-icons'} title={'Sitzung ohne Video'}>videocam_off</span>
                    }
                </div>
                <div className={'board'}>
                    {data.board === 'balanceboard' &&
                        <span className={'material-icons-sharp'} title={'Balanceboard'}>grid_view</span>
                    }

                    {data.board === 'healthyworking' &&
                        <span className={'material-icons icon--rotated'} title={'Gesundes Arbeiten'}>analytics</span>
                    }

                    {data.board === 'false' &&
                        <span>-</span>
                    }
                </div>
                <div className={'pdf'}>
                    {pdf}
                </div>
                <div className={'options'}>
                    <Menu />
                    <ul>
                        {(data.invitationState !== 'Closed') &&
                            <li className={'theme_background_hover'} onClick={() => this.props.methods.setSessions(section,'editSession', data)}>{getTranslation(this, this.props.state.lang, 'Edit')}</li>
                        }
                        {(data.invitationState === 'Closed') &&
                            <li className={'theme_background_hover'} onClick={() => this.props.methods.setSessions('session','addSession', data)}>{getTranslation(this, this.props.state.lang, 'Renew')}</li>
                        }
                        <li className={'theme_background_hover'} onClick={() => this.props.methods.deleteSession(data['code'])}>{getTranslation(this, this.props.state.lang, 'Delete')}</li>
                        <li className={'theme_background_hover'} onClick={e => this.copyLink(e, data)}>Einladungslink kopieren</li>

                        {((data.invitationState === 'New' || data.invitationState === 'Sent') && !!data['coacheeEmail']) &&
                            <li className={'theme_background_hover'} onClick={() => this.props.methods.setPopup('invite', data['id'])}>{getTranslation(this, this.props.state.lang, 'Resend')}</li>
                        }
                    </ul>
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
            }
        },
        null
    )(
        Listitem
    )
);

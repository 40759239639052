import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../../assets/scss/healthyworkingboard/cell.scss';
import {connect} from "react-redux";
import {copySessions, getBGMCellData, getImportantBGMCellData, getPositiveBGMCellData} from "../helper";

let current = 0;

class CellDiv extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current: getBGMCellData(props, props.section, props.question),
            important: getImportantBGMCellData(props, props.section, props.question),
            positive: getPositiveBGMCellData(props, props.section, props.question),
        };

        this.onClick = this.onClick.bind(this);
    }

    change() {
        let oldData = copySessions(this.props);
        if (!oldData['results']) {
            oldData['results'] = {};
        }
        if (!oldData['results'][this.props.section]) {
            oldData['results'][this.props.section] = {};

            oldData['results'][this.props.section]['answers'] = {};
            oldData['results'][this.props.section]['important'] = [];
            oldData['results'][this.props.section]['positive'] = [];
        }
        oldData['results'][this.props.section]['answers'][this.props.question] = current;

        this.props.publish({sessions: {
            [this.props.userID]: {
                "@type":"Session",
                'sessionData': JSON.stringify(oldData)
            }
        }});
    }

    setAttention(type) {
        let oldData = copySessions(this.props);
        if (!oldData['results']) {
            oldData['results'] = {};
        }

        if (!oldData['results'][this.props.section]) {
            oldData['results'][this.props.section] = {};

            oldData['results'][this.props.section]['answers'] = {};
        }

        if (!oldData['results'][this.props.section][type]) {
            oldData['results'][this.props.section][type] = [];
        }

        if (type === 'important' && oldData['results'][this.props.section]['positive'].includes(this.props.question)) {
            oldData['results'][this.props.section]['positive'] = oldData['results'][this.props.section]['positive'].filter(el => el !== this.props.question);
        } else if (type === 'positive' && oldData['results'][this.props.section]['important'].includes(this.props.question)) {
            oldData['results'][this.props.section]['important'] = oldData['results'][this.props.section]['important'].filter(el => el !== this.props.question);
        }

        if (type !== '') {
            oldData['results'][this.props.section][type].push(this.props.question);
        } else {
            oldData['results'][this.props.section]['positive'] = oldData['results'][this.props.section]['positive'].filter(el => el !== this.props.question);
            oldData['results'][this.props.section]['important'] = oldData['results'][this.props.section]['important'].filter(el => el !== this.props.question);
        }

        this.props.publish({sessions: {
            [this.props.userID]: {
                "@type":"Session",
                'sessionData': JSON.stringify(oldData)
            }
        }});
    }

    onClick(e, type) {
        e.preventDefault();

        let cell = e.target.getAttribute('dataquestion');
        let elm = e.target;
        current = elm.getAttribute('elm');

        elm.classList.remove('highlight', 'background_hover_add', 'theme_highlight_before');

        if (!!cell && !elm.classList.contains('zero')) {
            this.change();
        } else if (!elm.classList.contains('disabled')) {
            this.setAttention(type);
        }
    }


    render() {
        let path = this.props.location.pathname;

        let content = [];
        let classList = '';
        let disabled = true;
        let clickType = '';

        if (this.props.elm === 0) {
            if (path.indexOf('/rating') && this.state.positive && !this.state.important) {
                content.push(
                    React.createElement(
                        'path',
                        {
                            key: 'path2',
                            className: 'index_text',
                            textAnchor: 'middle',
                            alignmentBaseline: 'central',
                            dominantBaseline: 'middle',
                            fill: '#999',
                            transform: "translate(8, 8)",
                            d:'M13.12 2.06L7.58 7.6c-.37.37-.58.88-.58 1.41V19c0 1.1.9 2 2 2h9c.8 0 1.52-.48 1.84-1.21l3.26-7.61C23.94 10.2 22.49 8 20.34 8h-5.65l.95-4.58c.1-.5-.05-1.01-.41-1.37-.59-.58-1.53-.58-2.11.01zM3 21c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2s-2 .9-2 2v8c0 1.1.9 2 2 2z'
                        }
                    )
                );
                clickType = 'important';
                disabled = false;

                if (this.props.elm === 0 && !!this.props.allPositive && this.props.allPositive >= 5 && !this.state.positive) {
                    classList += ' disabled';
                }

                if (this.props.elm === 0 && this.state.positive) {
                    classList += ' current';
                }
                classList += ' positive';
            } else if (path.indexOf('/rating') && !this.state.positive && this.state.important) {
                content.push(
                    React.createElement(
                        'path',
                        {
                            key: 'path2',
                            className: 'index_text',
                            textAnchor: 'middle',
                            alignmentBaseline: 'central',
                            dominantBaseline: 'middle',
                            fill: '#999',
                            transform: "translate(8, 9)",
                            d:'M10.88 21.94l5.53-5.54c.37-.37.58-.88.58-1.41V5c0-1.1-.9-2-2-2H6c-.8 0-1.52.48-1.83 1.21L.91 11.82C.06 13.8 1.51 16 3.66 16h5.65l-.95 4.58c-.1.5.05 1.01.41 1.37.59.58 1.53.58 2.11-.01zM21 3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2s2-.9 2-2V5c0-1.1-.9-2-2-2z'
                        }
                    )
                );
                clickType = '';
                disabled = false;

                if (!!this.props.allImportants && this.props.allImportants >= 5 && !this.state.important) {
                    classList += ' disabled';
                }

                if (this.props.elm === 0 && this.state.important) {
                    classList += ' current';
                }
                classList += ' important';
            } else {
                content.push(React.createElement(
                    'text',
                    {
                        key: 'index_text',
                        className: 'index_text',
                        x:'50%',
                        y:'50%',
                        textAnchor: 'middle',
                        alignmentBaseline: 'central',
                        dominantBaseline: 'middle',
                        fill: '#999',
                        fontSize: 16,
                    },
                    '!'
                ));

                if (path.indexOf('/rating') > -1 && ((!!this.props.allImportants && this.props.allImportants >= 5 && !this.state.important) || (!!this.props.allPositive && this.props.allPositive >= 5 && !this.state.positive))) {
                    classList += ' disabled';
                    disabled = true;
                } else if (path.indexOf('/rating') === -1) {
                    classList += ' disabled';
                    disabled = true;
                }

                clickType = 'positive';
            }
        } else {
            content.push(React.createElement(
                'text',
                {
                    key: 'index_text',
                    className: 'index_text',
                    x:'50%',
                    y:'50%',
                    textAnchor: 'middle',
                    alignmentBaseline: 'central',
                    dominantBaseline: 'middle',
                    fill: '#999',
                    fontSize: 16,
                },
                this.props.elm
            ));
        }


        let inner = React.createElement(
            'svg',
            {
                key: 'index_',
                className: 'index',
                width:'100%',
                height:'100%'
            },
            content
        )


        if (this.props.area === 'q' && this.props.elm === 0) {
            classList +=' zero';
        }

        if (this.props.area === 'tm' && (this.props.elm / 5 % 2 === 1)) {
            classList += ' small background_hover_before';
        } else if (this.props.elm === 0 && this.props.type === 'normal') {

        } else {
            classList += ' background_hover';
        }

        if (this.props.elm !== 0 && this.props.elm === this.state.current) {
            classList += ' current';
        }

        return React.createElement(
            'div',
            {
                elm: this.props.elm,
                className: 'cellDiv' + classList,
                dataquestion: this.props.question,
                onClick: (e) => this.onClick(e, clickType),
                disabled: disabled
            },
            [
                inner
            ]
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.results !== this.props.results) {
            this.setState({
                current: getBGMCellData(this.props, this.props.section, this.props.question),
                important: getImportantBGMCellData(this.props, this.props.section, this.props.question),
                positive: getPositiveBGMCellData(this.props, this.props.section, this.props.question),
            });
        }
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                results: state.sessions.results,
                sessions: state.sessions,
                userID: state.userID
            }
        },
        null
    )(
        CellDiv
    )
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import _ from 'lodash';

import '../../assets/scss/balanceboard/results.scss';
import TopBox from "./topBox.class";

import {copySessions} from "../helper";

import PdfDownload from "./pdfDownload.class";

// import { PDFViewer } from '@react-pdf/renderer';
// import {PdfDocument} from "./pdf";
import marked from "../markdown";
import moment from "moment";

let timer = null;

class Results extends Component {

    constructor(props) {
        super(props);

        this.state = {
            received: !!props.sessions['results']['received'] ? props.sessions['results']['received'] : '',
            willDo: !!props.sessions['results']['willDo'] ? props.sessions['results']['willDo'] : '',
            downloadAllowed: !!props.sessions['results']['downloadAllowed'] ? props.sessions['results']['downloadAllowed'] : false
        };

        this.change = this.change.bind(this);
    }

    change(target, e) {
        if (!!timer) {
            clearTimeout(timer);
        }

        let name = target.name;
        let value = target.name === 'downloadAllowed' ? target.checked : target.value;
        let self = this;

        this.setState({
            [name]: value
        }, () => {
            timer = setTimeout(() => {
                let oldData = copySessions(this.props);
                if (!oldData['results']) {
                    oldData['results'] = {};
                }
                if (!oldData['results'][this.props.section]) {
                    oldData['results'][this.props.section] = {};
                }
                oldData['results'][name] = value;

                self.props.publish({sessions: {
                    'SINGLE': {
                        "@type":"Session",
                        'sessionData': JSON.stringify(oldData)
                    }
                }});
            }, 2000);
        })
    }

    render() {

        if (!!this.props.state['Auswertung']) {

            let downloadable = this.props.state['Auswertung'][this.props.state.lang]['DownloadErlaubt'];
            let text = !!this.props.state.Start[this.props.state.lang] ? this.props.state.Start[this.props.state.lang]['Unterueberschrift'] + '<br />COACHEE: ' + (!!this.props.invitationData.coacheeName ? this.props.invitationData.coacheeName : 'Coachee') + ' | ' + this.props.state['Auswertung'][this.props.state.lang]['Date'] + ': ' + moment(this.props.invitationData['plannedDate']).format('DD.MM.YYYY') + ' | COACH: ' + this.props.invitationData['coach']['name'] : '';

            return (
                <div className={'results'}>

                    <TopBox section={this.props.state['Auswertung'][this.props.state.lang]['Ueberschrift']} setLanguage={this.props.setLanguage} />

                    <div className={'subheadline'} dangerouslySetInnerHTML={{__html: (!!text ? marked(text) : '')}} />

                    <div className={'form'}>

                        <label htmlFor={'received'}>
                            {this.props.state['Auswertung'][this.props.state.lang]['Text_1']}
                        </label>

                        <textarea className={''} id={'received'} name={'received'} rows={3} value={this.state.received} onChange={e => this.change(e.target)} />


                        <label htmlFor={'willDo'}>
                            {this.props.state['Auswertung'][this.props.state.lang]['Text_2']}
                        </label>

                        <textarea className={''} id={'willDo'} name={'willDo'} rows={3} value={this.state.willDo} onChange={e => this.change(e.target)} />


                        <div className={'buttonBox'}>
                            <PdfDownload downloadAllowed={this.state.downloadAllowed} downloadable={downloadable} publish={this.props.publish} />
                        </div>


                    </div>

                    {/*{this.state.downloadAllowed &&*/}
                    {/*    <PDFViewer style={{width:'100%', height:'500px'}}>*/}
                    {/*        <PdfDocument data={this.props} />*/}
                    {/*    </PDFViewer>*/}
                    {/*}*/}

                </div>
            );
        } else {
            return <></>;
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.sessions.results,this.props.sessions.results)) {
            this.setState({
                received: !!this.props.sessions.results.received ? this.props.sessions.results.received : '',
                willDo: !!this.props.sessions.results.willDo ? this.props.sessions.results.willDo : '',
                downloadAllowed: !!this.props.sessions.results.downloadAllowed ? this.props.sessions.results.downloadAllowed : false
            })
        }
    }

    componentDidMount() {
        if (this.props['infoBubble']) {
            this.props.toggleBlur({
                section: this.props.section.replace(' ', '-'),
                data: this.props['infoBubble']
            });
        }
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                sessions: state.sessions,
                invitationData: state.invitationData
            }
        },
        null
    )(
        Results
    )
);

import React, {Component} from 'react';
import {withRouter, Link, Switch, Route} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import {connect} from "react-redux";
import queryString from "query-string";

import PdfDownload from "./pdfDownload.class";

import {
    clearUrl, getAllImportants, getAllPositive,
    getPermissions, getQuestionsWithResults,
    getTranslation,
    logout, sumAllQuestionResults, setQuestionResults
} from "../helper";
import {setData} from "../../redux/actions";

import Form from './form.class';
import Start from './start.class';
import Modal from "../modal.class";

import '../../assets/scss/healthyworkingboard/forms.scss';

import {ReactComponent as Home} from '../../assets/img/home.svg';
import {ReactComponent as Menu} from '../../assets/img/menu.svg';
import {ReactComponent as BurgerMenu} from '../../assets/img/burgermenu.svg';
import ScrollToTop from "./scrollToTop.class";


function LinkTo(props) {
    const resetMenuState = () => {
        let link = document.querySelector('.normalMenu li.current');

        if (!!link) {
            link.classList.remove('current');
        }

        props.unobserve();

        if (!!props.toggleMenu) {
            props.toggleMenu();
        }
    }

    let link;
    if (!!props.tohome) {
        link =
            <Link to={props.to} className={props.className} onClick={() => resetMenuState()}>
                {props.text}
            </Link>
    } else if (!!props.home) {
        link =
            <Link to={props.to} className={props.className} onClick={() => resetMenuState()}>
                <Home width={20} className={'theme_svg'} />
                <span>{props.text}</span>
            </Link>
    } else {
        link = <HashLink smooth to={props.disabled ? '#' : props.to + (!!props.search ? props.search : '')} className={props.className} onClick={() => !!props.toggleMenu ? props.toggleMenu() : null}>{props.name}</HashLink>
    }

    return link;

}


class Forms extends Component {

    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
            logout: false,
            rendered: false,

            sorting: '',
            sortDirection: ''
        };

        this.params = queryString.parse(this.props.location.search);

        this.toggleMenu = this.toggleMenu.bind(this);
        this.close = this.close.bind(this);
        this.callObserver = this.callObserver.bind(this);

        this.observer = null;

        this.unobserve = this.unobserve.bind(this);
    }

    close() {
        if (getPermissions('Coach', this.props)) {
            this.props.publish({
                invitationState: 'Closed'
            });

            window.close();
        } else {
            logout(this.props);
        }
    }

    getCalc(_location) {
        let location = _location.replace('lobby/healthyworking/','');

        let hasZero = 100;
        let sum = 100;

        let question = this.props.state.questions[this.props.state.lang][location];

        if (!!this.props.sessions['results'][location] && Object.keys(this.props.sessions['results'][location]).length === Object.keys(question).length) {
            hasZero = Object.keys(this.props.sessions['results'][location]).filter(e => this.props.sessions['results'][location][e] === 0).length;
        }

        return sum === 100 && hasZero === 0;
    }

    getQuestionResults(section, location) {
        let results = getQuestionsWithResults(this.props, section, location);

        if (!!results && !!results[location]) {
            results = results[location];

            return (!!results && results.questions === results.answered);
        } else {
            return false;
        }
    }

    getTimeMeaningResults(location) {
        let results = getQuestionsWithResults(this.props, null, location);

        if (!!results) {
            return results.total === 100;
        } else {
            return false;
        }
    }

    toggleMenu() {
        this.setState({menuOpen: !this.state.menuOpen})
    }

    callObserver() {
        this.observe();
    }

    observe() {
        if (this.state.rendered === false) {
            this.setState({
                rendered: true
            }, () => {
                let sections = document.querySelectorAll('.toObserve');
                let allLinks = document.querySelectorAll('.link')

                if ('IntersectionObserver' in window) {
                    // IntersectionObserver Supported
                    let config = {
                        root: document.querySelector('.content'),
                        rootMargin: '0px',
                        threshold: 0.1
                    };

                    this.observer = new IntersectionObserver((changes) => {
                        changes.forEach(change => {
                            if (change.isIntersecting) {
                                // Stop watching and load the image
                                let selector = document.querySelector('.link[dataref="' + change.target.id + '"]');

                                allLinks.forEach(link => link.classList.remove('current'));

                                if (!!selector) {
                                    selector.classList.add('current');
                                }
                            }
                        });
                    }, config);

                    sections.forEach(section => this.observer.observe(section));
                }
            })
        }
    }

    unobserve() {
        let sections = document.querySelectorAll('.toObserve');
        sections.forEach(section => this.observer.unobserve(section));

        this.setState({
            rendered: false
        });
    }

    sort(field) {
        if (this.state.sorting === field) {
            if (this.state.sortDirection === 'asc') {
                this.setState({
                    sorting: field,
                    sortDirection: 'desc'
                });
            } else if (this.state.sortDirection === 'desc') {
                this.setState({
                    sorting: '',
                    sortDirection: ''
                });
            }
        } else {
            this.setState({
                sorting: field,
                sortDirection: 'asc'
            });
        }
    }

    render() {

        let code;
        if (!!this.params['code']) {
            code = '?code=' + this.params['code'];
        }


        let links = [];

        if (!!this.props.questionData && !!this.props.questionData.questions && this.props.questionData.questions.length > 0) {
            this.props.questionData.questions.forEach((v) => {
                links.push(
                    <li key={v['category']} className={'link theme_bordercolor_before ' + (clearUrl(this.props) === '/' + v['category'] ? 'current ' : '')} dataref={v['category']}>
                        <LinkTo {...this.props} to={'/lobby/healthyworking/section/#' + v['category']} search={code} className={'theme_color_hover ' + (clearUrl(this.props) === v['category'] ? 'theme_color ' : '')} name={v['category']} toggleMenu={() => this.toggleMenu()} unobserve={this.unobserve} />
                    </li>
                )
            })
        }


        let link = this.props.state.config['Link'];
        if (!!this.props.questionData && !!this.props.questionData['url']) {
            link = this.props.questionData['url'];
        } else if (!!this.props.state.invitationData && !!this.props.state.invitationData['coach'] && !!this.props.state.invitationData['coach']['website']) {
            link = this.props.state.invitationData['coach']['website'];
        } else if (!!this.props.state.invitationData['coach']['avatar']) {
            link = '';
        }


        let results = sumAllQuestionResults(this.props.sessions);
        setQuestionResults(this.props.questionData, results);

        let logo;
        if (!!this.props.questionData && !!this.props.questionData['logo']) {
             logo = this.props.questionData['logo'];
        } else if (!!this.props.state.invitationData && !!this.props.state.invitationData['coach'] && !!this.props.state.invitationData['coach']['avatar']) {
            logo = this.props.state.invitationData['coach']['avatar'];
        } else {
            logo = this.props.state.config['Logo'];
        }

        let allQuestionsData;
        if (!!this.props.questionData && this.props.questionData.questions && this.props.questionData.questions.length) {
            let allQuestions = [];
            this.props.questionData.questions.forEach((d, k) => {
                const res = !!results.allResults[k] ? results.allResults[k] : null;

                d.questions.forEach((e, s) => {
                    e.results = !!res ? res[s] : 0;
                    allQuestions.push(e);
                })
            });

            allQuestionsData = {
                category: '',
                enabled: true,
                questions: allQuestions,
            };
        }

        let allImportants = getAllImportants(results.allImportants);
        let allPositive = getAllPositive(results.allPositives);

        return (
            <div className={'forms'}>

                <ScrollToTop />

                <div className={'navigation'}>
                    <div className={'logo theme_bordercolor'}>
                        <span style={{backgroundImage: 'url(' + logo + ')'}} />
                    </div>


                    <ul className={'coachmenu' + (getPermissions('Coach', this.props) ? ' coach' : ' coachee')}>
                        <li className={'space' + (getPermissions('Coach', this.props) ? ' coach' : ' coachee')} />

                        {!getPermissions('Coach', this.props) &&
                            <li className={'results' + (getPermissions('Coach', this.props) ? ' coach' : ' coachee')}>
                                <div className={'resultsText'}>MITARBEITERBEFRAGUNG</div>
                               <div className={'sessionData'}>{(!!this.props.invitationData.coacheeName ? this.props.invitationData.coacheeName : ' - ')}</div>
                            </li>
                        }
                        {getPermissions('Coach', this.props) &&
                            <>
                                <li className={'results' + (getPermissions('Coach', this.props) ? ' coach' : ' coachee')}>
                                    <LinkTo to={'/lobby/healthyworking/auswertung'} search={code} className={'theme_color_hover ' + (clearUrl(this.props) === '/lobby/healthyworking/auswertung/' ? 'theme_color ' : '')} name={'Kategorisiert'} toggleMenu={() => this.toggleMenu()} unobserve={this.unobserve} />
                                </li>
                                <li className={'results' + (getPermissions('Coach', this.props) ? ' coach' : ' coachee')}>
                                    <LinkTo to={'/lobby/healthyworking/auswertung/alle'} search={code} className={'theme_color_hover ' + (clearUrl(this.props) === '/lobby/healthyworking/auswertung/alle' ? 'theme_color ' : '')} name={'Alle Fragen'} toggleMenu={() => this.toggleMenu()} unobserve={this.unobserve}  />
                                </li>
                            </>
                        }
                    </ul>

                    <BurgerMenu className={'mobilemenu theme_background'} onClick={() => this.toggleMenu()} />

                    <ul className={'normalMenu' + (getPermissions('Coach', this.props) ? ' coach' : ' coachee') + (this.state.menuOpen ? ' visible' : '')}>
                        {!getPermissions('Coach', this.props) &&
                            <>
                                <li className={'home'}>
                                    <LinkTo to={'/lobby/healthyworking/start'} search={code} name={'Home'} props={this.props} home={true} text={getTranslation(this, this.props.state.lang, 'Start')} toggleMenu={() => this.toggleMenu()} unobserve={this.unobserve} />
                                </li>
                                {links}
                            </>
                        }

                        <li className={'menu theme_hover'}>
                            <Menu />

                            <ul>
                                <li>
                                    <a href={'#logout'} onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({logout: !this.state.logout})
                                    }} className={'theme_color_hover'}>
                                        {getTranslation(this, this.props.state.lang, 'EndSession')}
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <Modal button={{
                        title: getTranslation(this, this.props.state.lang, 'Hint'),
                        text: getTranslation(this, this.props.state.lang, 'EndSessionText'),
                        className: 'theme_color_hover',
                        id: 'btnStart',
                        confirm: () => this.close(),
                        changeState: () => {
                            this.setState({
                                logout: false
                            });
                        },
                        button: getTranslation(this, this.props.state.lang, 'EndSession'),
                        hideButton: true,
                        state: this.state.logout
                    }} />
                </div>

                <div className={'horizontalShadow'} />

                <div className={'content'}>

                    <Route path={'/lobby/healthyworking/section'} key={'section_labels'}>
                        <div className={'mobileLabels'}>
                            <div className={'noMatch'}>
                                Trifft für mich<br /> eher nicht zu
                            </div>

                            <div className={'actionRequired'}>
                                Größter Handlungsbedarf<br /> (max. 3 Punkte)
                            </div>

                            <div className={'match'}>
                                Trifft für mich<br /> eher zu
                            </div>
                        </div>
                    </Route>

                    <Switch>
                        <Route path={['/lobby/healthyworking', '/lobby/healthyworking/start']} exact key={'start'}>
                            <Start />
                        </Route>

                        <Route path={'/lobby/healthyworking/section'} key={'section'}>
                            {
                                !!this.props.questionData.questions && this.props.questionData.questions.length > 0 && this.props.questionData.questions.map((v, k) => {
                                    return (
                                        <section className={'toObserve'} id={v.category} key={'question_' + k}>
                                            <Form key={v.category} dataKey={k} publish={this.props.publish} data={v} callObserver={this.callObserver} show={'options'} results={null} importants={null} allImportants={allImportants} positive={null} allPositive={allPositive} />
                                        </section>
                                    );
                                })
                            }
                        </Route>

                        {getPermissions('Coach', this.props) &&
                            <Route path={['/lobby/healthyworking/auswertung', '/lobby/healthyworking/auswertung/alle']} key={'results'}>

                                <div className={'sortingLabels'}>
                                    <div className={'label'} onClick={() => this.sort('important')}>
                                        <div>
                                            Trifft nicht zu
                                        </div>
                                        <div className={'sorting'}>
                                            {this.state.sorting === 'important' ? (this.state.sortDirection === 'asc' ? 'Aufsteigend' : 'Absteigend') : ' '}
                                        </div>

                                        <div className={'sortBox ' + (this.state.sorting === 'important' ? this.state.sortDirection : '')} />
                                    </div>

                                    <div className={'label'}>
                                        <div>
                                            Handlungsbedarf
                                        </div>
                                        <div className={'sorting'}>
                                            {this.state.sorting === 'allImportants' ? (this.state.sortDirection === 'asc' ? '- Aufsteigend' : '- Absteigend') : ' '}
                                            {this.state.sorting === 'allPositives' ? (this.state.sortDirection === 'asc' ? '+ Aufsteigend' : '+ Absteigend') : ' '}
                                        </div>

                                        <div className={'sortings'}>
                                            <div className={'sortBox ' + (this.state.sorting === 'allImportants' ? this.state.sortDirection : '')} onClick={() => this.sort('allImportants')} />

                                            <div className={'sortBox ' + (this.state.sorting === 'allPositives' ? this.state.sortDirection : '')} onClick={() => this.sort('allPositives')} />
                                        </div>
                                    </div>

                                    <div className={'label'} onClick={() => this.sort('positive')} >
                                        <div>
                                            Trifft zu
                                        </div>
                                        <div className={'sorting'}>
                                            {this.state.sorting === 'positive' ? (this.state.sortDirection === 'asc' ? 'Aufsteigend' : 'Absteigend') : ' '}
                                        </div>

                                        <div className={'sortBox ' + (this.state.sorting === 'positive' ? this.state.sortDirection : '')}/>
                                    </div>
                                </div>

                                <Route path={'/lobby/healthyworking/auswertung'} exact={true} key={'results_categories'}>
                                    {
                                        !!this.props.questionData.questions && this.props.questionData.questions.length > 0 && this.props.questionData.questions.map((v, k) => {
                                            return (
                                                <section className={'toObserve'} id={v.category} key={'question_' + k}>
                                                    <Form key={v.category} dataKey={k} publish={this.props.publish} data={v} callObserver={this.callObserver} show={'results'} hideLabelBar={true} sorting={this.state.sorting} sortDirection={this.state.sortDirection} />
                                                </section>
                                            );
                                        })
                                    }
                                </Route>
                                <Route path={'/lobby/healthyworking/auswertung/alle'} exact={true} key={'results_all'}>
                                    {
                                        !!allQuestionsData && allQuestionsData.questions.length > 0 && (
                                            <section className={'toObserve'} id={allQuestionsData.category} key={'question_all'}>
                                                <Form key={allQuestionsData.category} dataKey={null} publish={this.props.publish} data={allQuestionsData} callObserver={this.callObserver} show={'results'} hideLabelBar={true} sorting={this.state.sorting} sortDirection={this.state.sortDirection} />
                                            </section>
                                        )
                                    }
                                </Route>

                                {/*<PDFViewer style={{width:'100%', height:'500px'}}>*/}
                                {/*    <PdfDocument data={this.props} />*/}
                                {/*</PDFViewer>*/}
                            </Route>
                        }
                    </Switch>
                </div>

                <div className={'horizontalShadow'} />

                <div className={'footer'}>

                    <div className={'balanceAktiveLogo'} style={{backgroundImage: 'url(/assets/img/balance_aktiv.png)'}} />

                    <div className={'centerBox'}>
                        {!getPermissions('Coach', this.props) &&
                            <>
                                <Route path={'/lobby/healthyworking/start'} key={'home'}>
                                    <LinkTo className={'btn prev disable'} disabled={true} to={'/lobby/healthyworking/start'} search={code} name={getTranslation(this, this.props.state.lang, 'Back')} props={this.props} text={getTranslation(this, this.props.state.lang, 'Back')} unobserve={this.unobserve} />

                                    <LinkTo className={'btn next theme_background_hover'} to={'/lobby/healthyworking/section'} search={code} name={getTranslation(this, this.props.state.lang, 'Next')} props={this.props} text={getTranslation(this, this.props.state.lang, 'Next')} unobserve={this.unobserve} />
                                </Route>

                                <Route path={'/lobby/healthyworking/section'} exact key={'section'}>
                                    <LinkTo tohome={true} className={'btn prev theme_background_hover'} to={'/lobby/healthyworking/start'} search={code} name={getTranslation(this, this.props.state.lang, 'Back')} props={this.props} text={getTranslation(this, this.props.state.lang, 'Back')} unobserve={this.unobserve} />

                                    <LinkTo className={'btn next theme_background_hover'} to={'/lobby/healthyworking/section/rating#Arbeitsinhalt/-Aufgabe'} search={code} name={getTranslation(this, this.props.state.lang, 'Next')} props={this.props} text={getTranslation(this, this.props.state.lang, 'Next')} unobserve={this.unobserve} />
                                </Route>

                                <Route path={'/lobby/healthyworking/section/rating'} key={'section_positive'}>
                                    <LinkTo tohome={true} className={'btn prev theme_background_hover'} to={'/lobby/healthyworking/section#Arbeitsinhalt/-Aufgabe'} search={code} name={getTranslation(this, this.props.state.lang, 'Back')} props={this.props} text={getTranslation(this, this.props.state.lang, 'Back')} unobserve={this.unobserve} />

                                    <Modal button={{
                                        title: getTranslation(this, this.props.state.lang, 'Hint'),
                                        text: getTranslation(this, this.props.state.lang, 'EndSessionText'),
                                        className: 'btn next theme_background_hover',
                                        id: 'btnStart',
                                        confirm: () => this.close(),
                                        button: getTranslation(this, this.props.state.lang, 'EndSession')
                                    }} />
                                </Route>
                            </>
                        }

                        {getPermissions('Coach', this.props) &&
                            <>
                                <PdfDownload />

                                <Modal button={{
                                    title: getTranslation(this, this.props.state.lang, 'Attention'),
                                    text: getTranslation(this, this.props.state.lang, 'EndSessionText'),
                                    className: 'btn next theme_background_hover',
                                    id: 'btnStart',
                                    confirm: () => window.close(),
                                    button: getTranslation(this, this.props.state.lang, 'EndSession')
                                }} />
                            </>
                        }
                    </div>

                    <div className={'logo'}>
                        {!!link
                            ?
                                <a href={link} target={'_blank'} rel="noreferrer" className={'theme_bordercolor'}>
                                    <img src={logo} alt={'Logo'} />
                                </a>
                            :
                                <span style={{backgroundImage: 'url(' + logo + ')'}} />
                        }
                    </div>

                </div>

            </div>
        );
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.observe();
        }
    }

}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
                sessions: state.sessions,
                questionData: state.questionData,
                invitationData: state.invitationData
            }
        },
        {
            setData
        }
    )(
        Forms
    )
);

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../../assets/scss/balanceboard/form.scss';
import Cell from "./cellDiv.class";
import {connect} from "react-redux";
import {setData} from "../../redux/actions";
import TopBox from "./topBox.class";
import marked from "../markdown";
import {getCellData} from "../helper";

import Slider from './slider.class';

const CellFunc = (props) => {

    let cells = [];
    for(let i = -5; i <= 5; i++) {
        cells.push(<Cell {...props} elm={i} key={'cellDiv_' + props.section.replace(' ', '-') + '_' + props.question + '_' + i} type={'normal'} />);
    }

    return cells;
};


class Form extends Component {
    render() {
        let headline = !!this.props.state['headlines'] ? this.props.state['headlines'][this.props.state.lang][this.props.section] : '';
        let text = !!this.props.state['subheadline'] ? this.props.state['subheadline'][this.props.state.lang][this.props.section] : '';

        return (
            <div className={'formData'}>

                <TopBox section={headline} setLanguage={this.props.setLanguage} />

                <div className={'subheadline'} dangerouslySetInnerHTML={{__html: (!!text ? marked(text) : '')}} />

                <div className={'questions'}>
                    {!!this.props.state.questions && !!this.props.state.questions[this.props.state.lang] && !!this.props.state.questions[this.props.state.lang][this.props.section] && Object.keys(this.props.state.questions[this.props.state.lang][this.props.section]).map((e, i) => {
                        let g = this.props.state.questions[this.props.state.lang][this.props.section][e];

                        return (
                            <div key={'q_' + i} className={'question'}>
                                <div className={'top'}>
                                    <div>
                                        {g.question}
                                    </div>
                                </div>

                                <div className={'slider'}>
                                    <CellFunc data={g} publish={this.props.publish} section={this.props.section.replace(' ', '-')} question={i} area={'q'} />
                                </div>


                                <Slider min={-5} max={5} step={1} default={getCellData(this.props, this.props.section.replace(' ', '-'), i)} publish={this.props.publish} section={this.props.section.replace(' ', '-')} question={i} />
                            </div>
                        );
                    })}
                </div>

            </div>
        );
    }

    componentDidMount() {
        if (this.props['infoBubble'] && this.props.sessions.infoBubbleActive === 'true') {
            this.props.toggleBlur({
                section: this.props.section.replace(' ', '-'),
                data: this.props['infoBubble']
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps['infoBubble'] !== this.props['infoBubble']) {
            this.props.toggleBlur({
                section: this.props.section.replace(' ', '-'),
                data: this.props['infoBubble']
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return (JSON.stringify(nextProps.state.invitationData) !== JSON.stringify(this.props.state.invitationData) || JSON.stringify(nextProps.state.sessions) !== JSON.stringify(this.props.state.sessions) || this.props.state.lang !== nextProps.state.lang)
    }

}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                sessions: state.sessions,
                userID: state.userID
            }
        },
        {
            setData
        }
    )(
        Form
    )
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import axios from "axios";
import {
    token
} from "../helper";
import moment from "moment";

import {setData} from "../../redux/actions";

import '../../assets/scss/admin/addUser.scss';

import AddCoach from './add/coach/addCoach.class';
import AddCompany from "./add/coach/addCompany.class";

import ChooseSessionType from './add/session/chooseSessionType.class';
import NewSession from './add/healthyWorking/newSession.class';

import AddSession from "./add/session/addSession.class";

import AddVouchers from "./add/voucher/addVouchers.class";
import Vouchers from "./add/voucher/vouchers.class";

import SetOptions from "./add/options.class";



class AddInvitation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: !!this.props.invitationData && this.props.invitationData.id ? this.props.invitationData.id : null,

            expiration: false,
            section: this.props.section === 'coach' ? ['Coach'] : ['BigCompany'],

            name: this.props.section === 'options' ? (!!props.state.user.name ? props.state.user.name : '') : (!!this.props.invitationData && this.props.invitationData.coacheeName ? this.props.invitationData.coacheeName : ''),
            mail: this.props.section === 'options' ? (!!props.state.user.email ? props.state.user.email : '') : (!!this.props.invitationData && this.props.invitationData.coacheeEmail ? this.props.invitationData.coacheeEmail : ''),
            roles: this.props.section === 'options' ? (!!props.state.user.roles ? props.state.user.roles : '') : this.props.section === 'coach' ? ['Coach'] : ['BigCompany'],
            restriction: {
                "@type": "UserRestriction",
                brandingActivated: false,
                expirationDate: false,
                maxInvitationCount: 0
            },

            plannedDate: (!!this.props.invitationData && this.props.invitationData.invitationState !== 'Closed' && this.props.invitationData.plannedDate) ? moment(this.props.invitationData.plannedDate).utc().format('YYYY-MM-DDTHH:mm') : (!!this.props.invitationData && !!this.props.invitationData['code'] ? '' : moment().format('YYYY-MM-DDTHH:mm')),
            endDate: (!!this.props.invitationData && this.props.invitationData.invitationState !== 'Closed' && this.props.invitationData.endDate) ? moment(this.props.invitationData.endDate).utc().format('YYYY-MM-DDTHH:mm') : (!!this.props.invitationData && !!this.props.invitationData['code'] ? '' : moment().add(1, 'hour').format('YYYY-MM-DDTHH:mm')),

            board: !!this.props.invitationData && this.props.invitationData.board ? this.props.invitationData.board : 'false',
            withVideo: !!this.props.invitationData && !!this.props.invitationData.sessions && !!this.props.invitationData.sessions['SINGLE'] && !!this.props.invitationData.sessions['SINGLE']['sessionData'] ? JSON.parse(this.props.invitationData.sessions['SINGLE']['sessionData']).withVideo : 'false',
            infoBubbleActive: !!this.props.invitationData && !!this.props.invitationData.sessions && !!this.props.invitationData.sessions['SINGLE'] && !!this.props.invitationData.sessions['SINGLE']['sessionData'] ? JSON.parse(this.props.invitationData.sessions['SINGLE']['sessionData']).infoBubbleActive : 'false',
            voucherCount: 0,
            codes: null,
            questionData: !!this.props.invitationData && this.props.invitationData.questionData ? this.props.invitationData.questionData : null,
        };

        this.addUser = this.addUser.bind(this);
        this.changeData = this.changeData.bind(this);
    }

    changeData(data) {
        this.setState(data);
    }

    addUser(e) {
        e.preventDefault();

        this.setState({
            error: null
        });

        let data = {
            "@type": "User",
            email: this.state.mail,
            name: this.state.name,
            locale: {
                "language": "de",
                "name": "German",
                "tag": "de"
            },
            restriction: {
                "@type": "UserRestriction",
                brandingActivated: this.state.restriction.brandingActivated,
            },
            roles: this.state.roles
        };

        if (!!this.state.restriction.maxInvitationCount) {
            data.restriction.maxInvitationCount = this.state.restriction.maxInvitationCount
        }

        if (!!this.state.restriction.expirationDate) {
            data.restriction.expirationDate = this.state.restriction.expirationDate;
        }

        axios.put(
            process.env['REACT_APP_API'] + '/private/user/v1',
            data, {
                'headers': {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': token()
                }
            }
        ).then(() => {
            this.props.methods.getUsers();
            this.props.methods.setThisState(false);
        }).catch(error => {
            console.error(error.response);
            this.setState({
                error: !!error.response.data ? error.response.data.message : null
            });
        });
    }

    render() {
        return (
            <div className={'overlay'}>

                {this.props.section === 'coach' &&
                    <AddCoach change={this.changeData} addUser={this.addUser} data={this.state} methods={this.props.methods} />
                }

                {this.props.section === 'company' &&
                    <AddCompany change={this.changeData} addUser={this.addUser} data={this.state} methods={this.props.methods} />
                }

                {this.props.section === 'chooseSessionType' &&
                    <ChooseSessionType methods={this.props.methods} />
                }

                {this.props.section === 'session' &&
                    <AddSession data={this.state} methods={this.props.methods} action={this.props.action} />
                }

                {this.props.section === 'vouchers' &&
                    <AddVouchers data={this.state} methods={this.props.methods} action={this.props.action} />
                }

                {this.props.section === 'codes' &&
                    <Vouchers codes={this.state.codes} methods={this.props.methods} />
                }

                {this.props.section === 'healthyWorking' &&
                    <NewSession data={this.state} methods={this.props.methods} action={this.props.action} />
                }

                {this.props.section === 'options' &&
                    <SetOptions change={this.changeData} addUser={this.addUser} data={this.state} methods={this.props.methods} />
                }

            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
            }
        },
        {
            setData
        }
    )(
        AddInvitation
    )
);

import React, {Component} from 'react';
import {withRouter, Link, Switch, Route} from 'react-router-dom';
import {connect} from "react-redux";
import queryString from "query-string";

import {
    clearUrl,
    copySessions, getPermissions, getQuestionsWithResults,
    getTranslation,
    getTranslationHeadline, logout
} from "../helper";
import {setData} from "../../redux/actions";

import Form from './form.class';
import TimeMeaning from './timeMeaning.class';
import Start from './start.class';
import Results from './results.class';
import Modal from "../modal.class";

import '../../assets/scss/balanceboard/forms.scss';

import {ReactComponent as Home} from '../../assets/img/home.svg';
import {ReactComponent as Menu} from '../../assets/img/menu.svg';
import {ReactComponent as BurgerMenu} from '../../assets/img/burgermenu.svg';
import moment from "moment";



const add = (a, b) => a + b;

function LinkTo(props) {
    async function navigate(e, to, search, disabled) {
        e.preventDefault();

        if (!!props.toggleMenu) {
            props.toggleMenu();
        }

        if (disabled) return;

        let oldData = copySessions(props.props);
        oldData['path'] = to;


        await props.props.publish({sessions: {
            'SINGLE': {
                "@type":"Session",
                'sessionData': JSON.stringify(oldData)
            }
        }});
        props.props.history.push({
            pathname: to,
            search: search
        });
    }

    return (
        (!!props.home) ?
            <Link to={props.to} onClick={e => navigate(e, props.to, props.search)} className={props.className}>
                <Home width={20} className={'theme_svg'} />
                <span>{props.text}</span>
            </Link>
            :
            <Link to={props.disabled ? '#' : props.to} onClick={e => navigate(e, props.to, props.search, props.disabled)} className={props.className}>{props.name}</Link>
    )

}


class Forms extends Component {

    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
            logout: false
        };

        this.params = queryString.parse(this.props.location.search);

        this.toggleMenu = this.toggleMenu.bind(this);
        this.close = this.close.bind(this);
    }

    close() {
        if (!!this.props.sessions && (!this.props.sessions.withVideo || this.props.sessions.withVideo === false || this.props.sessions.withVideo === 'false')) {
            if (getPermissions('Coach', this.props)) {
                this.props.publish({
                    invitationState: 'Closed',
                    endDate: moment().utc().toISOString()
                });

                window.close();
            } else {
                logout(this.props);
            }

        } else {
            window.close();
        }
    }

    switchBoard(e, position) {
        e.preventDefault();

        localStorage.setItem('position', position);

        this.props.setData({
            position: position
        });
    }

    getCalc(_location) {
        let location = _location.replace('lobby/balanceboard/','');

        let hasZero = 100;
        let sum = 100;

        let question = this.props.state.questions[this.props.state.lang][location];

        if (!!this.props.sessions['results'][location] && Object.keys(this.props.sessions['results'][location]).length === Object.keys(question).length) {
            hasZero = Object.keys(this.props.sessions['results'][location]).filter(e => this.props.sessions['results'][location][e] === 0).length;
        } else if (location === 'Time' && !!this.props.state['Time']['questions'] && Object.keys(this.props.state['Time']['questions'][this.props.state.lang]).length) {
            let num = Object.keys(this.props.state['Time']['questions'][this.props.state.lang]).map(e => this.props.state['Time']['questions'][this.props.state.lang][e].value);
            sum = num.reduce(add);
        } else if (location === 'Meaning' && !!this.props.state['Meaning']['questions'] && Object.keys(this.props.state['Meaning']['questions'][this.props.state.lang]).length) {
            let num = Object.keys(this.props.state['Meaning']['questions'][this.props.state.lang]).map(e => this.props.state['Meaning']['questions'][this.props.state.lang][e].value);
            sum = num.reduce(add);
        }

        return sum === 100 && hasZero === 0;
    }

    getInfoBubble(data) {
        if (!!this.props.state['infoBubble'][this.props.state.lang][data] && !!this.props.state['infoBubble'][this.props.state.lang][data]['headline'] && !!this.props.state['infoBubble'][this.props.state.lang][data]['text']) {
            return this.props.state['infoBubble'][this.props.state.lang][data];
        } else {
            return null;
        }

    }

    getQuestionResults(section, location) {
        console.log(this.props, section, location)
        let results = getQuestionsWithResults(this.props, section, location);

        if (!!results && !!results[location]) {
            results = results[location];

            return (!!results && results.questions === results.answered);
        } else {
            return false;
        }
    }

    getTimeMeaningResults(location) {
        console.log(this.props, location)
        let results = getQuestionsWithResults(this.props, null, location);

        if (!!results) {
            return results.total === 100;
        } else {
            return false;
        }
    }

    toggleMenu() {
        this.setState({menuOpen: !this.state.menuOpen})
    }

    render() {

        let code;
        if (!!this.params['code']) {
            code = '?code=' + this.params['code'];
        }


        let arr = [];
        if (!!this.props.state.structure && this.props.state.structure.size > 0) {
            this.props.state.structure.forEach((v, k) => {
                arr.push('/lobby/balanceboard/' + k);
            })
        }

        let footerLinks = {
            '/lobby/balanceboard/start': {
                next : arr[0]
            },
            [arr[0]]: {
                prev: '/lobby/balanceboard/start',
                next : arr[1]
            },
            [arr[1]]: {
                prev: arr[0],
                next : arr[2]
            },
            [arr[2]]: {
                prev: arr[1],
                next : arr[3]
            },
            [arr[3]]: {
                prev: arr[2],
                next : '/lobby/balanceboard/Bedeutung'
            },
            '/lobby/balanceboard/Bedeutung': {
                prev: arr[3],
                next : '/lobby/balanceboard/Zeit'
            },
            '/lobby/balanceboard/Zeit': {
                prev: '/lobby/balanceboard/Bedeutung',
                next : '/lobby/balanceboard/Erkenntnisse'
            },
            '/lobby/balanceboard/Erkenntnisse': {
                prev: '/lobby/balanceboard/Zeit',
                next: 'logout'
            }
        };

        let currentLinks = footerLinks[this.props.location.pathname];


        let location = clearUrl(this.props);
        let section = 'questions';

        if (location === 'Bedeutung') {
            location = 'Meaning';
            section = null;
        } else if (location === 'Zeit') {
            location = 'Time';
            section = null;
        }

        let structure = [];
        if (!!this.props.state.structure && this.props.state.structure.size > 0) {
            this.props.state.structure.forEach((v, k) => {
                let nextDisabled = this.getQuestionResults('questions', k);

                structure.push(
                    <li key={k} className={'link theme_bordercolor_before ' + (nextDisabled ? '' : 'disabled ') + (clearUrl(this.props) === '/' + k ? 'current ' : '')}>
                        <LinkTo to={'/lobby/balanceboard/' + k} search={code} name={this.props.state['headlines'][this.props.state.lang][this.props.state['questions'][this.props.state.lang][k][0]['section'].replace(' ', '-')]} props={this.props} className={'theme_color_hover ' + (nextDisabled ? '' : 'disabled ') + (clearUrl(this.props) === k ? 'theme_color ' : '')} disabled={!nextDisabled} toggleMenu={() => this.toggleMenu()} />
                    </li>
                )
            })
        }

        let nextDisabled;
        if (section === 'questions') {
            nextDisabled = this.getQuestionResults(section, location);
        } else {
            nextDisabled = this.getTimeMeaningResults(location);
        }


        let avatar = this.props.state.config['Logo'];
        if (!!this.props.state.invitationData && !!this.props.state.invitationData['coach'] && !!this.props.state.invitationData['coach']['avatar']) {
            avatar = this.props.state.invitationData['coach']['avatar'];
        }

        let link = this.props.state.config['Link'];
        if (!!this.props.state.invitationData && !!this.props.state.invitationData['coach'] && !!this.props.state.invitationData['coach']['website']) {
            link = this.props.state.invitationData['coach']['website'];
        } else if (!!this.props.state.invitationData['coach']['avatar']) {
            link = '';
        }

        return (
            <div className={'forms'}>

                <div className={'navigation'}>
                    <div className={'logo theme_bordercolor'}>
                        {(!!this.props.state.invitationData && !!this.props.state.invitationData['coach'] && !!this.props.state.invitationData['coach']['avatar'])
                            ?
                            <span style={{backgroundImage: 'url(' + this.props.state.invitationData['coach']['avatar'] + ')'}} />
                            :
                            <span style={{backgroundImage: 'url(' + this.props.state.config['Logo'] + ')'}} />
                        }
                    </div>

                    <BurgerMenu className={'mobilemenu theme_background'} onClick={() => this.toggleMenu()} />
                    <ul className={this.state.menuOpen ? 'visible' : ''}>
                        <li className={'home'}>
                            <LinkTo to={'/lobby/balanceboard/start'} search={code} name={'Home'} props={this.props} home={true} text={getTranslation(this, this.props.state.lang, 'Start')} toggleMenu={() => this.toggleMenu()} />
                        </li>
                        {structure}
                        <li className={'link theme_bordercolor_before ' + (this.getTimeMeaningResults('Meaning', 'Meaning') ? '' : 'disabled ') + (clearUrl(this.props) === '/Bedeutung' ? 'current ' : '')}>
                            <LinkTo to={'/lobby/balanceboard/Bedeutung'} search={code} name={getTranslationHeadline(this.props.state['headlines'], this.props.state.lang, 'Bedeutung')} props={this.props} className={'theme_color_hover ' + (clearUrl(this.props) === 'Bedeutung' ? 'theme_color' : '')} disabled={!this.getTimeMeaningResults('Meaning', 'Meaning')} toggleMenu={() => this.toggleMenu()} />
                        </li>
                        <li className={'link theme_bordercolor_before ' + (this.getTimeMeaningResults('Time', 'Time') ? '' : 'disabled ') + (clearUrl(this.props) === '/Zeit' ? 'current' : '')}>
                            <LinkTo to={'/lobby/balanceboard/Zeit'} search={code} name={getTranslationHeadline(this.props.state['headlines'], this.props.state.lang, 'Zeit')} props={this.props} className={'theme_color_hover ' + (clearUrl(this.props) === 'Zeit' ? 'theme_color' : '')} disabled={!this.getTimeMeaningResults('Time', 'Time')} toggleMenu={() => this.toggleMenu()} />
                        </li>
                        <li className={'link theme_bordercolor_before ' + (this.getTimeMeaningResults('Time', 'Time') ? '' : 'disabled ') + (clearUrl(this.props) === '/Erkenntnisse' ? 'current' : '')}>
                            <LinkTo to={'/lobby/balanceboard/Erkenntnisse'} search={code} name={getTranslationHeadline(this.props.state['headlines'], this.props.state.lang, 'Auswertung')} props={this.props} className={'theme_color_hover ' + (clearUrl(this.props) === 'Erkenntnisse' ? 'theme_color' : '')} disabled={!this.getTimeMeaningResults('Time', 'Time')} toggleMenu={() => this.toggleMenu()} />
                        </li>
                        <li className={'menu theme_hover'}>
                            <Menu />

                            <ul>
                                <li className={'boardswitch'}>
                                    <a href={'#switch'} onClick={(e) => this.switchBoard(e, this.props.state.position === 'right' ? 'left' : 'right')} className={'theme_color_hover'}>
                                        {getTranslation(this, this.props.state.lang, 'ChangeView')}
                                    </a>
                                </li>
                                <li>
                                    <a href={'#logout'} onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({logout: !this.state.logout})
                                    }} className={'theme_color_hover'}>
                                        {getTranslation(this, this.props.state.lang, 'EndSession')}
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <Modal button={{
                        title: getTranslation(this, this.props.state.lang, 'Hint'),
                        text: getTranslation(this, this.props.state.lang, 'EndSessionText'),
                        className: 'theme_color_hover',
                        id: 'btnStart',
                        confirm: () => this.close(),
                        changeState: () => {
                            this.setState({
                                logout: false
                            });
                        },
                        button: getTranslation(this, this.props.state.lang, 'EndSession'),
                        hideButton: true,
                        state: this.state.logout
                    }} />
                </div>

                <div className={'horizontalShadow'} />

                <div className={'content'}>
                    <Switch>
                        <Route path={'/lobby/balanceboard/start'} exact key={'start'}>
                            <Start setLanguage={this.props.setLanguage} />
                        </Route>
                        {
                            (!!this.props.state['questions'] && !!this.props.state['questions'][this.props.state.lang] && Object.keys(this.props.state['questions'][this.props.state.lang]).length > 0) && Object.keys(this.props.state['questions'][this.props.state.lang]).map(k => {
                                let f = this.props.state['questions'][this.props.state.lang][k];
                                let infobubble = this.getInfoBubble(k);

                                return (
                                    <Route path={'/lobby/balanceboard/' + k} key={k}>
                                        <Form publish={this.props.publish} section={k} data={f} infoBubble={infobubble} toggleBlur={this.props.toggleBlur} setLanguage={this.props.setLanguage} />
                                    </Route>
                                )

                            })
                        }
                        <Route path={'/lobby/balanceboard/Zeit'} key={'time'}>
                            {Object.keys(this.props.state['Time']).length &&
                                <TimeMeaning publish={this.props.publish} section={'Time'} question={7} data={this.props.state['Time']} add={this.props.state['Time']} info={this.props.state['Time']} infoBubble={(!!this.props.state['infoBubble'] && !!this.props.state['infoBubble'][this.props.state.lang] && !!this.props.state['infoBubble'][this.props.state.lang]['Time']) ? this.props.state['infoBubble'][this.props.state.lang]['Time'] : {}} toggleBlur={this.props.toggleBlur} setLanguage={this.props.setLanguage} />
                            }
                        </Route>
                        <Route path={'/lobby/balanceboard/Bedeutung'} key={'meaning'}>
                            {Object.keys(this.props.state['Meaning']).length &&
                                <TimeMeaning publish={this.props.publish} section={'Meaning'} question={8} data={this.props.state['Meaning']} add={this.props.state['Meaning']} info={this.props.state['Meaning']} infoBubble={(!!this.props.state['infoBubble'] && !!this.props.state['infoBubble'][this.props.state.lang] && !!this.props.state['infoBubble'][this.props.state.lang]['Meaning']) ? this.props.state['infoBubble'][this.props.state.lang]['Meaning'] : {}} toggleBlur={this.props.toggleBlur} setLanguage={this.props.setLanguage} />
                            }
                        </Route>
                        {(this.props.state['Auswertung'] && Object.keys(this.props.state['Auswertung']).length) &&
                            <Route path={'/lobby/balanceboard/Erkenntnisse'} key={'results'}>
                                <Results publish={this.props.publish} setLanguage={this.props.setLanguage} section={'Auswertung'} infoBubble={(!!this.props.state['infoBubble'] && !!this.props.state['infoBubble'][this.props.state.lang] && !!this.props.state['infoBubble'][this.props.state.lang]['Auswertung']) ? this.props.state['infoBubble'][this.props.state.lang]['Auswertung'] : {}} toggleBlur={this.props.toggleBlur} />
                            </Route>
                        }
                    </Switch>
                </div>

                <div className={'horizontalShadow'} />

                <div className={'footer'}>

                    <div className={'balanceAktiveLogo'} style={{backgroundImage: 'url(/assets/img/balance_aktiv.png)'}} />

                    <div className={'centerBox'}>
                        {(!!currentLinks && currentLinks.prev)
                            ?
                                <LinkTo className={'btn prev theme_background_hover'} to={currentLinks.prev} search={code} name={getTranslation(this, this.props.state.lang, 'Back')} props={this.props} />
                            :
                                <div className={'btn prev disabled'}>&nbsp;</div>
                        }
                        {(!!currentLinks && !!currentLinks.next)
                            ?
                                (
                                    currentLinks.next === 'logout' ?
                                        <Modal button={{
                                            title: getTranslation(this, this.props.state.lang, 'Hint'),
                                            text: getTranslation(this, this.props.state.lang, 'EndSessionText'),
                                            className: 'btn next theme_background_hover',
                                            id: 'btnStart',
                                            confirm: () => window.close(),
                                            button: getTranslation(this, this.props.state.lang, 'EndSession')
                                        }} />
                                    :
                                        <LinkTo className={'btn next' + ((location === 'start' || nextDisabled) ? ' theme_background_hover' : ' disable')} to={currentLinks.next} search={code} name={clearUrl(this.props) === '/' ? getTranslation(this, this.props.state.lang, 'Start') : getTranslation(this, this.props.state.lang, 'Next')} props={this.props} disabled={location!== 'start' && !nextDisabled} />
                                )
                            :
                                <div className={'btn next disabled'}>&nbsp;</div>
                        }
                    </div>

                    <div className={'logo'}>
                        {!!link
                            ?
                                <a href={link} target={'_blank'} rel="noreferrer" className={'theme_bordercolor'}>
                                    <img src={avatar} alt={'Logo'} />
                                </a>
                            :
                                <span style={{backgroundImage: 'url(' + avatar + ')'}} />
                        }
                    </div>

                </div>

            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
                sessions: state.sessions
            }
        },
        {
            setData
        }
    )(
        Forms
    )
);

import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import '../../assets/scss/healthyworkingboard/cell.scss';
import {connect} from "react-redux";

class ResultDiv extends Component {

    render() {

        if (this.props.elm === 'negative' || this.props.elm === 'positive' || (this.props.results > 0)) {

            let text;

            if (this.props.elm === 'negative') {
                text = this.props.importants;
            } else if (this.props.elm === 'positive') {
                text = this.props.positive;
            } else {
                text = this.props.results;
            }

            let inner = React.createElement(
                'svg',
                {
                    key: 'index_',
                    className: 'index',
                    width:'100%',
                    height:'100%'
                },
                React.createElement(
                    'text',
                    {
                        key: 'index_text',
                        className: 'index_text',
                        x:'50%',
                        y:'50%',
                        textAnchor: 'middle',
                        alignmentBaseline: 'central',
                        dominantBaseline: 'middle',
                        fill: '#999',
                        fontSize: 16,
                    },
                    text
                )
            );

            return React.createElement(
                'div',
                {
                    elm: this.props.elm,
                    className: 'cellDiv' + (this.props.elm === 0 ? ' important' : ' value_' + this.props.elm),
                    dataquestion: this.props.question,
                    style: {width: /*this.props.width +*/ '100%', margin:'0'}
                },
                [
                    inner
                ]
            );
        }
    }

}

export default withRouter(
    connect(
        (state) => {
            return {
                sessions: state.sessions,
                userID: state.userID
            }
        },
        null
    )(
        ResultDiv
    )
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {
    getFreeLinces, getLicenceModel, getPermissions, getTotalInvitations,
    getTranslation, getUsedLicences, token,
} from "../../../helper";
import {setData} from "../../../../redux/actions";
import axios from "axios";
import {ReactComponent as Close} from "../../../../assets/img/close.svg";


class AddVouchers extends Component {

    constructor(props) {
        super(props);

        this.state = this.props.data;

        this.addVouchers = this.addVouchers.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    addVouchers(e) {
        e.preventDefault();

        let data = {
            "@type": "Invitation",
            "coachOnline": false,
            "coacheeOnline": false,
            "hidden": false,
            "invitationState": "New",
            "board" : this.state.board,
            "sessions": {
                'SINGLE' : {
                    '@type': 'Session',
                    sessionData: JSON.stringify({
                        "withVideo": this.state.withVideo
                    })
                }
            }
        };

        let _promises = []

        if (!!this.state.voucherCount) {

            for (let i = 0; i < this.state.voucherCount; i++) {

                _promises.push(new Promise((resolve, reject) => {
                    axios.put(
                        process.env['REACT_APP_API'] + '/private/invitation/v1',
                        data, {
                            'headers': {
                                'Content-Type': 'application/json',
                                'X-Requested-With': 'XMLHttpRequest',
                                'Authorization': token()
                            }
                        }
                    ).then((result) => {
                        let link = window.location.protocol + "//" + window.location.host + '/lobby/';
                        if (data['board'] !== 'false') {
                            if (!!result.data.sessionData && result.data.sessionData.withVideo === false) {
                                link = window.location.protocol + "//" + window.location.host + '/lobby/' + data['board'] + '/start';
                            }
                        }

                        resolve({
                            code: result.data.code,
                            withVideo: !!result.data.sessionData ? result.data.sessionData['withVideo'] : false,
                            board: result.data.board,
                            link: link + '?code=' + result.data['code']
                        });

                    }).catch(error => {
                        console.error(error.response);
                        this.props.setData({
                            error: error.response.data
                        });

                        reject(error.response);
                    });
                }));


            }

            Promise.all(_promises).then(result => {
                if (getPermissions('BigCompany', this.props) || getPermissions('Admin', this.props)) {
                    this.methods.props.getUsers();
                }

                this.props.methods.getInvitationsAndStartWS();
                this.props.methods.setThisState('codes');

                console.log(result);

                this.props.setData({
                    codes: result
                });
            })

        }

    }

    handleChange (event) {
        let { value, min, max } = event.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));

        this.setState({voucherCount: value})
    };

    render() {
        return (
            <div className={'addUser overlayBox'}>

                <Close className={'close'} onClick={() => this.props.methods.setThisState(false)} />

                <form onSubmit={(e) => this.addVouchers(e)}>

                    <h3>
                        {getTranslation(this, this.props.state.lang, 'Vouchers')} {getFreeLinces(this.props, this.props.invitations) ? '(' + getTotalInvitations(this.props.invitations) + '/' + getUsedLicences(this.props) + ')' : '' }
                    </h3>


                    <p>{getTranslation(this, this.props.state.lang, 'howManyVouchers')}</p>

                    <div className={'box voucherCount'}>
                        {getFreeLinces(this.props, this.props.invitations)
                            ?
                            <>
                                <input type={'number'} max={getUsedLicences(this.props) - getTotalInvitations(this.props.invitations)} name={'voucherCount'} value={this.state.voucherCount} onChange={e => this.handleChange(e)} />
                                / {getUsedLicences(this.props) - getTotalInvitations(this.props.invitations)}
                            </>
                            :
                            <>
                                <input type={'number'} max={99} name={'voucherCount'} value={this.state.voucherCount} onChange={e => this.handleChange(e)} />
                                / {getLicenceModel(this, this.props)}
                            </>
                        }
                    </div>

                    <h5>{getTranslation(this, this.props.state.lang, 'Coachingtool')}</h5>

                    <div className={'boardType'}>
                        <label htmlFor={'withBalanceboard'}>
                            <input type={'checkbox'} id={'withBalanceboard'} name={'board'} value={'balanceboard'} checked={this.state.board === 'balanceboard'} onChange={e => this.setState({board: !!e.target.checked ? 'balanceboard' : 'false'})} />
                            {getTranslation(this, this.props.state.lang, 'BalanceboardActive')}
                        </label>

                        {/*<label htmlFor={'withHealthyLivinBoard'}>*/}
                        {/*    <input type={'checkbox'} id={'withHealthyLivinBoard'} name={'board'} value={'healthylivingboard'} checked={this.state.board === 'healthylivingboard'} onChange={e => this.setState({board: !!e.target.checked ? 'healthylivingboard' : 'false'})} />*/}
                        {/*    {getTranslation(this, this.props.state.lang, 'HealthyLiving')}*/}
                        {/*</label>*/}
                    </div>

                    <h5>{getTranslation(this, this.props.state.lang, 'Settings')}</h5>

                    <div className={'settings'}>
                        <label htmlFor={'withVideo'}>
                            <input type={'checkbox'} id={'withVideo'} name={'video'} value={'withVideo'} onChange={e => this.setState({withVideo: !!e.target.checked ? 'true' : 'false'})} />
                            {getTranslation(this, this.props.state.lang, 'SessionVideo')}
                        </label>
                    </div>

                    <div className={'buttonBox'}>
                        <button type={'button'} className={'button theme_color theme_bordercolor theme_background_hover'} onClick={() => this.props.methods.setThisState(false)}>
                            {getTranslation(this, this.props.state.lang, 'Cancel')}
                        </button>

                        <button className={'button theme_color theme_bordercolor theme_background_hover'}>
                            {getTranslation(this, this.props.state.lang, 'addVouchers')}
                        </button>
                    </div>

                </form>
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
            }
        },
        {
            setData
        }
    )(
        AddVouchers
    )
);

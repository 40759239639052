import React, {Component} from 'react';
import {connect} from 'react-redux';
import axios from "axios";
import Peer from "simple-peer";

import VideoLobby from "./videoLobby.class";

import '../../assets/scss/lobby/videoBox.scss';
import {copySessions} from "../helper";



let peerStream, captureStream, videoLocal, videoRemote, subscription, videoOverlay, videoRemoteOverlay;

class VideoLobbyConnected extends Component {
    constructor(props) {
        super(props);

        this.state = {
            audio: this.props.audio,
            video: this.props.video
        };


        this.gotMedia = this.gotMedia.bind(this);
        this.changeMedia = this.changeMedia.bind(this);
    }


    componentDidMount() {
        videoLocal = document.querySelector('#vidLocal');
        videoRemote = document.querySelector('#vidRemote');
        videoOverlay = document.querySelector('#videoOverlay');
        videoRemoteOverlay = document.querySelector('#videoRemoteOverlay');


        console.log('rerender');

        if (!!navigator.mediaDevices && navigator.mediaDevices.getUserMedia !== null) {
            navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true
            }).then((stream) => {
                if (!!videoLocal) {
                    videoOverlay.style.display = 'none';

                    if ('srcObject' in videoLocal) {
                        videoLocal.srcObject = stream
                    } else {
                        videoLocal.src = window.URL.createObjectURL(stream) // for older browsers
                    }

                    if (!captureStream) {
                        captureStream = stream;
                    }

                    if (this.state.video) {
                        captureStream.getVideoTracks()[0].enabled = true;
                    } else {
                        captureStream.getVideoTracks()[0].enabled = false;
                    }

                    if (!!this.props.client) {
                        // get the Stun-Server Credentials
                        this.getStunServerConnection().then(credentials => {

                            // Open a Websocket-Peer for WebRTC
                            this.subscribeToSignal(credentials);
                            this.sendMessage({
                                type: 'join'
                            });
                        });
                    }
                }

            }).catch(() => {})
        }


    }

    getStunServerConnection() {
        return new Promise((resolve, reject) => {
            axios.get(process.env['REACT_APP_API'] + '/public/invitation/v1/ice-credentials/' + this.props.code, {
                'headers': {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-Requested-With': 'XMLHttpRequest'
                }
            }).then((result) => {
                resolve(result.data);
            }).catch((result) => {
                reject(result.response.data.message)
            });
        })
    }

    _signal(data) {
        this.sendMessage({
            type: 'signal',
            data: data
        });
    }

    _stream(stream) {
        // if (!!videoRemote) {
            if ('srcObject' in videoRemote) {
                videoRemote.srcObject = stream
            } else {
                videoRemote.src = window.URL.createObjectURL(stream) // for older browsers
            }
            videoRemote.play();
            videoRemoteOverlay.style.display = 'none';
        // }
    }

    peerConnection() {

        peerStream.on('signal', data => this._signal(data));

        peerStream.on('stream', stream => this._stream(stream));

        peerStream.on('close', () => {
            peerStream.removeAllListeners('close');
            peerStream.removeAllListeners('signal', this._signal);
            peerStream.removeAllListeners('stream', this._stream);
            peerStream.destroy();
            peerStream = null;

            subscription.unsubscribe();

            videoLocal.pause();
            videoLocal.srcObject = null;
            if (!!captureStream) {
                captureStream.getTracks().forEach(e => e.stop());
            }

            videoRemote.srcObject = null;
            videoRemoteOverlay.style.display = 'block';

            let oldData = copySessions(this.props);
            oldData['started'] = 0;
            this.props.publish({sessions: {
                'SINGLE': {
                    'sessionData': JSON.stringify(oldData)
                }
            }});
        });

        peerStream.on('error', err => {
            console.error('received webRTC error', err);
        });

        peerStream.on('data', data => {
            console.log('Peerdata', data);
        });


    }

    gotMedia (stream) {
        peerStream.addStream(stream)
    }

    changeMedia(data) {
        this.setState(data);

        if (!!captureStream) {
            if (!!data.video && data.video === true) {
                captureStream.getVideoTracks()[0].enabled = true;
                videoOverlay.style.display = 'none';
            } else {
                captureStream.getVideoTracks()[0].enabled = false;
                videoOverlay.style.display = 'block';
            }

            if (!!data.audio && data.audio === true) {
                captureStream.getAudioTracks()[0].enabled = true;
            } else {
                captureStream.getAudioTracks()[0].enabled = false;
            }
        }

    }


    componentWillUnmount() {
        if (!!peerStream) {
            peerStream.removeAllListeners('close');
            peerStream.removeAllListeners('signal', this._signal);
            peerStream.removeAllListeners('stream', this._stream);
            peerStream.destroy();
            peerStream = null;

            subscription.unsubscribe();

            videoRemote.pause();
            videoRemote.srcObject = null;
        }

        if (!!captureStream) {
            videoLocal.pause();
            videoLocal.srcObject = null;
            captureStream.getTracks().forEach(e => e.stop());
            captureStream = null;
        }
    }

    publishState(state) {
        let invitationData = {};

        invitationData.invitationState = state;

        this.props.publish(invitationData);
    }

    receiveSignalingMessage(message, peerConfig) {
        switch (message.type) {
            case 'join':
                if (!!message.isFirst) {
                    // ui.appendChatMessage('SYSTEM', 'Created room "default". Waiting for users.');
                } else {
                    peerStream = new Peer({initiator: false, stream: videoLocal.srcObject, config: peerConfig});
                    peerStream._debug = console.log;
                    this.peerConnection();
                    this.sendMessage({
                        type: 'init'
                    });
                }
                break;
            case 'init':
                peerStream = new Peer({initiator: true, stream: videoLocal.srcObject, config: peerConfig});
                peerStream._debug = console.log;
                this.peerConnection();
                break;
            case 'signal':
                if (!!peerStream) {
                    peerStream.signal(message.data)
                }

                break;
            default:
                console.error(`Unknown message type: "${message.type}"`);
                break;
        }
    }

    subscribeToSignal(credentials) {
        return subscription = this.props.client.subscribe('/user/topic/signal/' + this.props.code, (data) => {
            // Start the WebRTC Video-Client
            this.receiveSignalingMessage(JSON.parse(data.body), {
                iceServers: [{
                    urls: credentials.stunServer
                }, {
                    url: credentials.turnServer + '?transport=tcp',
                    username: credentials.username,
                    credential: credentials.credential
                }]
            });
        });
    }

    sendMessage(data) {
        this.props.client.publish({
            destination: '/app/signal/' + this.props.code,
            body: JSON.stringify(data)
        });
    }

    shareScreen() {
        if (this.state.screenshare) {
            this.setState({screenshare:false}, () => {
                navigator.mediaDevices.getUserMedia({video:this.state.video, audio:!this.state.audio}).then((stream) => {
                    if (!!captureStream) {
                        captureStream.getVideoTracks()[0].stop();
                    }

                    captureStream.replaceTrack(captureStream.getVideoTracks()[0], stream.getVideoTracks()[0], captureStream);
                }).catch(() => {})
            });
        } else {
            navigator.mediaDevices.getDisplayMedia().then(stream => {
                this.setState({screenshare:true});

                if (!!captureStream) {
                    captureStream.getVideoTracks()[0].stop();
                }

                peerStream.replaceTrack(captureStream.getVideoTracks()[0], stream.getVideoTracks()[0], captureStream);
            });
        }
    }

    render() {
        return (
            <VideoLobby publish={e => this.props.publish(e)} publishState={e => this.props.publishState(e)} setFullscreen={this.props.setFullscreen} peerConnection={() => this.peerConnection(null)} changeMedia={this.changeMedia} audio={this.state.audio} video={this.state.video} />
        );
    }
}

export default connect(
    (state) => {
        return {
            state: state,
            sessions: state.sessions
        }
    },
    null
)(
    VideoLobbyConnected
);

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {sha256} from 'js-sha256';


import {setData} from "../../redux/actions";

import {v4 as uuid4} from 'uuid';

import '../../assets/scss/login.scss';
import axios from "axios";

import loading from '../../assets/img/loading.gif';
import {cookie} from "cookie.js";


class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,

            ok: false,
            error: false,

            login: {
                password: '',
                email: '',
            }
        }

        this.change = this.change.bind(this);
        this.login = this.login.bind(this);
    }


    change(section, e) {
        let target = e.target;

        let properties = {...this.state[section]};
        properties[target.name] = target.value;

        this.setState({
            [section]: properties
        });
    }

    async login(e, usertype) {
        if (!!e) {
            e.preventDefault();
        }

        this.setState({
            error: '',
            loading: true
        });

        await axios.post(process.env['REACT_APP_API'] + '/public/user/v1/login', {
        }, {
            'headers': {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
                'email': this.state.login.email,
                'password': sha256(this.state.login.password)
            }
        }).then((loginresult) => {

            axios.get(process.env['REACT_APP_API'] + '/private/user/v1', {
                'headers': {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'X-Requested-With': 'XMLHttpRequest',
                    'authorization': loginresult.data.authorization,
                }
            }).then((result) => {

                if (!!result.data.roles && result.data.roles.length >= 0 && result.data.userState !== 'Expired') {
                    localStorage.setItem('token', JSON.stringify(loginresult.data.authorization));
                    localStorage.setItem('user', JSON.stringify(result.data));

                    cookie.set('userID', uuid4(), {path:'/'});

                    this.props.setData({
                        user: result.data
                    });

                    this.setState({
                        loading: false
                    });

                    this.props.history.push('/admin');
                } else if (result.data.userState === 'Expired') {
                    this.setState({
                        error: this.props.state.language[this.props.state.lang]['AccountExpired'],
                        loading: false
                    });
                } else {
                    this.setState({
                        error: this.props.state.language[this.props.state.lang]['AdminLoginError'],
                        loading: false
                    });
                }

            }).catch((result) => {
                this.setState({
                    error: result.response.data.message,
                    loading: false
                });
            });
        }).catch((result) => {
            this.setState({
                error: result.response.data.message,
                loading: false
            });
        });
    }

    render() {
        if (Object.keys(this.props.state['language']).length) {
            return (
                <div className={'login'}>

                    <form onSubmit={e => this.login(e, 'admin')}>
                        <h3 className={'theme_color'}>{this.props.state['language'][this.props.state.lang]['AdminDashboard']}</h3>

                        <div className={'box'}>
                            <input type={'mail'} name={'email'} placeholder={this.props.state['language'][this.props.state.lang]['Mail']} value={this.state.login.email} onChange={e => this.change('login', e)} required={true} />

                            <input type={'password'} name={'password'} placeholder={this.props.state['language'][this.props.state.lang]['Password']} value={this.state.login.password} onChange={e => this.change('login', e)} required={true} />
                        </div>

                        {/*<div className={'stayLoggedInBox'}>*/}
                        {/*    <input type={'radio'} name={'stayLoggedIn'} id={'stayLoggedIn'} />*/}
                        {/*    <label htmlFor={'stayLoggedIn'}>angemeldet bleiben</label>*/}
                        {/*</div>*/}

                        {this.state.error &&
                            <div className={'error'}>
                                {this.state.error}
                            </div>
                        }

                        <div className={'bottomBox'}>
                            <button type={'submit'} className={'button theme_color theme_bordercolor theme_background_hover'}>
                                {this.state.loading ?
                                    <img src={loading} alt={'Loading...'} /> :
                                    this.props.state['language'][this.props.state.lang]['SignIn']
                                }
                            </button>
                        </div>

                    </form>

                </div>
            );
        } else {
            return <></>;
        }
    }

}


export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        {
            setData
        }
    )(
        Login
    )
);

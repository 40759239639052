import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import axios from "axios";
import {getTranslation, token} from "../helper";


import {setData} from "../../redux/actions";

import '../../assets/scss/admin/popup.scss';
import '../../assets/scss/admin/addUser.scss';

import {ReactComponent as Close} from '../../assets/img/close.svg';
import moment from "moment";




class Edituser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name: this.props.data.name,
            email: this.props.data.email,
            roles: this.props.data.roles,
            branding: false,
            expiration: !!this.props.data.restriction.expirationDate ? this.props.data.restriction.expirationDate : false,
            restriction: {
                brandingActivated: !!this.props.data.restriction.brandingActivated ? this.props.data.restriction.brandingActivated : false,
                expirationDate: !!this.props.data.restriction.expirationDate ? this.props.data.restriction.expirationDate : false,
                maxInvitationCount: !!this.props.data.restriction.maxInvitationCount ? this.props.data.restriction.maxInvitationCount : false,
            }
        };
    }

    editUser(e) {
        e.preventDefault();

        this.setState({
            error: null
        });

        let data = {
            email: this.state.email,
            name: this.state.name,
            locale: {
                "language": "de",
                "name": "German",
                "tag": "de"
            },
            restriction: {
                "@type": "UserRestriction",
                brandingActivated: this.state.restriction.brandingActivated,
            },
        };

        if (!!this.state.restriction.maxInvitationCount) {
            data.restriction.maxInvitationCount = this.state.restriction.maxInvitationCount
        }

        if (!!this.state.restriction.expirationDate) {
            data.restriction.expirationDate = this.state.restriction.expirationDate;
        }

        axios.patch(
            process.env['REACT_APP_API'] + '/private/user/v1/' + this.props.data.id,
            data, {
                'headers': {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': token()
                }
            }
        ).then(() => {
            this.props.methods.editUser();
        }).catch(error => {
            console.error(error.response);
            this.setState({
                error: !!error.response.data ? error.response.data.message : null
            });
        });
    }

    render() {
        return (
            <div className={'overlay'}>
                <div className={'popup overlayBox addUser'}>

                    <Close className={'close'} onClick={() => this.props.methods.editUser()} />

                    <form onSubmit={(e) => this.editUser(e)}>

                        <h3>{getTranslation(this, this.props.state.lang, 'EditCoach')}</h3>

                        <div className={'box'}>
                            <input type={'text'} placeholder={'Coach Name'} value={this.state.name} onChange={e => this.setState({name: e.target.value})} />
                            <input type={'mail'} placeholder={'Coach E-Mail-Adresse'} value={this.state.email} onChange={e => this.setState({email: e.target.value})} />
                        </div>

                        <h5>{getTranslation(this, this.props.state.lang, 'Licensemodel')}</h5>

                        <div className={'model'}>
                            <label htmlFor={'coach_card_10'}>
                                <input type={'radio'} id={'coach_card_10'} name={'restrictions'} checked={this.state.restriction.maxInvitationCount === 10} value={'10'} onChange={() => this.setState({restriction: Object.assign({}, this.state.restriction, {maxInvitationCount: 10})})} />
                                {getTranslation(this, this.props.state.lang, '10er')}
                            </label>

                            <label htmlFor={'coach_card_20'}>
                                <input type={'radio'} id={'coach_card_20'} name={'restrictions'} checked={this.state.restriction.maxInvitationCount === 20} value={'20'} onChange={() => this.setState({restriction: Object.assign({}, this.state.restriction, {maxInvitationCount: 20})})} />
                                {getTranslation(this, this.props.state.lang, '20er')}
                            </label>

                            <label htmlFor={'coach_card_25'}>
                                <input type={'radio'} id={'coach_card_25'} name={'restrictions'} checked={this.state.restriction.maxInvitationCount === 25} value={'25'} onChange={() => this.setState({restriction: Object.assign({}, this.state.restriction, {maxInvitationCount: 25})})} />
                                {getTranslation(this, this.props.state.lang, '25er')}
                            </label>

                            <label htmlFor={'coach_flat'}>
                                <input type={'radio'} id={'coach_flat'} name={'restrictions'} checked={!this.state.restriction.maxInvitationCount} value={'flatrate'} onChange={() => this.setState({restriction: Object.assign({}, this.state.restriction, {maxInvitationCount: false})})} />
                                {getTranslation(this, this.props.state.lang, 'Flatrate')}
                            </label>
                        </div>

                        <h5>{getTranslation(this, this.props.state.lang, 'AccountEndDate')}</h5>

                        <div className={'expirationDate'}>
                            <label htmlFor={'coach_expiration'}>
                                <input type={'checkbox'} id={'coach_expiration'} checked={this.state.expiration} onChange={() => this.setState({expiration: !this.state.expiration})} />
                                {getTranslation(this, this.props.state.lang, 'AccountHasEndDate')}
                            </label>

                            {this.state.expiration &&
                            <label className={'inputlabel'} htmlFor={'coach_expiration'}>
                                <input type={'date'} id={'coach_expiration'} value={this.state.restriction.expirationDate} onChange={e => this.setState({restriction: Object.assign({}, this.state.restriction, {expirationDate: moment(e.target.value).format('YYYY-MM-DD')})})} />
                                {getTranslation(this, this.props.state.lang, 'AccountExpires')}
                            </label>
                            }
                        </div>

                        <h5>{getTranslation(this, this.props.state.lang, 'Branding')}</h5>

                        <div className={'branding'}>
                            <label htmlFor={'coach_branding'}>
                                <input type={'checkbox'} id={'coach_branding'} checked={this.state.restriction.brandingActivated} onChange={() => this.setState({restriction: Object.assign({}, this.state.restriction, {brandingActivated: !this.state.restriction.brandingActivated})})} />
                                {getTranslation(this, this.props.state.lang, 'OwnBranding')}
                            </label>
                        </div>

                        {this.state.error &&
                        <div className={'error'}>
                            {this.state.error}
                        </div>
                        }

                        <div className={'buttonBox'}>
                            <button type={'button'} className={'button theme_color theme_bordercolor theme_background_hover'} onClick={() => this.props.methods.editUser()}>
                                {getTranslation(this, this.props.state.lang, 'Cancel')}
                            </button>

                            <button className={'button theme_color theme_bordercolor theme_background_hover'}>
                                {getTranslation(this, this.props.state.lang, 'Save')}
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
            }
        },
        {
            setData
        }
    )(
        Edituser
    )
);

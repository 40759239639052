import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import '../../../../assets/scss/admin/add/healthyWorking/newCategory.scss';


class HealthyWorkingNewCategory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            edit: false
        };
    }

    render() {
        return (
            <>

                <div className={'category__category__new'} onClick={this.props.addCategory}>
                    <div className={'category__category__new__add'}>Kategorie hinzufügen</div>
                </div>

            </>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state
            }
        },
        null
    )(
        HealthyWorkingNewCategory
    )
);

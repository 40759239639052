import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import {setData} from "../redux/actions";
import {getTranslation} from "./helper";

import '../assets/scss/modal.scss';


class Modal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false
        }

        this.confirm = this.confirm.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    confirm(e) {
        if (!!this.props.button.changeState) {
            this.props.button.changeState();
            this.props.button.confirm(e);
        } else {
            this.setState({
                show: false
            }, () => this.props.button.confirm(e));
        }
    }

    cancel() {
        if (!!this.props.button.changeState) {
            this.props.button.changeState();
        } else {
            this.setState({
                show: false
            });
        }
    }

    render() {
        return (
            <>
                {this.state.show &&
                    <div className={'overlay modal'} onClick={() => this.cancel()} title={getTranslation(this, this.props.state.lang, 'Close')}>
                        <div className={'overlayBox'} onClick={e => e.stopPropagation()} title={''}>
                            <h3 className={'theme_color'}>{this.props.button.title}</h3>
                            <p dangerouslySetInnerHTML={{__html: this.props.button.text}} />

                            <div className={'modal_buttons'}>
                                <button className={'modal_button button theme_color theme_bordercolor theme_background_hover'} onClick={() => this.cancel()}>
                                    {getTranslation(this, this.props.state.lang, 'No')}
                                </button>

                                <button className={'modal_button button theme_color theme_bordercolor theme_background_hover'} onClick={(e) => this.confirm(e)}>
                                    {getTranslation(this, this.props.state.lang, 'Yes')}
                                </button>
                            </div>
                        </div>
                    </div>
                }
                {!this.props.button.hideButton &&
                    <button className={this.props.button.className} id={this.props.button.id} onClick={() => this.setState({show: true})}>
                        {this.props.button.button}
                    </button>
                }
            </>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.button.state !== this.props.button.state) {
            this.setState({
                show: this.props.button.state
            });
        }
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
            }
        },
        {
            setData
        }
    )(
        Modal
    )
);

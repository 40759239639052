import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';

import '../../assets/scss/admin/listItem.scss';
import {getLicenceUser, getTranslation} from "../helper";


class Listitem extends Component {
    render() {
        const {data, index} = this.props;
        let key = index+1;

        return (
            <div className={'listItem'}>
                <div className={'number'}>
                    {key}
                </div>
                <div className={'date'}>
                    {!!data['activated'] ? new Date(data['activated']).toLocaleDateString('de-DE', {
                        year:'numeric',
                        month:'2-digit',
                        day:'2-digit'
                    }) : '-'}
                </div>
                <div className={'name'}>
                    {data['name']}
                </div>
                <div className={'mail'}>
                    {data['email']}
                </div>
                <div className={'code'}>
                    {getLicenceUser(this,data)}
                </div>
                <div className={'state'}>
                    {data['userState'] === 'New' &&
                        <>{getTranslation(this, this.props.state.lang, 'New')}</>
                    }
                    {data['userState'] === 'Active' &&
                        <>{getTranslation(this, this.props.state.lang, 'Active')}</>
                    }
                    {data['userState'] === 'Waiting' &&
                        <Link to={'/lobby/' + data['model']}>{getTranslation(this, this.props.state.lang, 'Waiting')}</Link>
                    }
                    {data['userState'] === 'Expired' &&
                        <>{getTranslation(this, this.props.state.lang, 'Expired')}</>
                    }
                </div>
                <div className={'options'}>...
                    <ul>
                        {/*<li className={'theme_background_hover'} onClick={() => this.props.methods.editUser(data['id'])}>{getTranslation(this, this.props.state.lang, 'EditProfile')}</li>*/}
                        <li className={'theme_background_hover'} onClick={() => this.props.methods.deleteUser(data['id'])}>{getTranslation(this, this.props.state.lang, 'Delete')}</li>
                        {/*{data['userState'] === 'New' &&*/}
                        {/*    <li className={'theme_background_hover'} onClick={() => this.props.methods.setPopup('invite')}>{getTranslation(this, this.props.state.lang, 'Resend')}</li>*/}
                        {/*}*/}
                    </ul>
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                language: state.language,
            }
        },
        null
    )(
        Listitem
    )
);

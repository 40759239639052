import React, {Component} from 'react';
import {pdf} from "@react-pdf/renderer";
import {PdfDocument} from "./pdf";
// import {saveAs} from 'file-saver';
import {connect} from "react-redux";
// import axios from "axios";
import {ReactComponent as Check} from '../../assets/img/checked.svg';
import {copySessions, getPermissions} from "../helper";

const generatePdfDocument = async (fileName, documentData, done, section) => {
    pdf(<PdfDocument data={documentData} />).toBlob().then((blob) => {
        const win = window.open(URL.createObjectURL(new Blob([blob], {type: 'application/pdf'})), '_blank');
        win.document.title = "Erkenntnisse_BalanceAktiv.pdf";

        // saveAs(blob, fileName);
        done(section);
    });
};

// const generatePdfDocumentAndSendMail = async (fileName, documentData, mail, done, error, section) => {
//     pdf(<PdfDocument data={documentData} />).toBlob().then((blob) => {
//         const _data = new File([blob], fileName);
//         const data = new FormData();
//         data.append('upload', _data);
//
//         axios.post(process.env['REACT_APP_API'] + '/contact.php', data, {
//             'headers': {
//                 'Content-Type': 'multipart/form-data',
//                 'X-Requested-With': 'XMLHttpRequest',
//                 'User': mail
//             }
//         }).then(() => {
//             done(section);
//         }).catch(() => {
//             error();
//         });
//     });
// };


class PdfDownload extends Component {

    constructor(props) {
        super(props);

        this.state = {
            a: false,
            b: false,
            mail: '',
            error: false,
            sent: false
        };

        this.done = this.done.bind(this);
        this.error = this.error.bind(this);
        this.sent = this.sent.bind(this);
    }

    done(dl) {
        this.setState({
            [dl]: false
        })
    }

    error() {
        this.setState({
            b: false,
            error: true
        })
    }

    sent() {
        this.setState({
            b: false,
            sent: true,
            error: false
        })
    }

    change(target, e) {
        let oldData = copySessions(this.props);
        if (!oldData['results']) {
            oldData['results'] = {};
        }
        if (!oldData['results'][this.props.section]) {
            oldData['results'][this.props.section] = {};
        }
        oldData['results'][target.name] = target.checked;

        this.props.publish({sessions: {
            'SINGLE': {
                "@type":"Session",
                'sessionData': JSON.stringify(oldData)
            }
        }});
    }

    render() {

        // let btn = '';
        // if (this.state.sent) {
        //     btn = (
        //         <button className={'link done'}>
        //             <span className={'text'}>
        //                 {this.props.state.language[this.props.state.lang]['Sent']}
        //             </span>
        //         </button>
        //     )
        // } else {
        //     if (this.state.b === true) {
        //         btn = (
        //             <button className={'link theme_background'} style={{display: 'flex'}}>
        //                 {this.props.state.language[this.props.state.lang]['Send']}
        //
        //                 <div className={'loading'}>
        //                     <div className="spinner">
        //                         <div className="bounce1"/>
        //                         <div className="bounce2"/>
        //                         <div className="bounce3"/>
        //                     </div>
        //                 </div>
        //             </button>
        //         );
        //     } else {
        //         btn = (
        //             <button className={'link theme_background'} onClick={() => {
        //                 if (!!this.state.mail) {
        //                     this.setState({
        //                         b: true
        //                     });
        //                 }
        //             }}>
        //                 <span className={'text'}>
        //                     {this.props.state.language[this.props.state.lang]['Sending']}
        //                 </span>
        //             </button>
        //         )
        //     }
        // }

        return (
            <div className={'downloadList'}>
                {(this.props.downloadable === 'Ja') &&
                <div className={'box' + (getPermissions('Coach', this.props) ? ' center' : '')}>
                    {
                        (this.state.a === true
                                ?
                                <button className={'generate theme_background'}>
                                    {this.props.state.language[this.props.state.lang]['GeneratePDF']}

                                    <span className={'loading'}>
                                            <div className="spinner">
                                                <div className="bounce1" />
                                                <div className="bounce2" />
                                                <div className="bounce3" />
                                            </div>
                                        </span>
                                </button>
                                :
                                <button className={'generate ' + ((!getPermissions('Coach', this.props) || this.props.downloadAllowed) ? 'downloadable theme_background' : 'disabled')} disabled={!(!getPermissions('Coach', this.props) || this.props.downloadAllowed)} onClick={() => {
                                    this.setState({
                                        a: true
                                    });
                                }}>
                                    <span className={'text'}>
                                        {this.props.state['Auswertung'][this.props.state.lang]['DownloadButton']}
                                    </span>
                                </button>
                        )
                    }
                </div>
                }

                {/*{!getPermissions('Coach', this.props) &&*/}
                {/*    <div className={'box'}>*/}
                {/*        {!getPermissions('Coach', this.props) &&*/}
                {/*            <label htmlFor={''}>*/}
                {/*                {this.props.state['Auswertung'][this.props.state.lang]['PDFMail']}*/}
                {/*            </label>*/}
                {/*        }*/}

                {/*        {getPermissions('Coach', this.props) &&*/}
                {/*            <label htmlFor={''} className={'center'}>*/}
                {/*                {this.props.state['Auswertung'][this.props.state.lang]['CoacheeDownload']}*/}
                {/*            </label>*/}
                {/*        }*/}

                {/*        <div className={'inner'}>*/}
                {/*            <input type={'mail'} name={'mail'} value={this.state.mail} placeholder={'IHRE E-MAIL-ADRESSE'} onChange={e => this.setState({mail: e.target.value})} />*/}
                {/*            {btn}*/}
                {/*        </div>*/}

                {/*        {this.state.error &&*/}
                {/*            <div className={'error'}>*/}
                {/*                {this.props.state.language[this.props.state.lang]['SendError']}*/}
                {/*            </div>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*}*/}

                <div className={'textBox'}>
                    {(this.props.downloadable === 'Ja' && !getPermissions('Coach', this.props)) &&
                    <label htmlFor={'allowDownload'} className={'allowDownload'}>
                        <input type={'checkbox'} id={'allowDownload'} name={'downloadAllowed'} checked={this.state.downloadAllowed} onChange={e => this.change(e.target, e)} />
                        <Check className={'checkbox'} />

                        {this.props.state['Auswertung'][this.props.state.lang]['DownloadText']}
                    </label>
                    }
                </div>
            </div>
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.a !== this.state.a && this.state.a === true) {
            generatePdfDocument('Erkenntnisse_BalanceAktiv.pdf', this.props, this.done, 'a');
        }

        // if (prevState.b !== this.state.b && this.state.b === true) {
        //     generatePdfDocumentAndSendMail('Erkenntnisse_BalanceAktiv.pdf', this.props, this.state.mail, this.sent, this.error, 'b');
        // }
    }

}

export default connect(
    (state) => {
        return {
            state: state,
            sessions: state.sessions,
            invitationData: state.invitationData
        }
    },
    null
)(
    PdfDownload
);

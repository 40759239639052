import React from 'react';
import {Page, Text, View, Document, Font, Image, Link} from '@react-pdf/renderer';

import regular from '../../assets/font/Merriweather-Regular.ttf';
import bold from '../../assets/font/Merriweather-Bold.ttf';
import italic from '../../assets/font/Merriweather-Italic.ttf';

import shadow from '../../assets/img/shadow.png';
import {sumAllQuestionResults} from "../helper";
import moment from "moment";


Font.register({
    family: 'Open Sans',
    fonts: [
        {src: regular},
        {src: bold, fontWeight: 700},
        {src: italic, fontStyle: 'italic'}
    ]
});


const Cell = (data) => {
    return (
        <View style={{backgroundColor:data.bgColor, width:data.width, height:25, textAlign:'center', borderRadius:25, display:'flex', flexDirection:'column', justifyContent:'center'}} key={data.key}>
            <Text style={{fontSize:10, color:'#fff'}}>{data.position === 0 ? '!' : data.results}</Text>
        </View>
    )
}

const ResultCellFunc = (props) => {
    let cell = [];
    let sub = [];
    let subWith = '';
    let add = [];
    let addWith = '';

    let subCount = 0;
    if (!!props.results) {
        if (!!props.results[-2] || props.results[-1]) {
            subCount = (!!props.results[-2] ? props.results[-2] : 0) + (!!props.results[-1] ? props.results[-1] : 0)
        }
    }
    for(let i = -2; i < 0; i++) {
        if (!!props.results && !!props.results[i]) {
            sub.push(Cell({
                'width': props.results[i] / subCount * 100 + '%',
                'position': i,
                'key': 'cellDiv_' + props.category + '_' + props.question + '__' + i,
                'results': !!props.results ? (!!props.results[i] ? props.results[i] : 0) : 0,
                'importants': !!props.importants ? props.importants : 0,
                'question': props.question,
                'bgColor': i === -2 ? '#c70000' : '#f29200'
            }));
            subWith += (props.results[i] / subCount * 100) + '% ';
        }
    }

    cell.push(
        <View className={'sub'} style={{display:'flex', flexDirection:'row', width:100, gridTemplateColumns: subWith, marginRight:2}} key={'sub_' + props.category + '_' + props.question + '_' + 0}>
            {sub}
        </View>
    );

    cell.push(Cell({
        'width': 25,
        'position': 0,
        'key': 'cellDiv_' + props.category + '_' + props.question + '__' + 0,
        'results': !!props.results ? (!!props.results[0] ? props.results[0] : 0) : 0,
        'importants': !!props.importants ? props.importants : 0,
        'question': props.question,
        'bgColor': '#cccccc'
    }));

    let addCount = 0;
    if (!!props.results) {
        if (!!props.results[1] || props.results[2]) {
            addCount = (!!props.results[1] ? props.results[1] : 0) + (!!props.results[2] ? props.results[2] : 0)
        }
    }
    for(let i = 1; i <= 2; i++) {
        if (!!props.results && !!props.results[i]) {
            add.push(Cell({
                'width': props.results[i] / addCount * 100 + '%',
                'position': i,
                'key': 'cellDiv_' + props.category + '_' + props.question + '__' + i,
                'results': !!props.results ? (!!props.results[i] ? props.results[i] : 0) : 0,
                'importants': !!props.importants ? props.importants : 0,
                'question': props.question,
                'bgColor': i === 2 ? '#649600' : '#c2d900'
            }));
            addWith += (props.results[i] / addCount * 100) + '% ';
        }
    }

    cell.push(
        <View className={'add'} style={{display:'flex', flexDirection:'row', width:100, gridTemplateColumns: addWith, marginLeft:2}} key={'add_' + props.category + '_' + props.question + '_' + 0}>
            {add}
        </View>
    );

    return cell;
}

function RenderQuestions(props) {

    if (!!props.category) {
            let questions = props.category.questions.map((data, key) => {
                let val = [];

                val.push(
                    ResultCellFunc(
                        Object.assign({}, props, {
                            'results': !!props.results && !!props.results[key] ? props.results[key] : null
                        })
                    )
                )

                return (
                    <View style={{marginBottom:10}} key={'q_' + key}>
                        <View style={{marginBottom:5}}>
                            <Text style={{fontSize:8, textAlign:'center'}}>{data['question']}</Text>
                        </View>

                        <View style={{display:'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            {val}
                        </View>
                    </View>
                )
            });

            return (
                <View key={'page_' + props.section} style={props.style}>
                    <View style={{display:'flex', justifyContent:'center', flexDirection:'row', marginBottom:10}}>
                        <Text style={{textTransform:'uppercase', display:'flex', fontSize:10, borderBottom:'1 solid ' + props.state.config['Farbe'], padding:'0 20'}}>
                            {props.category.category}
                        </Text>
                    </View>

                    <View>

                        {questions}

                    </View>
                </View>
            );
    }

}


function RenderFooter(props) {

    let logo;
    if (!!props.questionData && !!props.questionData['logo']) {
        logo = props.questionData['logo'];
    } else if (!!props.state.invitationData && !!props.state.invitationData['coach'] && !!props.state.invitationData['coach']['avatar']) {
        logo = props.state.invitationData['coach']['avatar'];
    } else {
        logo = props.state.config['Logo'];
    }

    let link = props.state.config['Link'];
    if (!!props.questionData && !!props.questionData['url']) {
        link = props.questionData['url'];
    } else if (!!props.invitationData && !!props.invitationData['coach'] && !!props.invitationData['coach']['website']) {
        link = props.invitationData['coach']['website'];
    } else if (!!props.invitationData['coach']['avatar']) {
        link = '';
    }

    return (
        <View style={{position:'absolute', right:20, bottom:10, left:20, display:'flex', flexDirection:'column'}}>

            <View style={{position:'absolute', right:5, bottom:5, left:5}}>
                <Image src={shadow} style={{width:'100%'}} />

                <View style={{display:'flex', flexDirection:'row', justifyContent:'space-between', margin:'5 15 0'}}>
                    <View style={{flex:'1', display:'flex', flexDirection:'column', justifyContent:'center'}}>
                        <Link src={'https://balanceaktiv.de'} style={{margin:'auto auto auto 0', display:'flex', flexDirection:'row', justifyContent:'flex-start'}}>
                            <Image src={'assets/img/balance_aktiv.png'} style={{width:'80px'}} />
                        </Link>
                    </View>

                    <View style={{flex:'1'}}>
                        <View style={{fontSize:10, display:'flex', flexDirection:'column', justifyContent:'center', margin:'auto'}}>
                            <Text>{props.page}/5</Text>
                        </View>
                    </View>

                    <View style={{flex:'1', display:'flex', flexDirection:'column', justifyContent:'center'}}>
                        {!!link
                            ?
                                <Link href={link} style={{margin:'auto 0 auto auto', display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
                                    <Image src={logo} style={{maxHeight:30, maxWidth:300}} />
                                </Link>
                            :
                                <View style={{margin:'auto 0 auto auto', display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
                                    <Image src={logo} style={{maxHeight:30, maxWidth:300}} />
                                </View>
                        }
                    </View>
                </View>
            </View>

        </View>
    )
}

function RenderPages(props) {

    let arr = [];

    if (!!props.questionData && props.questionData.questions) {
        let results = sumAllQuestionResults(props.sessions);

        props.questionData.questions.forEach((c,k) => {

            if (k === 0) {
                let text = 'Datum: ' + moment(props.invitationData['plannedDate']).format('DD.MM.YYYY HH:mm') + ' - ' + moment(props.invitationData['endDate']).format('DD.MM.YYYY HH:mm') + ' | COACH: ' + props.invitationData['coach']['name'];

                arr.push(
                    <Page wrap={true} size="A4" style={{fontFamily:'Open Sans', display:'flex', flexDirection:'column', justifyContent:'flex-start', backgroundColor:'#fff', padding:'4vw 10vw', fontSize:8, boxSizing:'border-box'}} key={'page_qestions_' + k}>
                        <View>
                            <View style={{display:'flex', justifyContent:'center', flexDirection:'row', marginBottom:10}}>
                                <Text style={{textTransform:'uppercase', display:'flex', fontSize:14, borderBottom:'1 solid ' + props.state.config['Farbe'], padding:'0 20'}}>
                                    {props.invitationData['coacheeName']}
                                </Text>
                            </View>

                            <View style={{display:'flex', justifyContent:'center', flexDirection:'row', marginBottom:20, textAlign:'center'}}>
                                <Text style={{display:'flex', fontSize:10, whiteSpace:'pre-line', fontStyle:'italic', textAlign:'center'}}>
                                    {text}
                                </Text>
                            </View>
                        </View>

                        <RenderQuestions {...props} category={c} results={!!results.allResults ? (!!results.allResults[k] ? results.allResults[k] : null) : null} key={'questions_' + k} />
                        <RenderFooter {...props} page={3} />
                    </Page>
                );
            } else {
                arr.push(
                    <Page wrap={true} size="A4" style={{fontFamily:'Open Sans', display:'flex', flexDirection:'column', justifyContent:'flex-start', backgroundColor:'#fff', padding:'4vw 10vw', fontSize:8, boxSizing:'border-box'}} key={'page_qestions_' + k}>
                        <RenderQuestions {...props} category={c} results={!!results.allResults ? (!!results.allResults[k] ? results.allResults[k] : null) : null} key={'questions_' + k} />
                        <RenderFooter {...props} page={3} />
                    </Page>
                );
            }
        });
    }

    return arr;
}

export function PdfDocument(props) {
    return (
        <Document>
            <RenderPages {...props.data} />
        </Document>
    )
}

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import marked from "../markdown";

import '../../assets/scss/balanceboard/start.scss';
import TopBox from "./topBox.class";
import moment from "moment";


class Start extends Component {
    render() {
        let text = !!this.props.state.Start[this.props.state.lang] ? this.props.state.Start[this.props.state.lang]['Unterueberschrift'] + '<br />COACHEE: ' + (!!this.props.invitationData.coacheeName ? this.props.invitationData.coacheeName : 'Coachee') + ' | ' + this.props.state['Auswertung'][this.props.state.lang]['Date'] + ': ' + moment(this.props.invitationData['plannedDate']).format('DD.MM.YYYY') + ' | COACH: ' + this.props.invitationData['coach']['name'] : '';

        return (
            <div className={'startContainer'}>

                <TopBox section={!!this.props.state.Start[this.props.state.lang] ? this.props.state.Start[this.props.state.lang]['Ueberschrift'] : ''} setLanguage={this.props.setLanguage} />

                <div className={'subheadline'} dangerouslySetInnerHTML={{__html: (!!text ? marked(text) : '')}} />

                <div className={'start'}>
                    <div className={'sections'}>
                        <section dangerouslySetInnerHTML={{__html: (!!this.props.state.Start[this.props.state.lang] ? marked(this.props.state.Start[this.props.state.lang]['Links']) : '')}} />

                        <section dangerouslySetInnerHTML={{__html: (!!this.props.state.Start[this.props.state.lang] ? marked(this.props.state.Start[this.props.state.lang]['Rechts']) : '')}} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => {
            return {
                state: state,
                invitationData: state.invitationData
            }
        },
        null
    )(
        Start
    )
);
